/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../inputCustom.scss';

export type Props = {
  country: string;
  value: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: ([arg]?: any) => any; //TODO: remove any types
  [key: string]: any;
};

const PhoneInput: React.FC<Props> = ({
  country,
  className = '',
  disabled,
  placeholder,
  value,
  onChange,
}) => {
  const phoneCountry = country.toLowerCase();

  return (
    <ReactPhoneInput
      containerClass={className}
      disabled={disabled}
      placeholder={placeholder}
      country={phoneCountry}
      value={value}
      specialLabel=""
      onChange={onChange}
      enableSearch
      disableSearchIcon
      searchPlaceholder="Search"
    />
  );
};

export default PhoneInput;
