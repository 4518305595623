/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Upload, UploadFile, UploadProps } from 'antd';
import { UploadChangeParam, RcFile } from 'antd/es/upload';
import ImgCrop from 'antd-img-crop';
import IconCustom from '../../IconCustom/IconCustom';
import { getBase64 } from '../../../Helpers/helperFunctions';

export type Props = {
  className?: string;
  onChange: ([arg]?: any) => any;
};

const UploadPictureButton: React.FC<Props> = ({ className = '', onChange }) => {
  const [imageUrl, setImageUrl] = React.useState('');

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (imageUrl) {
      setImageUrl('');
    }
    getBase64(info.file.originFileObj as RcFile, (url) => {
      setImageUrl(url);
      onChange(url);
    });
  };

  return (
    <ImgCrop modalClassName="upload-crop-modal" shape="round">
      <Upload showUploadList={false} className={className} onChange={handleChange}>
        <IconCustom avatarIcon="addPicture" />
      </Upload>
    </ImgCrop>
  );
};

export default UploadPictureButton;
