import * as React from 'react';
import ExperienceForm from './ExperienceForm/ExperienceForm';
import ModalCustom from '../../../Components/UI/ModalCustom/ModalCustom';
import CardCustom from '../../../Components/UI/CardCustom/CardCustom';
import AvatarCustom from '../../../Components/AvatarCustom/AvatarCustom';
import IconCustom from '../../../Components/IconCustom/IconCustom';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import ButtonCustom from '../../../Components/UI/ButtonCustom/ButtonCustom';
import { WorkExperience } from '../../../redux/reducers/user/userInterface';
import { useAppDispatch } from '../../../redux/hooks';
import { deleteUserExperience } from '../../../redux/reducers/user/userApi';
import {
  calculateDifferenceInYearsAndMonths,
  createElementFromString,
} from '../../../Helpers/helperFunctions';
import { ReactComponent as IconDelete } from '../../../assets/svg/icon-delete.svg';

export type Props = {
  experienceItem: WorkExperience;
  isEditable?: boolean;
  userId?: string;
};

const maxDescriptionLength = 70;

const ExperienceCard: React.FC<Props> = ({ experienceItem, isEditable = true, userId }) => {
  const dispatch = useAppDispatch();

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [allShowing, setAllShowing] = React.useState(false);

  const { role, company, startDate, endDate, description, logo } = experienceItem;

  const descriptionSanitized = createElementFromString(description);

  const closeEditModal = () => setEditModalOpen(false);
  const closeDeleteModal = () => setDeleteModalOpen(false);

  const deleteItem = () => {
    if (experienceItem.id && userId) {
      dispatch(
        deleteUserExperience({ currentUserId: +userId, currentExperienceId: +experienceItem.id }),
      );
    }
    closeDeleteModal();
  };

  const getDateText = (date: Date) =>
    date.toLocaleString('default', { month: 'short', year: 'numeric' });

  const startDateObj = new Date(startDate);
  const endDateObj = endDate ? new Date(endDate) : null;
  const startDateString = getDateText(startDateObj);
  const endDateString = endDateObj ? getDateText(endDateObj) : 'Present';
  const timeElapsed = endDateObj
    ? calculateDifferenceInYearsAndMonths(startDateObj, endDateObj)
    : null;

  const descriptionIsLong = description.length > maxDescriptionLength;
  const descriptionElement = (
    <>
      <p
        className={`experienceCard-description-text${
          allShowing || !descriptionIsLong ? '' : ' truncated-element'
        }`}
      >
        {descriptionSanitized}
      </p>
      {descriptionIsLong ? (
        <ButtonCustom
          type="text"
          className="educationExperience-card-description-button"
          onClick={() => setAllShowing((prevState) => !prevState)}
        >
          See {allShowing ? 'less' : 'more'}
        </ButtonCustom>
      ) : null}
    </>
  );
  return (
    <>
      <CardCustom className="experienceSection-card">
        <div className="flex">
          <AvatarCustom imageSrc={logo} size="sm" fallbackText={company[0]} />
          <div className="experienceSection-card-header">
            <h3 className="educationExperience-card-title">{role}</h3>
            <h4 className="educationExperience-card-subtitle">{company}</h4>
          </div>
        </div>
        <div className="educationExperience-card-info">
          <p className="educationExperience-card-date">
            <time>{startDateString}</time> - <time>{endDateString}</time>
            {timeElapsed || null}
          </p>
          <DividerCustom className="my-4" />
          <p className="educationExperience-card-description">{descriptionElement}</p>
          {isEditable ? (
            <div className="educationExperience-card-actions">
              <ButtonCustom
                type="text"
                className="educationExperience-card-button"
                onClick={() => setDeleteModalOpen(true)}
              >
                <IconCustom
                  icon="MdDeleteForever"
                  className="educationExperience-card-icon"
                  color="gray-400"
                />
              </ButtonCustom>
              <ButtonCustom
                type="text"
                className="educationExperience-card-button"
                onClick={() => setEditModalOpen(true)}
              >
                <IconCustom
                  icon="MdEdit"
                  className="educationExperience-card-icon ml-1"
                  color="gray-400"
                />
              </ButtonCustom>
            </div>
          ) : null}
        </div>
      </CardCustom>
      {editModalOpen && (
        <ExperienceForm
          experienceItem={experienceItem}
          onCancel={closeEditModal}
          onOk={closeEditModal}
          open={editModalOpen}
          userId={userId}
        />
      )}
      <ModalCustom
        danger
        open={deleteModalOpen}
        footer="fullLength"
        onCancel={closeDeleteModal}
        onOk={deleteItem}
        cancelText="Cancel"
        okText="Yes, delete"
      >
        <div className="small-modal-content">
          <IconCustom component={IconDelete} className="educationExperience-delete-icon" />
          <h2 className="modal-heading">Are you sure?</h2>
          <h4 className="modal-subheading">
            Do you really want to delete this education? This process cannot be undone.
          </h4>
        </div>
      </ModalCustom>
    </>
  );
};

export default ExperienceCard;
