/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import ButtonCustom from '../../UI/ButtonCustom/ButtonCustom';
import ModalCustom from '../../UI/ModalCustom/ModalCustom';
import WelcomeImage from '../../../assets/images/welcome.gif';
import './Welcome.scss';

export type Props = {
  open?: boolean;
  onCancel: () => void;
  onOk: ([arg]?: any) => void;
};
const Welcome: React.FC<Props> = ({ open = false, onOk, onCancel }) => {
  return (
    <ModalCustom
      open={open}
      footer="fullLength"
      okText="Set up your Account"
      onCancel={onCancel}
      onOk={onOk}
    >
      <div className="welcome-root small-modal-content">
        <div className="welcome-header">
          <img src={WelcomeImage} alt="" className="welcome-image" />
          <ButtonCustom type="default" onClick={onCancel}>
            Skip
          </ButtonCustom>
        </div>
        <h2 className="modal-heading">Hey there!</h2>
        <p className="modal-subheading">
          Welcome to INDUSTRIA Dashboard. There are a lot of cool things you can do on here. Press
          the button below to get started.
        </p>
      </div>
    </ModalCustom>
  );
};

export default Welcome;
