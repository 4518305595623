/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import ModalCustom from '../../UI/ModalCustom/ModalCustom';
import SocialMediaBox from '../../SocialMediaBox/SocialMediaBox';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateUserSocialMedia } from '../../../redux/reducers/user/userApi';
import { selectedUser } from '../../../redux/reducers/user/userSelectors';
import { socialMediaReducer } from '../../../redux/reducers/socialMedia/socialMediaReducer';
import { SocialMediaActions } from '../../../redux/reducers/socialMedia/socialMediaActions';
import { SocialMedia } from '../../../redux/reducers/user/userInterface';

import { UISocialMedia } from '../../../Helpers/generalTypes';
import { removeHTTPSFromUrl, addHTTPSToUrl, isValidUrl } from '../../../Helpers/helperFunctions';

import WelcomeSocialImage from '../../../assets/images/welcome-social.png';
import './WelcomeSocialMedia.scss';

export type Props = {
  open?: boolean;
  onCancel: () => void;
  onCancelButton: () => void;
  onOk: () => void;
};

const WelcomeSocialMedia: React.FC<Props> = ({ open = false, onCancel, onCancelButton, onOk }) => {
  const currentUser = useAppSelector(selectedUser);
  const dispatch = useAppDispatch();

  const [socialMedia, dispatchSocialMedia] = React.useReducer(socialMediaReducer, []);
  const [canSubmit, setCanSubmit] = React.useState(false);

  const setDefaultSocialMedia = () => {
    dispatchSocialMedia({
      type: SocialMediaActions.GET_SOCIAL_MEDIA,
      socialMedia: currentUser?.socialMedia,
      initial: true,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(setDefaultSocialMedia, [currentUser]);

  React.useEffect(() => {
    const hasSocialMedia = socialMedia.filter(
      (socialMediaItem: UISocialMedia) => socialMediaItem.profileUrl,
    ).length;
    setCanSubmit(!!hasSocialMedia);
  }, [socialMedia, socialMedia.length]);

  const generateElementsData = (socialMediaArray: Array<UISocialMedia>) =>
    socialMediaArray.map((socialMediaItem: UISocialMedia, i) => {
      return {
        id: socialMediaItem.id,
        name: socialMediaItem.name,
        invalid: !!socialMediaItem.profileUrl && !isValidUrl(socialMediaItem.profileUrl),
        imageUrl: socialMediaItem.imageSrc,
        url: removeHTTPSFromUrl(socialMediaItem.url),
        profileUrl: socialMediaItem.profileUrl,
        lastItem: i === socialMediaArray.length - 1,
        onChange: (e: any) => {
          const dispatchObject = {
            type: SocialMediaActions.UPDATE_SOCIAL_MEDIA_URL,
            id: socialMediaItem.id,
            profileUrl: e.target.value,
          };
          dispatchSocialMedia(dispatchObject);
        },
      };
    });

  const generateElements = (socialMediaArray: Array<UISocialMedia>) => {
    const data = generateElementsData(socialMediaArray);
    return data.map((socialMediaElement: any) => {
      return <SocialMediaBox key={socialMediaElement.id} {...socialMediaElement} />;
    });
  };

  const socialMediaElements = socialMedia?.length ? generateElements(socialMedia) : null;

  const isFormInvalid =
    generateElementsData(socialMedia).filter((element) => element.invalid).length > 0;

  const submitHandler = () => {
    const dispatchObject: Array<SocialMedia> = socialMedia
      .map((socialMediaItem: UISocialMedia) => {
        return { name: socialMediaItem.name, url: addHTTPSToUrl(socialMediaItem.profileUrl) };
      })
      .filter((socialMediaItem: UISocialMedia) => socialMediaItem.url);
    currentUser &&
      dispatchObject.length &&
      dispatch(
        updateUserSocialMedia({
          currentUserId: currentUser.id,
          socialMedia: dispatchObject,
        }),
      );
    onOk();
  };

  return (
    <ModalCustom
      form
      open={open}
      onCancelButton={onCancelButton}
      onCancel={onCancel}
      onOk={submitHandler}
      okButtonDisabled={!canSubmit || isFormInvalid}
      okText="Finish"
      cancelText="Back"
    >
      <div className="welcome-social-media-header">
        <div className="welcome-social-media-header-text">
          <h2 className="modal-heading">Almost Done!</h2>
          <p className="modal-subheading">
            Add at least one social network and start making friends.
          </p>
        </div>
        <img className="welcome-social-media-image" src={WelcomeSocialImage} alt="" />
      </div>
      <ul className="welcome-social-media-list">{socialMediaElements}</ul>
    </ModalCustom>
  );
};

export default WelcomeSocialMedia;
