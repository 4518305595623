/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Form } from 'antd';

import './formCustom.scss';

export type Props = {
  className?: string;
  children?: React.ReactNode;
  onSubmit?: ([arg]?: any) => void;
  onError?: ([arg]?: any) => any;
  [key: string]: any;
};

const FormCustom: React.FC<Props> = ({
  className = '',
  children,
  onSubmit,
  onError,
  ...otherProps
}) => {
  return (
    <Form
      className={`${className}formCustom-root`}
      onFinish={onSubmit}
      onFinishFailed={onError}
      {...otherProps}
    >
      {children}
    </Form>
  );
};

export default FormCustom;
