import * as React from 'react';
import { uid } from 'uid';
import { BaseTimeEntry } from '../../../redux/sharedInterface';
import { formatTimeString, getTotalTimeFromTimeEntries } from '../../../Helpers/helperFunctions';
import { CalendarModes } from '../../../Helpers/generalTypes';
import { workingHoursInADay, workingHoursInAWeek } from '../../../Helpers/constants';

export type Props = {
  mode: CalendarModes;
  timeEntries: Array<BaseTimeEntry>;
  allShown?: boolean;
};

const maxElementsShown = 3;

const TeamTimeTrackerDay: React.FC<Props> = ({ allShown, mode, timeEntries }) => {
  const totalTime = getTotalTimeFromTimeEntries(timeEntries);
  const isWeekly = mode === 'week';

  const timeEntriesToShow = allShown ? timeEntries : timeEntries.slice(0, maxElementsShown);

  const timeEntriesElements = timeEntriesToShow.map((timeEntry) => (
    <div
      className={`teamTimeTracker-calendar-time-entry teamTimeTracker-calendar-time-entry-${
        timeEntry.isBillable ? 'billable' : 'nonbillable'
      }`}
      key={uid()}
    >
      <span className="teamTimeTracker-calendar-time-entry-project">{timeEntry.projectName}</span>
      <span>{formatTimeString(timeEntry.hoursDecimal)}</span>
    </div>
  ));

  if (!allShown && timeEntries.length > maxElementsShown) {
    timeEntriesElements.push(
      <div className="teamTimeTracker-calendar-time-entry-more" key={uid()}>
        +{timeEntries.length - maxElementsShown}
      </div>,
    );
  }

  return (
    <div className="teamTimeTracker-calendar-time">
      <div className="teamTimeTracker-calendar-time-total">
        {totalTime} / {isWeekly ? workingHoursInADay : workingHoursInAWeek}
      </div>
      <div className="teamTimeTracker-calendar-time-entries">{timeEntriesElements}</div>
    </div>
  );
};

export default TeamTimeTrackerDay;
