/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from 'axios';
// export const baseAPIURL = `${process.env.REACT_APP_API_URL}`; // create env files for manual BE

export const axiosInstance = axios.create({
  withCredentials: true,
});

export class MainApi {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      xsrfHeaderName: 'placeholder-header',
      withCredentials: true,
    });
  }

  public async getCsrfToken(): Promise<void> {
    await this.instance.get('/api/v1/csrf/token').then((response: any) => {
      this.instance.defaults.headers.put['X-XSRF-TOKEN'] = response.data.token;
      this.instance.defaults.headers.post['X-XSRF-TOKEN'] = response.data.token;
      this.instance.defaults.headers.delete['X-XSRF-TOKEN'] = response.data.token;
    });
  }

  // get Instance of the class
  public getInstance(): AxiosInstance {
    return this.instance;
  }
}
