import * as React from 'react';
import { Tag } from 'antd';
import IconCustom from '../IconCustom/IconCustom';
import { StatusTagProps } from '../../Helpers/generalTypes';
import './statusTag.scss';

export interface Props extends StatusTagProps {
  className?: string;
}

const StatusTag: React.FC<Props> = ({ className, color, icon, text }) => {
  const colors = {
    green: {
      text: 'emerald-600',
      background: 'emerald-50',
    },
    red: {
      text: 'rose-500',
      background: 'rose-200',
    },
    blue: {
      text: 'sky-400',
      background: 'sky-100',
    },
    yellow: {
      text: 'amber-300',
      background: 'amber-50',
    },
  };

  return (
    <Tag className={`statusTag-root ${className} bg-${colors[color].background}`}>
      {icon ? <IconCustom className="mr-0.5" icon={icon} color={colors[color].text} /> : null}
      <span className={`text-${colors[color].text}`}>{text}</span>
    </Tag>
  );
};

export default StatusTag;
