import { useAppSelector } from '../redux/hooks';
import { loggedUser, selectedUser } from '../redux/reducers/user/userSelectors';

const useIsSameProfile = () => {
  const currentLoggedUser = useAppSelector(loggedUser);
  const currentSelectedUser = useAppSelector(selectedUser);
  return currentLoggedUser?.id === currentSelectedUser?.id;
};

export default useIsSameProfile;
