/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import AvatarCustom from '../../../Components/AvatarCustom/AvatarCustom';
import SelectCustom from '../../../Components/UI/SelectCustom/SelectCustom';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import ButtonCustom from '../../../Components/UI/ButtonCustom/ButtonCustom';
import IconCustom from '../../../Components/IconCustom/IconCustom';
import { levels } from '../../../Helpers/constants';
import './TechnologyBox.scss';

interface BasicProps {
  id: string;
  name: string;
  experience: string;
  url: string;
  status?: string;
  onChange: ([arg]?: any) => void;
}

interface PresetTechProps extends BasicProps {
  custom?: never;
  onEdit?: never;
  onDelete?: never;
  imageUrl: string;
}

interface CustomTechProps extends BasicProps {
  custom: true;
  onEdit: () => void;
  onDelete: () => void;
  imageUrl?: never;
}

export type Props = PresetTechProps | CustomTechProps;

const TechnologyBox: React.FC<Props> = ({
  custom,
  id,
  imageUrl,
  name,
  experience,
  url,
  status,
  onChange,
  onEdit,
  onDelete,
}) => {
  return (
    <>
      <div className="TechnologyBox-root">
        <div className="TechnologyBox-header">
          {!custom && (
            <AvatarCustom size="sm" imageSrc={imageUrl} className="mr-4" shape="square" />
          )}
          <div className="TechnologyBox-info">
            <p className="TechnologyBox-name">
              {name}
              {status === 'Approved' && <IconCustom className="pl-1" avatarIcon="success" />}
              {status === 'Declined' && <IconCustom className="pl-1" avatarIcon="error" />}
              {status === 'Pending' && <IconCustom className="pl-1" avatarIcon="updatedProfile" />}
            </p>
            <p className="TechnologyBox-url">{url}</p>
          </div>
        </div>
        <div className="flex justify-end items-center">
          <SelectCustom
            className="w-36"
            id={id}
            label=""
            value={{ label: experience, value: experience }}
            options={levels.map((level) => {
              return { label: level, value: level };
            })}
            onChange={onChange}
          />
          {custom && (
            <div className="flex ml-4">
              <ButtonCustom type="text" className="technologyBox-button" onClick={onDelete}>
                <IconCustom icon="MdDeleteForever" className="text-lg" color="gray-400" />
              </ButtonCustom>
              <ButtonCustom type="text" className="technologyBox-button" onClick={onEdit}>
                <IconCustom icon="MdEdit" className="text-lg ml-1" color="gray-400" />
              </ButtonCustom>
            </div>
          )}
        </div>
      </div>
      <DividerCustom />
    </>
  );
};

export default TechnologyBox;
