import React from 'react';
import BasicInformationSection from './BasicInformationSection';
import AdditionalInformationSection from './AdditionalInformationSection';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import { useAppSelector } from '../../../redux/hooks';
import { selectedUser } from '../../../redux/reducers/user/userSelectors';

import './EditPersonalProfile.scss';

const EditPersonalProfileTab: React.FC = () => {
  const currentSelectedUser = useAppSelector(selectedUser);
  return (
    currentSelectedUser && (
      <>
        <BasicInformationSection user={currentSelectedUser} />
        <DividerCustom />
        <AdditionalInformationSection user={currentSelectedUser} />
      </>
    )
  );
};

export default EditPersonalProfileTab;
