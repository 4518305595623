import * as React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import AvatarCustom from '../../../Components/AvatarCustom/AvatarCustom';
import IconCustom from '../../../Components/IconCustom/IconCustom';

import PaginationCustom from '../../../Components/PaginationCustom/PaginationCustom';
import TableCustom from '../../../Components/TableCustom/TableCustom';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { loggedUser, usersList } from '../../../redux/reducers/user/userSelectors';
import { getUsers } from '../../../redux/reducers/user/userApi';

import { ReactComponent as EditIcon } from '../../../assets/svg/icon-edit.svg';

const UserManagement: React.FC = () => {
  const dispatch = useAppDispatch();
  const userManagementData = useAppSelector(usersList);
  const currentLoggedUser = useAppSelector(loggedUser);
  const [currentPage, setCurrentPage] = React.useState(1);
  const usersPerPage = 9;

  const getInitials = (firstName: string, lastName?: string) => {
    if (firstName) {
      return lastName ? `${firstName[0]}${lastName[0]}` : firstName[0];
    }
  };

  const data = userManagementData?.data?.map((person) => {
    const dateJoinedOn = dayjs(person.joinedOn);
    const displayDate = dateJoinedOn.format('MMM DD, YYYY');

    const editItem = () => {
      if (currentLoggedUser?.email === userManagementData.data[0].managerEmail) {
        return (
          <span className="user-edit-link-wrapper">
            <Link
              className="user-edit-link"
              to={`/profile/${person.id}`}
              title="go to user profile page"
            >
              <IconCustom className="user-edit" component={EditIcon} />
            </Link>
          </span>
        );
      } else {
        return;
      }
    };
    const teamClass = (() => {
      return `team-0${person.teams[0].id || 0}`;
    })();
    return {
      key: person.id,
      name: (
        <Link to={`/profile/${person.id}`}>
          <div className="user">
            <AvatarCustom
              size="md"
              imageSrc={person.profilePicture}
              fallbackText={getInitials(person.firstName, person.lastName)}
              className="user-avatar"
            />
            <div className="user-info">
              <span className="user-info-name">
                {person.firstName} {person.lastName}
              </span>
              <span className="user-info-job-role">{person.jobRole}</span>
            </div>
          </div>
        </Link>
      ),
      email: <div className="user-email">{person.email}</div>,
      team: <span className={`user-team ${teamClass}`}>{person.teams[0].name}</span>,
      permissions: <span className="user-permissions">{person.systemRole}</span>,
      dateAdded: <div className="user-joined">{displayDate}</div>,
      edit: editItem(),
    };
  });

  React.useEffect(() => {
    const offset = (currentPage - 1) * usersPerPage;
    dispatch(getUsers({ usersPerPage, offset }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersPerPage, currentPage]);

  return data && data.length ? (
    <>
      <h2>Users</h2>
      <TableCustom
        columns={['Name', 'Email', 'Team', 'Permissions', 'Date Added', 'Edit']}
        dataSource={data}
        className="user-management"
      />
      <PaginationCustom
        pageSize={usersPerPage}
        current={currentPage}
        onChange={setCurrentPage}
        total={userManagementData?.totalElements || 0}
      />
    </>
  ) : null;
};

export default UserManagement;
