import * as React from 'react';
import EducationCard from './EducationCard';
import EducationForm from './EducationForm/EducationForm';
import DividedSection from '../../../Components/DividedSection/DividedSection';
import ButtonCustom from '../../../Components/UI/ButtonCustom/ButtonCustom';
import { Education } from '../../../redux/reducers/user/userInterface';

export type Props = {
  education: Array<Education>;
  userId?: string;
};

const cardsShownByDefault = 2;

const EducationSection: React.FC<Props> = ({ education, userId }) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const closeModal = () => setModalOpen(false);

  const educationCards = education.length
    ? education?.map((item: Education) => (
        <EducationCard educationItem={item} key={item.id} userId={userId} />
      ))
    : null;

  const [isShowingAll, setIsShowingAll] = React.useState(false);
  const [educationCount, setEducationCount] = React.useState<number>(cardsShownByDefault);

  const handleShowMore = () => {
    if (education?.length) {
      if (educationCount < education?.length) {
        setEducationCount(educationCount + 2);
        setIsShowingAll(false);
      } else {
        setEducationCount(education?.length);
        setIsShowingAll(true);
      }
    }
  };

  const handleShowLess = () => {
    setIsShowingAll(false);
    setEducationCount(cardsShownByDefault);
  };

  return (
    <>
      <DividedSection
        title="Education"
        subtitle="Share your hard-earned credentials with us."
        buttonAction={() => {
          setModalOpen(true);
        }}
      >
        {!educationCards || !educationCards.length ? (
          <p>No education added</p>
        ) : isShowingAll ? (
          educationCards
        ) : (
          educationCards.slice(0, educationCount)
        )}
        {educationCards && !isShowingAll && educationCards.length > educationCount ? (
          <ButtonCustom
            type="default"
            className="w-full block text-center"
            onClick={() => handleShowMore()}
          >
            Show More
          </ButtonCustom>
        ) : (
          <ButtonCustom
            type="default"
            className="w-full block text-center"
            onClick={() => handleShowLess()}
          >
            Show Less
          </ButtonCustom>
        )}
      </DividedSection>
      {modalOpen && (
        <EducationForm onCancel={closeModal} onOk={closeModal} open={modalOpen} userId={userId} />
      )}
    </>
  );
};

export default EducationSection;
