import { ReactElement, useEffect, useMemo } from 'react';
import { axiosInstance } from '../../axios-instance';
import { useAppSelector } from '../../redux/hooks';
import { userStatus } from '../../redux/reducers/user/userSelectors';

interface Props {
  children: ReactElement | null;
}

const AxiosInterceptor: React.FC<Props> = ({ children }) => {
  const reqInterceptor = useMemo(() => {
    return axiosInstance.interceptors.request.use(
      (request) => {
        if (request.url?.includes('/api/v1') && !request.url.includes('http')) {
          request.url = `${request.url}`;
          console.log(request.url);
        }

        return request;
      },
      (error) => error,
    );
  }, []);

  const isLoggedIn = useAppSelector(userStatus);

  const resInterceptor = useMemo(() => {
    return axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.config.url !== '/logout' && isLoggedIn) {
          if (
            error.response.status === 401 ||
            (error.response.status === 403 && error.response.config.url === '/users/me') ||
            error.response.config.url.includes('/auth')
          ) {
            if (isLoggedIn) {
              // Prevent adding `auth` route url in `?redirect=/auth/google` and preserve real redirect url
              const redirectUrl = error.response.config.url.includes('/auth')
                ? localStorage.getItem('redirect') || ''
                : window.location.pathname;
              console.log(redirectUrl);

              //   AuthContext.logout(true, redirectUrl);
            }
          } else if (error.response.status === 403 && error.response.config.url !== '/users/me') {
            // Check if the status code is 403 and then
            // if the user is really logged in.
            // If not 401 status code should cause Logout!
            // AuthContext.fetchLoggedUser();
          }
        }

        return Promise.reject(error);
      },
    );
  }, [isLoggedIn]);

  useEffect(() => {
    axiosInstance.interceptors.request.eject(reqInterceptor);
    axiosInstance.interceptors.response.eject(resInterceptor);
  }, [isLoggedIn, reqInterceptor, resInterceptor]);

  return children;
};

export default AxiosInterceptor;
