import * as React from 'react';
import { Typography } from 'antd';
import DividedSection from '../DividedSection/DividedSection';
import IconCustom from '../IconCustom/IconCustom';
import TagCustom from '../UI/TagCustom/TagCustom';
import UserProfileLinks from '../UserProfileLinks/UserProfileLinks';
import UserTechnologies from '../UserTechnologies/UserTechnologies';
import UserProfileEmptySectionPrompt from '../UserProfileEmptySectionPrompt/UserProfileEmptySectionPrompt';
import { capitalizeWord, createElementFromString, formatDate } from '../../Helpers/helperFunctions';
import { Skills, Technology, User } from '../../redux/reducers/user/userInterface';
import './userInfoSection.scss';

export type Props = {
  birthday: string | null;
  hobbies: Array<string>;
  skills: Array<Skills>;
  technologies: Array<Technology>;
  user: User;
  isDifferentProfile?: boolean;
};

const { Text, Title } = Typography;

const UserInfoSection: React.FC<Props> = ({
  birthday,
  hobbies,
  isDifferentProfile,
  skills,
  technologies,
  user,
}) => {
  const biographySanitized = createElementFromString(user.biography);
  const hasBiography =
    biographySanitized && biographySanitized[0] && biographySanitized[0].props?.children;
  const hasSkills = skills && skills.length;
  const hasTechnologies = technologies && technologies.length;
  const hasHobbies = hobbies && hobbies.length;

  return (
    <DividedSection
      className="userInfoSection-root"
      leftSectionComponent={<UserProfileLinks socialMediaLinks={user.socialMedia} />}
    >
      <div className="user-personal-info-section">
        {hasBiography || !isDifferentProfile ? (
          <div className="user-personal-info-subsection">
            <Title level={3} className="user-bio-label">
              About Me
            </Title>
            <div className="user-info ml-0">
              {hasBiography ? (
                biographySanitized
              ) : (
                <UserProfileEmptySectionPrompt
                  section="Biography"
                  emptySectionLinkPath="/settings#personalProfile"
                />
              )}
            </div>
          </div>
        ) : null}

        {hasSkills || !isDifferentProfile ? (
          <div className="user-personal-info-subsection">
            <Title level={3} className="inner-subheading">
              Skills
            </Title>
            <div>
              {hasSkills ? (
                skills.map((skillItem) => (
                  <TagCustom key={skillItem.skill}>
                    {skillItem.skill} ({capitalizeWord(skillItem.level)})
                  </TagCustom>
                ))
              ) : (
                <UserProfileEmptySectionPrompt
                  section="Skills"
                  emptySectionLinkPath="/settings#experienceAndEducation"
                />
              )}
            </div>
          </div>
        ) : null}

        {hasTechnologies || !isDifferentProfile ? (
          <div className="user-personal-info-subsection">
            <Title level={3} className="inner-subheading">
              Technologies
            </Title>
            {hasTechnologies ? (
              <UserTechnologies
                technologies={technologies}
                isDifferentProfile={isDifferentProfile}
              />
            ) : (
              <UserProfileEmptySectionPrompt
                section="Technologies"
                emptySectionLinkPath="/settings#technologies"
              />
            )}
          </div>
        ) : null}

        {birthday && !user.doNotShowBirthday ? (
          <div className="user-personal-info-subsection">
            <Title level={3} className="inner-subheading">
              Birthday
            </Title>
            <Text className="text-gray-500">
              <IconCustom className="text-gray-500" icon="MdCake" />{' '}
              {formatDate(birthday, 'MMM DD, YYYY')}
            </Text>
          </div>
        ) : null}

        {hasHobbies || !isDifferentProfile ? (
          <div className="user-personal-info-subsection">
            <Title level={3} className="inner-subheading">
              Hobbies
            </Title>
            <div>
              {hasHobbies ? (
                hobbies.map((hobbyItem: string) => (
                  <TagCustom key={hobbyItem}>{hobbyItem}</TagCustom>
                ))
              ) : (
                <UserProfileEmptySectionPrompt
                  section="Hobbies"
                  emptySectionLinkPath="/settings#personalProfile"
                />
              )}
            </div>
          </div>
        ) : null}
      </div>
    </DividedSection>
  );
};

export default UserInfoSection;
