import * as React from 'react';
import AvatarCustom from './AvatarCustom';
import { useAppSelector } from '../../redux/hooks';
import { loggedUser } from '../../redux/reducers/user/userSelectors';
import './avatarCustom.scss';

interface Props {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className: string;
}

const UserAvatarCustom: React.FC<Props> = ({ size, className = '' }) => {
  const currentLoggedUser = useAppSelector(loggedUser);

  return (
    <>
      <AvatarCustom
        className={className}
        imageSrc={currentLoggedUser?.profilePicture}
        size={size}
        fallbackText={`${currentLoggedUser?.firstName[0]}${currentLoggedUser?.lastName[0]}`}
      />
    </>
  );
};

export default UserAvatarCustom;
