import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useRoutes } from 'react-router-dom';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { routes, userRoutes } from './Router/Router';
import WelcomeWizard from './Components/WelcomeWizard/WelcomeWizard';
import AxiosInterseptor from './Helpers/AxiosInterseptor/AxiosInterseptor';
import { appTitle } from './Helpers/constants';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { loggedUser, userStatus } from './redux/reducers/user/userSelectors';
import { getAllDataForLoggedUser, getLoggedUser } from './redux/reducers/user/userApi';
import useErrorMessage from './hooks/useErrorMessage';

export const API_URL = `${process.env.REACT_APP_API_URL}`;

dayjs.extend(updateLocale);
dayjs.updateLocale('en', { weekStart: 1 });

const App = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(userStatus);
  const user = useAppSelector(loggedUser);
  const userId = user?.id;

  useErrorMessage();

  // eslint-disable-next-line array-callback-return, @typescript-eslint/no-explicit-any
  routes.find((route: any) => {
    if (route.path === '/') {
      if (isLoggedIn) {
        route.children = userRoutes;
      }
    }
  });

  useEffect(() => {
    dispatch(getLoggedUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.id) {
      dispatch(getAllDataForLoggedUser(user.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const COMPLETENESS_DELIMITER = 40;
  const [shouldOpenWizard, setShouldOpenWizard] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      const shouldOpen = user.profileCompleteness < COMPLETENESS_DELIMITER;
      setShouldOpenWizard(shouldOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const RoutesElement = useRoutes(routes);

  return (
    <>
      <Helmet>
        <title>{appTitle}</title>
      </Helmet>
      <div className="App">
        {shouldOpenWizard && <WelcomeWizard started={true} />}
        <AxiosInterseptor>{RoutesElement}</AxiosInterseptor>
      </div>
    </>
  );
};

export default App;
