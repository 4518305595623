/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { GlobalState } from './globalInterface';

// TODO: check the implementation of dayjs as this returns a non-serializable and it should be.
// we need to refactor dates throughout.
const initialState: GlobalState = {
  timeRangePicker: dayjs(new Date()),
};

/// TODO: Refactor into different slices

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // logout: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.isLoggedIn = false;
    // },
    changeDatePeriod: (state, action) => {
      state.timeRangePicker = action.payload;
    },
  },
});
export const { changeDatePeriod } = globalSlice.actions;

export default globalSlice.reducer;
