import * as React from 'react';
import { notification } from 'antd';
import { useAppSelector } from '../redux/hooks';
import { isPending, error } from '../redux/reducers/user/userSelectors';

const useErrorMessage = (message = 'Whoops! Something went wrong!') => {
  const pending = useAppSelector(isPending);
  const currentError = useAppSelector(error);

  React.useEffect(() => {
    if (!pending) {
      if (currentError && !currentError.includes('401')) {
        notification.error({
          message,
          description: currentError,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending, currentError]);

  return null;
};

export default useErrorMessage;
