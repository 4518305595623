/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import { Dayjs } from 'dayjs';
import { uid } from 'uid';
import TeamTimeTrackerPersonBox from './TeamTimeTrackerPersonBox';
import TeamTimeTrackerDay from './TeamTimeTrackerDay';
import {
  getAllDatesInAWeek,
  getTotalTimeFromTimeEntries,
  formatDate,
  getWidthOfElementInAGroup,
} from '../../../Helpers/helperFunctions';
import { workingHoursInAWeek } from '../../../Helpers/constants';
import { GroupedTimeEntry, TeamMember } from '../../../redux/reducers/team/teamInterface';
import { BaseTimeEntry, TimeEntry } from '../../../redux/sharedInterface';

interface BasicProps {
  from: Dayjs;
  singlePerson?: boolean;
}

interface PropsWithData extends BasicProps {
  data: Array<TeamMember<TimeEntry>>;
  groupedData?: never;
}

interface PropsWithGroupedData extends BasicProps {
  data?: never;
  groupedData: Array<TeamMember<GroupedTimeEntry>>;
}

export type Props = PropsWithData | PropsWithGroupedData;

const TeamTimeTrackerWeekly: React.FC<Props> = ({ data, from, singlePerson, groupedData }) => {
  const weekDates = getAllDatesInAWeek(from);

  const weekDateHeaders = weekDates.map((weekDate) => (
    <div className="teamTimeTracker-calendar-date" key={uid()}>
      {weekDate.format('ddd D')}{' '}
    </div>
  ));

  const gridTemplateColumnsStyle = {
    gridTemplateColumns: `${singlePerson ? '' : '20% '}repeat(${
      weekDates.length
    }, ${getWidthOfElementInAGroup(weekDates, singlePerson ? 100 : 80)})`,
  };

  const getUserElements = (array: Array<TeamMember<BaseTimeEntry>>, isGrouped: boolean) =>
    array.map((user) => {
      const timeEntries = [...user.timeEntries].sort((a, b) => b.projectId - a.projectId);
      const time = weekDates.map((weekDate) => {
        return (
          <TeamTimeTrackerDay
            allShown={singlePerson}
            mode="week"
            key={uid()}
            timeEntries={
              timeEntries.filter(
                (timeEntry: any) =>
                  formatDate(isGrouped ? timeEntry.fromDate : timeEntry.date) ===
                  formatDate(weekDate),
              ) || []
            }
          />
        );
      });

      const totalTimeForPeriod = getTotalTimeFromTimeEntries(timeEntries);
      return (
        <>
          {!singlePerson && (
            <div className="teamTimeTracker-calendar-team-member-details" key={uid()}>
              <TeamTimeTrackerPersonBox
                {...user}
                benchmarkHours={workingHoursInAWeek}
                totalTime={totalTimeForPeriod}
              />
            </div>
          )}
          {time}
        </>
      );
    });

  return (
    <div className="teamTimeTracker-calendar-grid" style={gridTemplateColumnsStyle}>
      {!singlePerson && <div className="teamTimeTracker-calendar-team-member">Team members</div>}{' '}
      {weekDateHeaders}
      {groupedData ? getUserElements(groupedData, true) : getUserElements(data, false)}
    </div>
  );
};

export default TeamTimeTrackerWeekly;
