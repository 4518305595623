import * as React from 'react';
import DoughnutChart from './DoughnutChart';

export type Props = {
  userId: string;
};

const DoughnutChartsSection: React.FC<Props> = ({ userId }) => {
  return (
    <div className="doughnutChartsSection-root">
      <DoughnutChart userId={userId} type="byProject" />
      <DoughnutChart userId={userId} type="byBillable" />
    </div>
  );
};

export default DoughnutChartsSection;
