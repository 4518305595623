/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import RequesterCard from './RequesterCard';

import IconCustom from '../../../../Components/IconCustom/IconCustom';

// eslint-disable-next-line import/order
import { useParams, useNavigate } from 'react-router-dom';
import HeadingWithBackButton from '../../../../Components/HeadingWithBackButton/HeadingWithBackButton';
import DividerCustom from '../../../../Components/UI/DividerCustom/DividerCustom';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

import {
  getEducationRequests,
  getExperienceRequests,
  getSelectedUser,
  getSkillRequests,
  getSocialMediaRequests,
  getTechnologyRequests,
  updateEducationRequestStatus,
  updateExperienceRequestStatus,
  updateSkillRequestStatus,
  updateSocialMediaRequestStatus,
  updateTechnologyRequestStatus,
} from '../../../../redux/reducers/user/userApi';
import {
  educationRequests,
  experienceRequests,
  selectedUser,
  skillRequests,
  socialMediaRequests,
  technologyRequests,
} from '../../../../redux/reducers/user/userSelectors';

// styles
import './singleRequestReviewDetails.scss';

interface ApprovePayload {
  userId: string;
  action: 'Approve' | 'Decline';
  notes: string;
}

const SingleRequestReviewDetails: React.FC = () => {
  const dispatch = useAppDispatch();

  const { requestType, userId } = useParams();
  const navigate = useNavigate();
  const userDetails = useAppSelector(selectedUser);
  const getInitials = (firstName: string | undefined, lastName: string | undefined): string => {
    const firstInitial = firstName ? firstName[0] : '';
    const lastInitial = lastName ? lastName[0] : '';
    return firstInitial + lastInitial;
  };

  const requestTypeMap: { [key: string]: string } = {
    technology: 'Technology',
    skill: 'Skill',
    education: 'Education',
    experience: 'Experience',
    'social media': 'Social Media',
  };

  const requestData = useAppSelector((state) => {
    switch (requestType) {
      case 'technology':
        return technologyRequests(state);
      case 'skill':
        return skillRequests(state);
      case 'education':
        return educationRequests(state);
      case 'experience':
        return experienceRequests(state);
      case 'social media':
        return socialMediaRequests(state);
      default:
        return null;
    }
  });

  const handleInit = () => {
    if (userId) {
      dispatch(getSelectedUser(userId));
      dispatch(getTechnologyRequests(userId));
      dispatch(getSkillRequests(userId));
      dispatch(getEducationRequests(userId));
      dispatch(getSocialMediaRequests(userId));
      dispatch(getExperienceRequests(userId));
    }
  };

  // const reviewerNote = useInputField('', true);

  const approveHandler = (requestItemId: number, notes: string) => {
    if (!userId) {
      return;
    }

    const approvePayload: ApprovePayload = {
      userId,
      action: 'Approve',
      notes,
    };

    switch (requestType) {
      case 'technology': {
        const technologyPayload = { ...approvePayload, technologyId: requestItemId };
        dispatch(updateTechnologyRequestStatus(technologyPayload));
        break;
      }
      case 'skill': {
        const skillPayload = { ...approvePayload, skillId: requestItemId };
        dispatch(updateSkillRequestStatus(skillPayload));
        break;
      }
      case 'education': {
        const educationPayload = { ...approvePayload, educationId: requestItemId };
        dispatch(updateEducationRequestStatus(educationPayload));
        break;
      }
      case 'experience': {
        const experiencePayload = { ...approvePayload, experienceId: requestItemId };
        dispatch(updateExperienceRequestStatus(experiencePayload));
        break;
      }
      case 'social media': {
        const socialMediaPayload = { ...approvePayload, socialMediaId: requestItemId };
        dispatch(updateSocialMediaRequestStatus(socialMediaPayload));
        break;
      }
      default:
        break;
    }
  };

  const declineHandler = (requestItemId: number, notes: string) => {
    if (!userId) {
      return;
    }
    const declinePayload: ApprovePayload = {
      userId,
      action: 'Decline',
      notes,
    };

    switch (requestType) {
      case 'technology': {
        const technologyPayload = { ...declinePayload, technologyId: requestItemId };
        dispatch(updateTechnologyRequestStatus(technologyPayload));
        break;
      }
      case 'skill': {
        const skillPayload = { ...declinePayload, skillId: requestItemId };
        dispatch(updateSkillRequestStatus(skillPayload));
        break;
      }
      case 'education': {
        const educationPayload = { ...declinePayload, educationId: requestItemId };
        dispatch(updateEducationRequestStatus(educationPayload));
        break;
      }
      case 'experience': {
        const experiencePayload = { ...declinePayload, experienceId: requestItemId };
        dispatch(updateExperienceRequestStatus(experiencePayload));
        break;
      }
      case 'social media': {
        const socialMediaPayload = { ...declinePayload, socialMediaId: requestItemId };
        dispatch(updateSocialMediaRequestStatus(socialMediaPayload));
        break;
      }
      default:
        break;
    }
  };

  // get request status
  function getStatusSpan(status: string) {
    const statusClass = {
      Pending: 'singleRequestReviewDetails-root-single-review_status-pending',
      Approved: 'singleRequestReviewDetails-root-single-review_status-approved',
      Rejected: 'singleRequestReviewDetails-root-single-review_status-rejected',
    };

    return (
      <span
        className={`singleRequestReviewDetails-root-single-review_status ${
          statusClass[status as keyof typeof statusClass]
        }`}
      >
        {status === 'Approved' ? (
          <>
            <IconCustom
              icon="MdDone"
              className="singleRequestReviewDetails-root-single-review_status-icon"
            />
            <span className="sr-only">Request status is {status.toLowerCase()}</span>
          </>
        ) : status === 'Rejected' ? (
          <>
            <IconCustom
              icon="MdClose"
              className="singleRequestReviewDetails-root-single-review_status-icon"
            />
            <span className="sr-only">Request status is {status.toLowerCase()}</span>
          </>
        ) : (
          <span className="sr-only">Request status is {status.toLowerCase()}</span>
        )}
      </span>
    );
  }

  React.useEffect(() => {
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const renderRequestSection = (request: any) => {
    return (
      <section className="singleRequestReviewDetails-root-single-review" key={request.id}>
        <div className="singleRequestReviewDetails-root-single-review_type-status">
          <h3>{requestTypeMap[requestType as string]}</h3>
          {getStatusSpan(request.status)}
        </div>

        <div className="singleRequestReviewDetails-root-single-review_summary">
          <RequesterCard
            requestTypeName={request}
            imageSrc={userDetails?.profilePicture}
            fallbackText={getInitials(userDetails?.firstName, userDetails?.lastName)}
            firstName={userDetails?.firstName}
            lastName={userDetails?.lastName}
            approval={(event: string) => approveHandler(request.id, event)}
            decline={(event: string) => declineHandler(request.id, event)}
          />
        </div>
      </section>
    );
  };

  return (
    <div className="container-root">
      <HeadingWithBackButton onBackButtonClick={() => navigate(-1)} showBackButton={true}>
        Requests Review
      </HeadingWithBackButton>
      <DividerCustom />

      {requestData && requestData.map((request: any) => renderRequestSection(request))}
    </div>
  );
};

export default SingleRequestReviewDetails;
