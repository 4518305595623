import * as React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import AvatarCustom from '../AvatarCustom/AvatarCustom';
import IconCustom from '../IconCustom/IconCustom';
import DividerCustom from '../UI/DividerCustom/DividerCustom';
import { Icon } from '../../Helpers/generalTypes';
import { useAppSelector } from '../../redux/hooks';
import { loggedUser } from '../../redux/reducers/user/userSelectors';
import './profileInHeader.scss';

const { Text } = Typography;

export type Props = Record<string, never>;

const ProfileInHeader: React.FC = () => {
  const currentLoggedUser = useAppSelector(loggedUser);
  const userIsManager =
    currentLoggedUser?.systemRole === 'Admin' || currentLoggedUser?.systemRole === 'Moderator';

  const userHeaderProfileInfo = [
    {
      navId: 'profile',
      iconName: 'MdAccountBox',
      text: 'Profile',
      to: `/profile/${currentLoggedUser?.id}`,
    },
    {
      navId: 'settings',
      iconName: 'MdSettings',
      text: 'Settings',
      to: '/settings',
    },
  ];

  return (
    <div className="profileInHeader-root">
      <div className="profileInHeader-root-person">
        <AvatarCustom
          size={'sm'}
          className="profileInHeader-root-avatar"
          imageSrc={currentLoggedUser?.profilePicture}
          fallbackText={`${currentLoggedUser?.firstName[0]}${currentLoggedUser?.lastName[0]}`}
        />
        <div className="flex flex-col justify-start leading-normal">
          <span className="text-gray-900 font-semibold">
            {currentLoggedUser?.firstName} {currentLoggedUser?.lastName}{' '}
          </span>
          <span className="text-gray-400">{currentLoggedUser?.email}</span>
        </div>
      </div>
      <DividerCustom className="mt-4 mb-1" />
      <div className={`profileInHeader-root-person flex-col mt-2 ${userIsManager ? '' : 'mb-4'}`}>
        <span className="text-gray-400 uppercase">User Options</span>

        <ul className="ant-menu-item-group-list profileInHeader-root-list">
          {userHeaderProfileInfo &&
            userHeaderProfileInfo.map((UserSetting) => {
              return (
                <li
                  className="ant-menu-item ant-menu-item-only-child profileInHeader-root-list-item"
                  key={UserSetting.navId}
                >
                  <Link
                    to={UserSetting.to}
                    className="profileInHeader-root-list-item-anchor"
                    role="button"
                    target="_self"
                  >
                    <IconCustom
                      icon={UserSetting.iconName as Icon}
                      className="profileInHeader-root-list-item-icon"
                      color="gray-400"
                      bgColor="transparent"
                    />
                    <Text className="font-semibold">{UserSetting.text}</Text>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
      {userIsManager && (
        <>
          <DividerCustom className="my-1" />
          <div className="profileInHeader-root-person flex-col mt-2 mb-4">
            <span className="text-gray-400 uppercase">Moderation</span>
            <ul className="ant-menu-item-group-list profileInHeader-root-list">
              <li className="ant-menu-item ant-menu-item-only-child profileInHeader-root-list-item">
                <Link
                  to="/user-management"
                  className="profileInHeader-root-list-item-anchor"
                  role="button"
                  target="_self"
                >
                  <Text className="font-semibold">User Management</Text>
                </Link>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileInHeader;
