import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { initialUrl, userStatus } from '../../redux/reducers/user/userSelectors';

interface Props {
  children: React.ReactNode;
  not?: boolean;
}

const RequireAuth: React.FC<Props> = ({ children, not }): JSX.Element | null => {
  const isLoggedIn = useAppSelector(userStatus);
  const redirectInitial = useAppSelector(initialUrl);

  if (not && isLoggedIn) {
    return <Navigate replace to={redirectInitial === '/login' ? '/' : redirectInitial} />;
  } else if (!not && !isLoggedIn) {
    if (isLoggedIn === null) {
      return null; // TODO: add loader here
    } else return <Navigate replace to={`login?redirect=${redirectInitial}`} />;
  }

  return <>{children}</>;
};

export default RequireAuth;
