import * as React from 'react';
import EducationForm from './EducationForm/EducationForm';
import CardCustom from '../../../Components/UI/CardCustom/CardCustom';
import AvatarCustom from '../../../Components/AvatarCustom/AvatarCustom';
import IconCustom from '../../../Components/IconCustom/IconCustom';
import ButtonCustom from '../../../Components/UI/ButtonCustom/ButtonCustom';
import ModalCustom from '../../../Components/UI/ModalCustom/ModalCustom';
import { useAppDispatch } from '../../../redux/hooks';
import { Education } from '../../../redux/reducers/user/userInterface';
import { deleteUserEducation } from '../../../redux/reducers/user/userApi';
import { ReactComponent as IconDelete } from '../../../assets/svg/icon-delete.svg';
import { createElementFromString } from '../../../Helpers/helperFunctions';

export type Props = {
  educationItem: Education;
  userId?: string;
  isEditable?: boolean;
};

const EducationCard: React.FC<Props> = ({ educationItem, isEditable = true, userId }) => {
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const dispatch = useAppDispatch();

  const closeEditModal = () => setEditModalOpen(false);
  const closeDeleteModal = () => setDeleteModalOpen(false);

  const deleteItem = () => {
    if (educationItem.id && userId) {
      dispatch(
        deleteUserEducation({ currentUserId: +userId, currentEducationId: +educationItem.id }),
      );
    }
    closeDeleteModal();
  };

  const { institution, degree, fieldOfStudy, startDate, endDate, description, logo } =
    educationItem;
  const sanitizedDescription = createElementFromString(description);
  return (
    <>
      <CardCustom className="educationSection-card">
        <AvatarCustom imageSrc={logo} fallbackText={institution[0]} size="sm" />
        <div className="educationSection-card-info">
          <div>
            <h3 className="educationExperience-card-title">{institution}</h3>
            {educationItem.status === 'Approved' && <IconCustom avatarIcon="success" />}
            {educationItem.status === 'Declined' && <IconCustom avatarIcon="delete" />}
            {educationItem.status === 'Pending' && <IconCustom avatarIcon="updatedProfile" />}
          </div>
          <h4 className="educationExperience-card-subtitle">
            {degree}, {fieldOfStudy}
          </h4>
          <p className="educationExperience-card-date">
            <time>{startDate}</time> - <time>{endDate || 'Present'}</time>
          </p>
          <p className="educationExperience-card-description">{sanitizedDescription}</p>
          {isEditable ? (
            <div className="educationExperience-card-actions">
              <ButtonCustom
                type="text"
                className="educationExperience-card-button"
                onClick={() => setDeleteModalOpen(true)}
              >
                <IconCustom
                  icon="MdDeleteForever"
                  className="educationExperience-card-icon"
                  color="gray-400"
                />
              </ButtonCustom>
              <ButtonCustom
                type="text"
                className="educationExperience-card-button"
                onClick={() => setEditModalOpen(true)}
              >
                <IconCustom
                  icon="MdEdit"
                  className="educationExperience-card-icon ml-1"
                  color="gray-400"
                />
              </ButtonCustom>
            </div>
          ) : null}
        </div>
      </CardCustom>
      {editModalOpen && (
        <EducationForm
          educationItem={educationItem}
          onCancel={closeEditModal}
          onOk={closeEditModal}
          open={editModalOpen}
          userId={userId}
        />
      )}
      <ModalCustom
        danger
        open={deleteModalOpen}
        footer="fullLength"
        onCancel={closeDeleteModal}
        onOk={deleteItem}
        cancelText="Cancel"
        okText="Yes, delete"
      >
        <div className="small-modal-content">
          <IconCustom component={IconDelete} className="educationExperience-delete-icon" />
          <h2 className="modal-heading">Are you sure?</h2>
          <h4 className="modal-subheading">
            Do you really want to delete this education? This process cannot be undone.
          </h4>
        </div>
      </ModalCustom>
    </>
  );
};

export default EducationCard;
