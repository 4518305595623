import * as React from 'react';
import TodaySection from './TodaySection';
import TimeBreakdownSection from './TimeBreakdownSection/TimeBreakdownSection';
import DoughnutChartsSection from './DoughnutChartsSection/DoughnutChartsSection';
import { useAppSelector } from '../../../redux/hooks';
import { loggedUser } from '../../../redux/reducers/user/userSelectors';
import './PersonalTimeTracker.scss';

const PersonalTimeTrackerTab: React.FC = () => {
  const loggedUserId = useAppSelector(loggedUser)?.id;

  return (
    <>
      <TodaySection userId={loggedUserId || ''} />
      <TimeBreakdownSection userId={loggedUserId} />
      <DoughnutChartsSection userId={loggedUserId || ''} />
    </>
  );
};

export default PersonalTimeTrackerTab;
