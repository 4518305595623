/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import IntegrationCard from './IntegrationCard';
import DividedSection from '../../../Components/DividedSection/DividedSection';
import { ReactComponent as TeamWorkLogo } from '../../../assets/svg/apps/teamwork-logo.svg';

import './IntegrationsAndNotificationsTab.scss';

const IntegrationsAndNotificationsTab: React.FC = () => {
  ///TODO: other integrations and notifications

  return (
    <>
      <DividedSection title="Integrations" subtitle="Connect all the tools you use">
        <div className="integrationsAndNotifications-integrations-container">
          <IntegrationCard
            title="Teamwork"
            description="INDUSTRIA Project Management System."
            icon={TeamWorkLogo}
          />
        </div>
      </DividedSection>
    </>
  );
};

export default IntegrationsAndNotificationsTab;
