import * as React from 'react';
import { Typography } from 'antd';
import AvatarCustom from '../../../../Components/AvatarCustom/AvatarCustom';
import { formatTimeString, formatPercentageString } from '../../../../Helpers/helperFunctions';
import './ProjectTimeCard.scss';

export type Props = {
  companyLogo: string;
  companyName: string;
  hoursDecimal: number;
  maximum: number;
  projectName: string;
};

const ProjectPercentageTimeCard: React.FC<Props> = ({
  companyLogo,
  companyName,
  hoursDecimal,
  maximum,
  projectName,
}) => {
  const { Title } = Typography;
  const style = { width: formatPercentageString(hoursDecimal, maximum) };

  return (
    <div className="projectTimeCard-root projectTimeCard-percentage">
      <div className="projectTimeCard-info">
        <AvatarCustom
          className="projectTimeCard-avatar"
          size="xs"
          imageSrc={companyLogo}
          fallbackText={companyName[0]}
        />
        <div className="projectTimeCard-text">
          <Title level={4} className="projectTimeCard-title">
            {projectName}
          </Title>
          <p className="projectTimeCard-subheading">{companyName}</p>
        </div>
      </div>
      <div className="projectTimeCard-percentage-container">
        <div className="projectTimeCard-percentage-data" style={style}>
          <div className="projectTimeCard-percentage-bar"> </div>
          <span className="projectTimeCard-percentage-hours">{formatTimeString(hoursDecimal)}</span>
        </div>
      </div>
    </div>
  );
};

export default ProjectPercentageTimeCard;
