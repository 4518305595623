import * as React from 'react';
import { Space, Typography } from 'antd';
import AvatarCustom from '../../../Components/AvatarCustom/AvatarCustom';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import { Team } from '../../../Helpers/generalTypes';

export type Props = {
  teams?: Array<Team> | null;
  onTeamClick: (team: Team) => void;
};

const { Text } = Typography;

const AllTeamsView: React.FC<Props> = ({ teams, onTeamClick }) => {
  return (
    <div className="allTeamsView-root">
      {teams?.map((teamItem, i) => {
        const userCount = teamItem.users.length;
        return (
          <div key={teamItem.id}>
            <Space className="allTeamsView-item" onClick={() => onTeamClick(teamItem)}>
              <AvatarCustom size="md" imageSrc={teamItem.logo} className="mr-3" />
              <div className="allTeamsView-item-data">
                <Text className="allTeamsView-item-name">{teamItem.name}</Text>
                <Text className="allTeamsView-item-details">
                  {userCount} User{userCount > 1 ? 's' : ''}
                </Text>
                <Text className="allTeamsView-item-details">{teamItem.description}</Text>
              </div>
            </Space>
            {i < teams.length ? <DividerCustom className="my-4" /> : null}
          </div>
        );
      })}
    </div>
  );
};

export default AllTeamsView;
