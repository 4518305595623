import * as React from 'react';
import { Typography } from 'antd';
import SendTokensWizard from '../../Components/SendTokensWizard/SendTokensWizard';
import ButtonCustom from '../../Components/UI/ButtonCustom/ButtonCustom';
import CardCustom from '../../Components/UI/CardCustom/CardCustom';
import { useAppSelector } from '../../redux/hooks';
import { selectedUserTokensBalance } from '../../redux/reducers/user/userSelectors';

const { Title } = Typography;

const CurrentBalanceSection: React.FC = () => {
  const [wizardStarted, setWizardStarted] = React.useState(false);
  const tokensBalance = useAppSelector(selectedUserTokensBalance);

  const getBalanceByType = (type: 'IND' | 'RIA') =>
    tokensBalance.find((token) => token.tokenType === type)?.balance || 0;
  const giftableTokens = getBalanceByType('IND');
  const redeemableTokens = getBalanceByType('RIA');

  return (
    <>
      <div className="currentBalanceSection-root">
        <div className="currentBalanceSection-header">
          <Title className="currentBalanceSection-title" level={2}>
            Current Balance
          </Title>
          <ButtonCustom
            type="primary"
            disabled={!giftableTokens}
            onClick={() => setWizardStarted(true)}
          >
            Send Tokens
          </ButtonCustom>
        </div>
        <div className="currentBalanceSection-cards">
          <CardCustom
            className="currentBalanceSection-card"
            meta={{ title: 'IND tokens', description: 'Giftable tokens' }}
          >
            {' '}
            <div className="currentBalanceSection-card-content">{giftableTokens}</div>
          </CardCustom>
          <CardCustom
            className="currentBalanceSection-card"
            meta={{ title: 'RIA tokens', description: 'Redeemable tokens' }}
          >
            {' '}
            <div className="currentBalanceSection-card-content">{redeemableTokens}</div>
          </CardCustom>
        </div>
      </div>
      {giftableTokens ? (
        <SendTokensWizard
          wizardStarted={wizardStarted}
          exitWizard={() => setWizardStarted(false)}
        />
      ) : null}
    </>
  );
};

export default CurrentBalanceSection;
