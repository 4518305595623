import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from '../../Components/UI/Container/Container';
import ProfileHeader from '../../Components/ProfileHeader/ProfileHeader';
import DividerCustom from '../../Components/UI/DividerCustom/DividerCustom';
import UserActiveProjectsSection from '../../Components/UserActiveProjectsSection/UserActiveProjectsSection';
import UserEducationSection from '../../Components/UserEducationSection/UserEducationSection';
import UserExperienceSection from '../../Components/UserExperienceSection/UserExperienceSection';
import UserInfoSection from '../../Components/UserInfoSection/UserInfoSection';
// import UserTimeInCompany from '../../Components/UserTimeInCompany/UserTimeInCompany';
import { appTitle } from '../../Helpers/constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getEducationList,
  getExperience,
  getSelectedUser,
  getUserProjects,
  getUserTokensBalance,
} from '../../redux/reducers/user/userApi';
import {
  loggedUser,
  selectedUser,
  selectedUserEducation,
  selectedUserExperience,
  selectedUserProjects,
  selectedUserSkills,
  selectedUserTechnologies,
  selectedUserTokensBalance,
} from '../../redux/reducers/user/userSelectors';
import useIsSameProfile from '../../hooks/useIsSameProfile';

const UserProfilePage: React.FC = () => {
  const { userId } = useParams();
  const currentUserProfile = useAppSelector(selectedUser);
  const loggedUserId = useAppSelector(loggedUser)?.id;
  const userExperience = useAppSelector(selectedUserExperience);
  const userEducation = useAppSelector(selectedUserEducation);
  const userSkills = useAppSelector(selectedUserSkills);
  const userTechnologies = useAppSelector(selectedUserTechnologies);
  const userProjects = useAppSelector(selectedUserProjects);
  const loggedUserTokenBalance = useAppSelector(selectedUserTokensBalance);

  const dispatch = useAppDispatch();

  const isSameProfile = useIsSameProfile();
  const userHobbies = currentUserProfile?.hobbies.filter((hobby) => hobby !== '') || [];
  const userActiveProjects = userProjects
    ? userProjects.data.filter((project) => project.status === 'ACTIVE')
    : [];

  React.useEffect(() => {
    if (userId) {
      dispatch(getSelectedUser(userId));
      dispatch(getExperience(userId));
      dispatch(getEducationList(userId));
      dispatch(getUserProjects({ currentUserId: userId, limited: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  React.useEffect(() => {
    if (loggedUserId) {
      dispatch(getUserTokensBalance(loggedUserId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserId]);

  return (
    <div className="userProfilePage-page">
      {currentUserProfile && (
        <>
          <Helmet>
            <title>
              {currentUserProfile.firstName} {currentUserProfile.lastName} | {appTitle}{' '}
            </title>
          </Helmet>
          <ProfileHeader
            differentUserProfile={!isSameProfile}
            canSendTokens={
              !!loggedUserTokenBalance.find((token) => token.tokenType === 'IND')?.balance
            }
          />
          <Container>
            <UserInfoSection
              user={currentUserProfile}
              skills={userSkills || []}
              hobbies={userHobbies}
              technologies={userTechnologies || []}
              birthday={currentUserProfile ? currentUserProfile.birthday : null}
              isDifferentProfile={!isSameProfile}
            />
            {/* <DividerCustom />
            <UserTimeInCompany />  //TODO: restore this section once BE is available */}
            {userActiveProjects?.length ? (
              <>
                <DividerCustom />
                <UserActiveProjectsSection projects={userActiveProjects} />
              </>
            ) : null}
            {(userEducation && userEducation.length) || isSameProfile ? (
              <>
                <DividerCustom />
                <UserEducationSection educationList={userEducation} />
              </>
            ) : null}
            {(userExperience && userExperience.length) || isSameProfile ? (
              <>
                <DividerCustom />
                <UserExperienceSection experienceList={userExperience} />
              </>
            ) : null}
          </Container>
        </>
      )}
    </div>
  ); //TODO: only show active projects section if the user has active projects; show real data in active projects
};

export default UserProfilePage;
