/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { Avatar } from 'antd';

import { AvatarIcons } from '../../Helpers/generalTypes';

import IconCustom from '../IconCustom/IconCustom';

import './avatarCustom.scss';

export type Props = {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  fallbackText?: string;
  iconEl?: React.ReactNode;
  iconType?: AvatarIcons;
  iconPosition?: 'top' | 'bottom';
  imageSrc?: string;
  shape?: 'circle' | 'square';
  onIconClick?: ([arg]?: any) => any;
  [key: string]: any;
};

const AvatarCustom: React.FC<Props> = ({
  className = '',
  fallbackText = null,
  imageSrc,
  iconEl,
  iconType,
  iconPosition = 'bottom',
  size,
  shape = 'circle',
  onIconClick,
  ...otherProps
}) => {
  const sizes: { [key: string]: number } = {
    xs: 26,
    sm: 34,
    md: 40,
    lg: 60,
    xl: 155,
  };

  const iconContainerClassName = `avatar-icon avatar-icon-${size} avatar-icon-${size}-${iconPosition}`;
  const iconElement = iconEl ? (
    <div className={iconContainerClassName + ' p-0'}>{iconEl} </div>
  ) : iconType ? (
    <IconCustom
      avatarIcon={iconType}
      className={iconContainerClassName}
      onClick={onIconClick}
    ></IconCustom>
  ) : null;

  return (
    <div className={`avatar-root avatar-root-${size} ${className}`}>
      <Avatar
        src={imageSrc || null}
        style={imageSrc ? {} : { backgroundColor: '#388cc4' }}
        shape={shape}
        size={sizes[size]}
        {...otherProps}
      >
        {fallbackText}
      </Avatar>
      {iconElement}
    </div>
  );
};

export default AvatarCustom;
