import * as React from 'react';
import './label.scss';

export type Props = {
  id: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
};

const Label: React.FC<Props> = ({ disabled, id, label, required }) => {
  return (
    <label className={`label-root ${disabled ? 'label-root-disabled' : ''}`} htmlFor={id}>
      {label}
      {required ? <sup className="label-required">&lowast;</sup> : ''}
    </label>
  );
};

export default Label;
