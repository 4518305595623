import * as React from 'react';
import { Table } from 'antd';
import { stringToCamelCase } from '../../Helpers/helperFunctions';
import './tableCustom.scss';

export type Props = {
  columns: Array<string>;
  dataSource: Array<{ [x: string]: string | number | React.ReactNode }>;
  className?: string;
};

const TableCustom: React.FC<Props> = ({ className = '', columns, dataSource }) => {
  const tableColumns = columns.map((columnName) => {
    return {
      title: columnName,
      dataIndex: stringToCamelCase(columnName),
      key: stringToCamelCase(columnName),
    };
  });

  return (
    <Table
      className={`tableCustom-root ${className}`}
      dataSource={dataSource}
      columns={tableColumns}
      pagination={false}
    />
  );
};

export default TableCustom;
