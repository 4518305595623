import { ConfigProvider, theme } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

interface Props {
  children?: React.ReactNode;
}

const propTypes = {
  children: PropTypes.element,
};

const defaultProps = {
  children: null,
};

const ThemeConfigProvider: React.FC<Props> = ({ children }) => {
  //--Colors
  const B100 = 'rgb(56, 140, 196)';
  const B50 = 'rgb(91, 157, 217)';
  const N10 = '#fff';
  const N50 = '(rgb(158, 160, 164)';
  const N40 = 'rgb(208, 213, 221)';
  const N100 = 'rgb(17, 39, 54)';
  const Transparent = 'transparent';

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
        hashed: false,
        token: {
          fontFamily: 'Titillium Web',
          colorPrimary: B100,
          colorPrimaryHover: B50,
          borderRadius: 8,
          colorTextBase: N100,
          colorTextHeading: N100,
          colorTextQuaternary: N10,
          colorText: N100,
          colorLink: B100,
          colorLinkActive: B100,
          colorLinkHover: N100,
          boxShadow: 'none',
        },
        components: {
          Button: {
            colorTextLightSolid: N100,
            colorBgContainer: Transparent,
            colorBorder: N40,
            controlHeight: 44,
            controlOutline: N50,
            colorLinkHover: N100,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

ThemeConfigProvider.propTypes = propTypes;
ThemeConfigProvider.defaultProps = defaultProps;

export default ThemeConfigProvider;
