import * as React from 'react';
import { uid } from 'uid';
import InputCustom from '../../../../Components/UI/InputCustom/InputCustom';
import ModalCustom from '../../../../Components/UI/ModalCustom/ModalCustom';
import { UISocialMedia } from '../../../../Helpers/generalTypes';
import { addHTTPSToUrl, isValidUrl } from '../../../../Helpers/helperFunctions';
import useInputField from '../../../../hooks/useInputField';
import { useAppSelector } from '../../../../redux/hooks';
import { isPending } from '../../../../redux/reducers/user/userSelectors';

type Props = {
  open?: boolean;
  socialMediaItem?: UISocialMedia;
  onCancel: () => void;
  onOk: (socialMedia: UISocialMedia) => void;
};

const nameMinLength = 3;
const nameMaxLength = 30;

const SocialMediaForm: React.FC<Props> = ({ open = false, socialMediaItem, onCancel, onOk }) => {
  const appIsLoading = useAppSelector(isPending);

  const name = useInputField(
    socialMediaItem?.name || '',
    false,
    (value: string) => value.length >= nameMinLength && value.length <= nameMaxLength,
  );
  const url = useInputField(
    addHTTPSToUrl(socialMediaItem?.url || ''),
    false,
    (value: string) => !!isValidUrl(value),
  );

  React.useEffect(() => {
    name.reset();
    url.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialMediaItem]);

  const submitHandler = () => {
    const socialMedia: UISocialMedia = {
      name: name.value,
      url: url.value,
      id: socialMediaItem?.id || uid(),
      profileUrl: `${url.value}/`,
    };
    onOk(socialMedia);
  };

  const canSubmitForm = React.useMemo(
    () => name.value && !name.invalid && url.value && !url.invalid,
    [name.value, name.invalid, url.value, url.invalid],
  );

  return (
    <ModalCustom
      form
      withHeader
      formClassName="modal-form-grid"
      title="Add Social Media"
      okText="Save and update"
      open={open}
      okButtonDisabled={!canSubmitForm}
      okButtonLoading={appIsLoading}
      onCancel={onCancel}
      onOk={submitHandler}
    >
      <InputCustom
        required
        id="social-media"
        label="Social media"
        placeholder="Enter name"
        errorMessage={`Please enter a name with ${nameMinLength}-${nameMaxLength} characters.`}
        invalid={name.invalid}
        value={name.value}
        onChange={name.onChange}
      />
      <InputCustom
        required
        id="url"
        label="URL"
        type="url"
        placeholder="Example: https://youtube.com"
        errorMessage="Please enter a valid URL."
        invalid={url.invalid}
        value={url.value}
        onChange={url.onChange}
      />
    </ModalCustom>
  );
};

export default SocialMediaForm;
