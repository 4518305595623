import * as React from 'react';
import BarGraph from './BarGraph';
import TimeSummary from '../../TimeSummary';
import TimeRangePicker from '../../../../Components/TimeRangePicker/TimeRangePicker';
import CardCustom from '../../../../Components/UI/CardCustom/CardCustom';
import type { CalendarModes } from '../../../../Helpers/generalTypes';
import { formatDate, getBillableAndUnbillableTime } from '../../../../Helpers/helperFunctions';
import useMemoizedFromToTimeRange from '../../../../hooks/useMemoizedFromToTimeRange';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { selectedUserTime } from '../../../../redux/reducers/user/userSelectors';
import { getUserCustomTime } from '../../../../redux/reducers/user/userApi';
import { currentChosenDate } from '../../../../redux/reducers/global/globalSelectors';

export type Props = {
  userId?: string;
};

const TimeBreakdownSection: React.FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const currentSelectedUserTime = useAppSelector(selectedUserTime);
  const currentDateSelected = useAppSelector(currentChosenDate);

  const [mode, setMode] = React.useState<CalendarModes>('week');
  const { from, to } = useMemoizedFromToTimeRange(currentDateSelected, mode);

  const time = currentSelectedUserTime?.customTime;
  const { billableTime, nonBillableTime } = getBillableAndUnbillableTime(time);

  React.useEffect(() => {
    if (userId) {
      dispatch(
        getUserCustomTime({
          userId,
          fromDate: formatDate(mode === 'week' ? from : from.startOf('week')),
          toDate: formatDate(mode === 'week' ? to : to.endOf('week')),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, mode, from, to]);

  return (
    //TODO for future iterations: add functionality to Export button
    <CardCustom className="timeBreakdown-root">
      <div className="timeBreakdown-header">
        <TimeRangePicker date={currentDateSelected} mode={mode} onModeChange={setMode} />
      </div>
      <BarGraph
        mode={mode}
        dataBillable={billableTime}
        dataNonbillable={nonBillableTime}
        fromDate={from}
      />
      <TimeSummary from={from} time={time} mode={mode} />
    </CardCustom>
  );
};

export default TimeBreakdownSection;
