/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Divider } from 'antd';

interface Props {
  className?: string;
  [key: string]: any;
}

const DividerCustom: React.FC<Props> = ({ className, ...otherProps }) => {
  return <Divider className={className} {...otherProps} />;
};

export default DividerCustom;
