/* eslint-disable @typescript-eslint/no-explicit-any */
import { uid } from 'uid';
import { SocialMediaActions } from './socialMediaActions';
import { presetSocialMedia } from './presetSocialMedia';
import { SocialMedia } from '../user/userInterface';
import { UISocialMedia } from '../../../Helpers/generalTypes';
import { removeHTTPSFromUrl } from '../../../Helpers/helperFunctions';

const initialSocialMedia = ['Facebook', 'LinkedIn', 'GitHub', 'Personal Website/Blog'];

const filterInitial = (socialMediaArray: Array<UISocialMedia>) =>
  socialMediaArray.filter((socialMediaItem) => initialSocialMedia.includes(socialMediaItem.name));

const getDomainFromProfileUrl = (profileUrl: string) =>
  removeHTTPSFromUrl(profileUrl)?.split('/')[0];

export const socialMediaReducer = (
  state: any,
  action: {
    type: string;
    custom?: boolean;
    id?: string;
    initial?: boolean;
    name?: string;
    profileUrl?: string;
    socialMedia?: Array<SocialMedia>;
    status?: string;
    url?: string;
  },
) => {
  if (action.type === SocialMediaActions.GET_SOCIAL_MEDIA) {
    if (!action.socialMedia)
      return action.custom
        ? []
        : action.initial
        ? filterInitial(presetSocialMedia)
        : presetSocialMedia;

    if (action.socialMedia) {
      const socialMediaFromAction = [...action.socialMedia];

      const presetSocialMediaWithProfile = presetSocialMedia.map((presetItem) => {
        socialMediaFromAction.forEach((socialMediaItem) => {
          if (socialMediaItem.name === presetItem.name) {
            presetItem.profileUrl = socialMediaItem.url;
          }
        });
        return presetItem;
      });

      const customSocialMedia = socialMediaFromAction
        .map((socialMediaItem) => {
          const socialMediaIsPreset = presetSocialMedia.find(
            (presetItem) => socialMediaItem.name === presetItem.name,
          );
          return socialMediaIsPreset
            ? null
            : {
                ...socialMediaItem,
                id: uid(),
                profileUrl: socialMediaItem.url,
                url: getDomainFromProfileUrl(socialMediaItem.url),
              };
        })
        .filter((item) => item !== null);
      return action.custom
        ? customSocialMedia
        : action.initial
        ? filterInitial(presetSocialMediaWithProfile)
        : presetSocialMediaWithProfile;
    }
  }
  if (action.type === SocialMediaActions.UPDATE_SOCIAL_MEDIA_URL) {
    const updatedState = state.map((socialMediaItem: UISocialMedia) =>
      socialMediaItem.id === action.id
        ? { ...socialMediaItem, profileUrl: action.profileUrl }
        : socialMediaItem,
    );
    return updatedState;
  }
  if (action.type === SocialMediaActions.ADD_CUSTOM_SOCIAL_MEDIA) {
    const newSocialMedia = {
      name: action.name,
      url: action.url,
      id: action.id,
      profileUrl: action.profileUrl,
    };
    const updatedState = [...state, newSocialMedia];
    return updatedState;
  }
  if (action.type === SocialMediaActions.EDIT_CUSTOM_SOCIAL_MEDIA) {
    const updatedState = state.map((socialMediaItem: UISocialMedia) =>
      socialMediaItem.id === action.id
        ? {
            ...socialMediaItem,
            name: action.name,
            id: action.id,
            url: action.url,
            profileUrl: action.profileUrl,
          }
        : socialMediaItem,
    );
    return updatedState;
  }
  if (action.type === SocialMediaActions.DELETE_SOCIAL_MEDIA) {
    const updatedState = state.filter((socialMedia: UISocialMedia) => socialMedia.id !== action.id);
    return updatedState;
  }
};
