import { useMemo } from 'react';
import { Dayjs } from 'dayjs';
import { CalendarModes } from '../Helpers/generalTypes';

const useMemoizedFromToTimeRange = (date: Dayjs, mode: CalendarModes) => {
  const from = useMemo(() => date.startOf(mode), [date, mode]);
  const to = useMemo(() => date.endOf(mode), [date, mode]);
  return { from, to };
};

export default useMemoizedFromToTimeRange;
