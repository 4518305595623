/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import ContactModal from './ContactModal/ContactModal';
import SendTokensWizard from '../SendTokensWizard/SendTokensWizard';
import ButtonCustom from '../UI/ButtonCustom/ButtonCustom';
import UploadPictureButton from '../UI/UploadPictureButton/UploadPictureButton';
import AvatarCustom from '../AvatarCustom/AvatarCustom';
import { useAppSelector } from '../../redux/hooks';
import { selectedUser } from '../../redux/reducers/user/userSelectors';
import temporaryPhoto from '../../assets/images/temporary-cover-photo.png';
import './ProfileHeader.scss';
import HeadingWithBackButton from '../HeadingWithBackButton/HeadingWithBackButton';
import DividerCustom from '../UI/DividerCustom/DividerCustom';

export type Props = {
  differentUserProfile: boolean;
  canSendTokens?: boolean;
};

const ProfileHeader: React.FC<Props> = ({ differentUserProfile, canSendTokens }) => {
  const [newProfilePicture, setNewProfilePicture] = React.useState<any>('');
  const [contactInfoOpen, setContactInfoOpen] = React.useState(false);
  const [sendTokensWizardOpen, setSendTokensWizardOpen] = React.useState(false);
  const navigate = useNavigate();

  const currentSelectedUser = useAppSelector(selectedUser);

  const handleProfilePictureChange = (e: any) => {
    setNewProfilePicture(e);
  };

  const subtitleString = `${currentSelectedUser?.jobRole || ''} ${
    currentSelectedUser?.city || currentSelectedUser?.country
      ? `based in ${currentSelectedUser?.city || ''}${
          currentSelectedUser?.city && currentSelectedUser?.country ? ', ' : ''
        }${currentSelectedUser.country || ''}`
      : ''
  }`;

  const secondaryButton = (
    <ButtonCustom
      onClick={() => (differentUserProfile ? setSendTokensWizardOpen(true) : navigate('/settings'))}
      className="mr-4"
      type="default"
      disabled={differentUserProfile && !canSendTokens}
    >
      {differentUserProfile ? 'Send tokens' : 'Edit profile'}
    </ButtonCustom>
  );

  return (
    <>
      <div className="profileHeader-root">
        <div className="profileHeader-cover-photo-container">
          <img src={currentSelectedUser?.profileCover || temporaryPhoto} alt="user cover" />
        </div>
        {differentUserProfile ? (
          <div className="flex flex-col align-center pt-4 pl-8">
            <HeadingWithBackButton showBackButton>Back</HeadingWithBackButton>
            <DividerCustom />
          </div>
        ) : null}
        <div className="profileHeader-user">
          <div className="profileHeader-user-info">
            <AvatarCustom
              imageSrc={currentSelectedUser?.profilePicture || newProfilePicture}
              fallbackText={`${currentSelectedUser?.firstName[0]}${currentSelectedUser?.lastName[0]}`}
              className="profileHeader-profile-picture"
              size="xl"
              iconEl={
                differentUserProfile ? null : (
                  <UploadPictureButton
                    className="change-profile-picture-icon"
                    onChange={handleProfilePictureChange}
                  />
                )
              }
            />
            <div>
              <h2 className="profileHeader-user-name">
                {currentSelectedUser?.firstName} {currentSelectedUser?.lastName}
              </h2>
              <p className="profileHeader-user-additional-info">{subtitleString}</p>
            </div>
          </div>
          <div className="profileHeader-user-actions">
            {secondaryButton}
            <ButtonCustom
              type="primary"
              iconLeft="MdOutlineAccountCircle"
              onClick={() => setContactInfoOpen(true)}
            >
              Contact info
            </ButtonCustom>
          </div>
        </div>
        <ContactModal
          name={currentSelectedUser?.firstName || ''}
          phone={currentSelectedUser?.phone || ''}
          email={currentSelectedUser?.email || ''}
          open={contactInfoOpen}
          closeModal={() => setContactInfoOpen(false)}
        />
      </div>
      {canSendTokens ? (
        <SendTokensWizard
          wizardStarted={sendTokensWizardOpen}
          exitWizard={() => setSendTokensWizardOpen(false)}
          user={currentSelectedUser || undefined}
        />
      ) : null}
    </>
  );
};

export default ProfileHeader;
