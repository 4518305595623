import * as React from 'react';

import AllTeamsView from './AllTeamsView';
import SingleTeamView from './SingleTeamView';
import HeadingWithBackButton from '../../../Components/HeadingWithBackButton/HeadingWithBackButton';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import { Team } from '../../../Helpers/generalTypes';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { team } from '../../../redux/reducers/team/teamSelectors';
import { getTeam } from '../../../redux/reducers/team/teamApi';
import DividedSection from '../../../Components/DividedSection/DividedSection';

const TemporaryPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showingTeam, setShowingTeam] = React.useState<Team | null>(null);

  const teamData = useAppSelector(team);

  React.useEffect(() => {
    dispatch(getTeam());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showingTeam ? (
        <>
          <HeadingWithBackButton
            showBackButton={!!showingTeam}
            onBackButtonClick={() => setShowingTeam(null)}
          >
            Back to All Teams
          </HeadingWithBackButton>
          <DividerCustom />
        </>
      ) : null}
      <DividedSection
        title={showingTeam ? showingTeam.name : 'All Teams'}
        subtitle="Manage your team and change roles/permissions."
      >
        {showingTeam ? (
          <SingleTeamView team={showingTeam} />
        ) : (
          <AllTeamsView teams={teamData} onTeamClick={setShowingTeam} />
        )}
      </DividedSection>
    </>
  );
};

export default TemporaryPage;
