import * as React from 'react';
import './Container.scss';

export type Props = {
  children: React.ReactNode;
  className?: string;
};

const Container: React.FC<Props> = ({ children, className = '' }) => (
  <div className={`container-root ${className}`}>{children}</div>
);

export default Container;
