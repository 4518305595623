import React from 'react';
import RequireAuth from './RequireAuth/RequireAuth';
import LayoutPage from '../Components/LayoutPage/LayoutPage';
import Login from '../Pages/Login/Login';
import PageNotFound from '../Pages/PageNotFound/PageNotFound';
import PeoplePage from '../Pages/PeoplePage/PeoplePage';
import SettingsPage from '../Pages/SettingsPage/SettingsPage';
import DetailsReview from '../Pages/TimeTrackerPage/DetailsReview/DetailsReview';
import TimeTrackerPage from '../Pages/TimeTrackerPage/TimeTrackerPage';
import TokensPage from '../Pages/TokensPage/TokensPage';
import UserManagementPage from '../Pages/UserManagementPage/UserManagementPage';
import UserProfilePage from '../Pages/UserProfilePage/UserProfilePage';
import SingleRequestReviewDetails from '../Pages/UserManagementPage/ApprovalRequestsTab/RequestsReview/SingleRequestReviewDetails';

// add new pages to Router
export const userRoutes = [
  {
    index: true,
    path: '/people',
    element: <PeoplePage />,
    linkTitle: 'People',
  },
  {
    index: true,
    path: '/profile/:userId',
    element: <UserProfilePage />,
    linkTitle: 'User Profile',
  },
  {
    index: true,
    path: '/settings',
    element: <SettingsPage />,
    linkTitle: 'Settings',
  },
  {
    index: true,
    path: '/user-management',
    element: <UserManagementPage />,
    linkTitle: 'User Management',
  },
  {
    index: true,
    path: '/user-management/:userId/:requestType',
    element: <SingleRequestReviewDetails />,
    linkTitle: 'User Management',
  },
  {
    index: true,
    path: '/time-tracker',
    element: <TimeTrackerPage />,
    linkTitle: 'Time Tracker',
  },
  {
    index: true,
    path: '/time-tracker/details/:userId',
    element: <DetailsReview />,
    linkTitle: 'Time Tracker',
  },
  {
    index: true,
    path: '/tokens',
    element: <TokensPage />,
    linkTitle: 'Tokens',
  },
  {
    index: true,
    path: '/',
    element: <TimeTrackerPage />,
    linkTitle: 'Time Tracker Page',
  },
  {
    path: '*',
    element: <PageNotFound></PageNotFound>,
  },
];

export const routes = [
  {
    path: '/',
    element: (
      <RequireAuth>
        <main>
          <LayoutPage />
        </main>
      </RequireAuth>
    ),
    children: userRoutes,
  },
  {
    path: '/login',
    element: (
      <RequireAuth not>
        <Login />
      </RequireAuth>
    ),
  },
];
