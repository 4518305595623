import * as React from 'react';

// imports from AntDesign
import { Content } from 'antd/es/layout/layout';
import './layoutContent.scss';

export type Props = {
  // optional props, define props here to utilize within the component
  children?: React.ReactNode;
};

const LayoutContent: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return <Content className="layoutContent-root">{children}</Content>;
};

export default LayoutContent;
