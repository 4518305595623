/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import InputCustom from '../../../../Components/UI/InputCustom/InputCustom';
import CheckboxCustom from '../../../../Components/CheckboxCustom/CheckboxCustom';
import TextArea from '../../../../Components/UI/TextArea/TextArea';
import ModalCustom from '../../../../Components/UI/ModalCustom/ModalCustom';

import { formatDate } from '../../../../Helpers/helperFunctions';
import useInputField from '../../../../hooks/useInputField';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { WorkExperience } from '../../../../redux/reducers/user/userInterface';
import {
  addUserWorkExperience,
  updateUserExperience,
} from '../../../../redux/reducers/user/userApi';
import { isPending } from '../../../../redux/reducers/user/userSelectors';

type Props = {
  experienceItem?: WorkExperience;
  open?: boolean;
  userId?: string;
  onCancel: () => void;
  onOk: ([arg]?: any) => void;
};

const ExperienceForm: React.FC<Props> = ({
  experienceItem,
  open = false,
  userId,
  onCancel,
  onOk,
}) => {
  const dispatch = useAppDispatch();
  const appIsLoading = useAppSelector(isPending);

  const defaultCurrentPosition = !experienceItem?.endDate;

  const role = useInputField(experienceItem?.role || '');
  const company = useInputField(experienceItem?.company || '');
  const startDate = useInputField(
    experienceItem ? new Date(experienceItem.startDate) : new Date(),
    true,
  );
  const endDate = useInputField(
    experienceItem && experienceItem.endDate ? new Date(experienceItem.endDate) : null,
    true,
  );
  const description = useInputField(experienceItem?.description || '', true);
  const [isCurrentPosition, setIsCurrentPosition] = React.useState(defaultCurrentPosition);

  const currentPositionChangeHandler = (e: CheckboxChangeEvent) => {
    endDate.setValueManually(null);
    setIsCurrentPosition(e.target.checked);
  };

  const submitHandler = () => {
    const experience: WorkExperience = {
      startDate: formatDate(startDate.value, 'YYYY-MM'),
      endDate: isCurrentPosition ? null : formatDate(endDate.value as Date, 'YYYY-MM'),
      role: role.value,
      company: company.value,
      description: description.value,
      logo: 'https://via.placeholder.com/150.png?text=TU', //TODO: use real logo
    };
    if (userId) {
      if (experienceItem && experienceItem.id) {
        dispatch(
          updateUserExperience({
            currentUserId: +userId,
            currentExperienceId: experienceItem.id,
            experience,
          }),
        );
      } else {
        dispatch(addUserWorkExperience({ currentUserId: +userId, experience }));
      }
    }
    onOk();
  };

  const cancelHandler = () => {
    role.reset();
    company.reset();
    startDate.reset();
    endDate.reset();
    description.reset();
    setIsCurrentPosition(defaultCurrentPosition);
  };

  const closeHandler = () => {
    cancelHandler();
    onCancel();
  };

  const canSubmitForm = React.useMemo(
    () => role.value && company.value && startDate.value && (isCurrentPosition || endDate.value),
    [role.value, company.value, startDate.value, endDate.value, isCurrentPosition],
  );

  const buttonsAreActive = React.useMemo(
    () => role.dirty || company.dirty || startDate.dirty || endDate.dirty || description.dirty,
    [role.dirty, company.dirty, startDate.dirty, endDate.dirty, description.dirty],
  );

  const canCancelForm = React.useMemo(
    () => role.dirty || company.dirty || startDate.dirty || endDate.dirty || description.dirty,
    [role.dirty, company.dirty, startDate.dirty, endDate.dirty, description.dirty],
  );

  return (
    <ModalCustom
      form
      withHeader
      formClassName="modal-form-grid gap-y-7"
      okText="Send for approval"
      cancelText="Cancel"
      title={`${experienceItem ? 'Edit' : 'Add'} Experience`}
      open={open}
      okButtonDisabled={!buttonsAreActive || !canSubmitForm}
      okButtonLoading={appIsLoading}
      cancelButtonDisabled={!buttonsAreActive || !canCancelForm}
      onCancelButton={cancelHandler}
      onCancel={closeHandler}
      onOk={submitHandler}
    >
      <InputCustom
        required
        id="role"
        label="Role"
        placeholder="Ex. Graphic Designer"
        errorMessage="Please enter the role you fulfilled."
        value={role.value}
        onChange={role.onChange}
      />
      <InputCustom
        required
        id="company-name"
        label="Company name"
        tooltip="lorem ipsum"
        placeholder="Ex. Microsoft"
        errorMessage="Please enter a company name."
        value={company.value}
        onChange={company.onChange}
      />
      <InputCustom
        required
        type="date"
        id="start-date"
        label="Start date"
        placeholder="Choose start date"
        errorMessage="Please enter a start date."
        value={startDate.value}
        onChange={startDate.onChange}
      />
      <InputCustom
        required={!isCurrentPosition}
        disabled={isCurrentPosition}
        type="date"
        id="end-date"
        label="End date"
        placeholder="Choose end date"
        errorMessage="Please enter an end date."
        minDate={startDate.value}
        value={endDate.value}
        onChange={endDate.onChange}
      />
      <CheckboxCustom
        className="col-span-full"
        label="I still work here"
        checked={isCurrentPosition}
        onChange={currentPositionChangeHandler}
      />
      <TextArea
        id="description"
        label="Description"
        maxLength={120}
        className="col-span-full"
        placeholder="Tell us what you did"
        value={description.value}
        onChange={description.onChange}
      />
    </ModalCustom>
  );
};

export default ExperienceForm;
