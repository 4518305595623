/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { uid } from 'uid';
import { useParams } from 'react-router-dom';

import AvatarCustom from '../../../../Components/AvatarCustom/AvatarCustom';
import CardCustom from '../../../../Components/UI/CardCustom/CardCustom';
import TagCustom from '../../../../Components/UI/TagCustom/TagCustom';
import IconCustom from '../../../../Components/IconCustom/IconCustom';
import { presetSocialMedia } from '../../../../redux/reducers/socialMedia/presetSocialMedia';
import ButtonCustom from '../../../../Components/UI/ButtonCustom/ButtonCustom';
import TextArea from '../../../../Components/UI/TextArea/TextArea';
import useInputField from '../../../../hooks/useInputField';

type Props = {
  requestTypeName: any;
  imageSrc?: string;
  fallbackText: string;
  firstName?: string;
  lastName?: string;
  approval: ([arg]?: any) => void;
  decline: ([arg]?: any) => void;
};

const RequesterCard: React.FC<Props> = ({
  requestTypeName,
  imageSrc,
  fallbackText,
  firstName,
  lastName,
  approval,
  decline,
}) => {
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const { requestType } = useParams();
  const reviewerNote = useInputField('', true);

  const approveDeclineHandler = (action: 'approve' | 'decline') => {
    if (action === 'approve') {
      approval(reviewerNote.value);
    } else {
      decline(reviewerNote.value);
    }

    reviewerNote.reset();
    setButtonsDisabled(true);
  };

  return (
    <>
      <div className="singleRequestReviewDetails-root-single-review_requester">
        <h4>Requester</h4>
        <div className="singleRequestReviewDetails-root-single-review_requester-info">
          <AvatarCustom
            size="md"
            // imageSrc={userDetails?.profilePicture}
            imageSrc={imageSrc}
            fallbackText={fallbackText}
          />
          <span className="singleRequestReviewDetails-root-single-review_requester-info-name">
            {firstName} {lastName}
          </span>
        </div>

        {/* Experience requester card content */}
        {requestType === 'experience' && (
          <CardCustom className="singleRequestReviewDetails-root-single-review_requester-education">
            <h4>Experience Summary</h4>
            <p className="text-sm">{requestTypeName.role}</p>

            <p className="text-sm text-gray-400">
              {requestTypeName.company} &nbsp;
              <span>
                {requestTypeName.startDate} -
                {requestTypeName.endDate !== null ? requestTypeName.endDate : 'To present'}
              </span>
            </p>

            <h4>Description</h4>
            <p className="text-sm">
              {requestTypeName.description ? requestTypeName.description : 'No description'}
            </p>
            <h4>Uploaded logo</h4>
            <AvatarCustom
              size="md"
              imageSrc={requestTypeName.logo}
              className="transaction-avatar"
              iconType="delete"
              iconPosition="top"
            />
          </CardCustom>
        )}

        {/* Education requester card content */}
        {requestType === 'education' && (
          <CardCustom className="singleRequestReviewDetails-root-single-review_requester-education">
            <h4>Education Summary</h4>
            <p className="text-sm">{requestTypeName.institution}</p>

            <p className="text-sm text-gray-400">
              {requestTypeName.fieldOfStudy} &nbsp;
              <span>
                {requestTypeName.startDate} - {requestTypeName.endDate}
              </span>
            </p>
            <p className="text-sm">{requestTypeName.degree}</p>

            <h4>Description</h4>
            <p className="text-sm">
              {requestTypeName.description ? requestTypeName.description : 'No description'}
            </p>
            <h4>Uploaded logo</h4>
            <AvatarCustom
              size="md"
              imageSrc={requestTypeName.logo}
              className="transaction-avatar"
              iconType="delete"
              iconPosition="top"
            />
          </CardCustom>
        )}
        {/* Skill requester card content */}
        {requestType === 'skill' && (
          <div className="singleRequestReviewDetails-root-single-review_requester-tags">
            <div className="singleRequestReviewDetails-root-single-review_requester-tags_items">
              {requestTypeName.currentLevel !== null ? (
                <>
                  <TagCustom key={uid()}>
                    {requestTypeName.name} ({requestTypeName.currentLevel})
                  </TagCustom>

                  <IconCustom
                    icon="MdEast"
                    className="singleRequestReviewDetails-root-single-review_requester-tags_icon"
                  />

                  <TagCustom key={uid()}>
                    {requestTypeName.name} ({requestTypeName.requestedLevel})
                  </TagCustom>
                </>
              ) : (
                <TagCustom key={uid()}>
                  {requestTypeName.name} ({requestTypeName.requestedLevel})
                </TagCustom>
              )}
            </div>
          </div>
        )}
        {/* Technology requester card content */}
        {requestType === 'technology' && (
          <div className="singleRequestReviewDetails-root-single-review_requester-tags">
            <div className="mb-3">
              {/* //TODO: add technology image
            <img src={requestTypeName.imageSrc} alt={requestTypeName.name} className="w-6 mr-3" /> */}
              {requestTypeName.currentExperience !== null ? (
                <>
                  <span>
                    {requestTypeName.name} ({requestTypeName.currentExperience})
                  </span>
                  <IconCustom
                    icon="MdEast"
                    className="singleRequestReviewDetails-root-single-review_requester-tags_icon"
                  />
                  <span>
                    {requestTypeName.name} ({requestTypeName.requestedExperience})
                  </span>
                </>
              ) : (
                <span>
                  {requestTypeName.name} ({requestTypeName.requestedExperience})
                </span>
              )}
            </div>
          </div>
        )}

        {/* Social Media requester card content */}
        {requestType === 'social media' && (
          <div className="singleRequestReviewDetails-root-single-review_requester-tags">
            <div className="mb-3">
              {presetSocialMedia.map((preset) =>
                requestTypeName?.name === preset.name ? (
                  <img
                    key={preset.name}
                    src={preset.imageSrc}
                    alt={preset.name}
                    className="w-6 mr-3"
                  />
                ) : null,
              )}

              {requestTypeName?.currentUrl !== null ? (
                <>
                  <span>{requestTypeName?.currentUrl}</span>

                  <IconCustom
                    icon="MdEast"
                    className="singleRequestReviewDetails-root-single-review_requester-tags_icon"
                  />

                  <span>{requestTypeName.requestedUrl}</span>
                </>
              ) : (
                <span>{requestTypeName.requestedUrl}</span>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="singleRequestReviewDetails-root-single-review_reviewer">
        <TextArea
          className="col-span-full mt-3"
          id={uid()}
          label="Note"
          maxLength={213}
          placeholder="Say why you approved or declined their request"
          value={reviewerNote.value}
          onChange={reviewerNote.onChange}
        />
        <div className="singleRequestReviewDetails-root-single-review_actions">
          <ButtonCustom
            type="default"
            id={uid()}
            onClick={() => approveDeclineHandler('decline')}
            disabled={buttonsDisabled}
          >
            Decline
          </ButtonCustom>
          <ButtonCustom
            type="primary"
            id={uid()}
            onClick={() => approveDeclineHandler('approve')}
            disabled={buttonsDisabled}
          >
            Approve
          </ButtonCustom>
        </div>
      </div>
    </>
  );
};

export default RequesterCard;
