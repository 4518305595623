/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

const useInputField = (
  initialValue: any = '',
  setEventDirectly = false,
  validityCondition?: (([arg]?: any) => boolean) | null,
) => {
  const [value, setValue] = React.useState(initialValue);
  const [invalid, setInvalid] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);

  const validate = (valueToValidate: any) => {
    if (validityCondition) {
      const isValid = validityCondition(valueToValidate);
      if (touched) {
        setInvalid(!isValid);
      }
    }
  };

  const onChange = (e: any) => {
    const eventValue = setEventDirectly ? e : e.target.value;
    if (!dirty) {
      setDirty(true);
    }
    setValue(eventValue);
    setTouched(true);
    if (validityCondition) {
      validate(eventValue);
    }
  };

  const reset = () => {
    setDirty(false);
    setValue(initialValue);
  };

  const setValueManually = (newValue: any) => setValue(newValue);

  React.useEffect(() => {
    if (validityCondition) {
      validate(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, touched]);

  return { value, invalid, onChange, reset, setValueManually, dirty };
};

export default useInputField;
