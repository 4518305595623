/* eslint-disable @typescript-eslint/no-explicit-any */

import { Skill, SkillLevels } from '../../../Helpers/generalTypes';

export const skillsReducer = (
  state: any,
  action: {
    type: string;
    skill?: Skill;
    skillName?: string;
    skillLevel?: SkillLevels;
    status?: string;
  },
) => {
  if (action.type === 'ADD_SKILL') {
    const skillAlreadyAdded = state.find(
      (skill: Skill) => skill.skill.toLowerCase() === action.skill?.skill.toLowerCase(),
    );
    return skillAlreadyAdded ? state : [...state, action.skill];
  }
  if (action.type === 'REMOVE_SKILL') {
    return [...state].filter((skill) => skill.skill !== action.skillName);
  }
};
