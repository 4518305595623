/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Button } from 'antd';

import './buttonCustom.scss';
import type { Icon } from '../../../Helpers/generalTypes';
import IconCustom from '../../IconCustom/IconCustom';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  iconLeft?: Icon;
  iconRight?: Icon;
  loading?: boolean;
  type?: 'primary' | 'default' | 'text' | 'link';
  htmlType?: 'button' | 'submit' | 'reset';
  [key: string]: any;
};

const ButtonCustom: React.FC<Props> = ({
  className = '',
  children,
  danger,
  disabled,
  iconLeft,
  iconRight,
  htmlType,
  loading,
  type = 'default',
  ...otherProps
}) => {
  const icon = iconLeft || iconRight;
  const iconElement = icon && (
    <IconCustom
      icon={icon}
      color={type === 'primary' ? 'white' : 'gray-400'}
      bgColor="transparent"
      className="text-xl flex"
    />
  );
  return (
    <Button
      className={`button-root ${type} ${danger ? 'danger' : ''} ${className}`}
      danger={danger}
      type={type}
      disabled={disabled}
      htmlType={htmlType}
      loading={loading}
      {...otherProps}
    >
      {iconLeft && iconElement}
      {children}
      {iconRight && iconElement}
    </Button>
  );
};

export default ButtonCustom;
