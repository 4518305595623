import * as React from 'react';

import { Team } from '../../../Helpers/generalTypes';
import IconCustom from '../../../Components/IconCustom/IconCustom';
import TableCustom from '../../../Components/TableCustom/TableCustom';
import AvatarCustom from '../../../Components/AvatarCustom/AvatarCustom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { loggedUser, usersByTeamId } from '../../../redux/reducers/user/userSelectors';
import { getUsersByTeamId } from '../../../redux/reducers/user/userApi';

export type Props = {
  team: Team;
};
const SingleTeamView: React.FC<Props> = ({ team }) => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(usersByTeamId);
  const currentLoggedUserEmail = useAppSelector(loggedUser)?.email;
  const teamId = team.id;

  const data = users?.data.map((teamMember) => {
    return {
      key: teamMember.id,
      name: (
        <div className="item-with-avatar-and-data">
          <AvatarCustom size="sm" imageSrc={teamMember.profilePicture} />
          <div className="item-data">
            <span className="item-data-main">
              {teamMember.firstName} {teamMember.lastName}
            </span>
            <span className="item-data-sub">{teamMember.jobRole}</span>
          </div>
        </div>
      ),
      email: <span className="singleTeamView-data">{teamMember.email}</span>,
      permissions: <span className="singleTeamView-data">{teamMember.systemRole}</span>,
      edit: (
        <div className="singleTeamView-last-column">
          {teamMember.managerEmail === currentLoggedUserEmail ? (
            <IconCustom icon="MdOutlineBorderColor" className="text-lg" color="gray-400" />
          ) : null}
        </div>
      ),
    };
  });
  React.useEffect(() => {
    dispatch(getUsersByTeamId({ teamId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return data && data.length ? (
    <TableCustom
      className="singleTeamView-table"
      columns={['Name', 'Email', 'Permissions', 'edit']}
      dataSource={data}
    />
  ) : null;
};

export default SingleTeamView;
