/* eslint-disable import/order */
import * as React from 'react';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import HeaderMenu from '../HeaderMenu/HeaderMenu';
import LayoutContent from '../LayoutContent/LayoutContent';
import LayoutHeader from '../LayoutHeader/LayoutHeader';
import LayoutSidebar from '../LayoutSidebar/LayoutSidebar';

import './layoutPage.scss';
import ButtonCustom from '../UI/ButtonCustom/ButtonCustom';
import FeedbackModal from '../FeedbackModal/FeedbackModal';

export type Props = {
  children?: React.ReactNode;
};

const LayoutPage: React.FC = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [feedbackModalIsOpen, setFeedbackModalIsOpen] = useState(false);

  const closeFeedbackModalHandler = () => {
    setFeedbackModalIsOpen(false);
  };
  const openFeedbackModalHandler = () => {
    setFeedbackModalIsOpen(true);
  };

  const getTitle = () => {
    if (location.pathname.startsWith('/profile')) {
      return 'People';
    }
    if (location.pathname.startsWith('/time-tracker')) {
      return 'Time Tracker';
    }
    if (location.pathname.startsWith('/user-management')) {
      return 'User Management';
    }
    switch (location.pathname) {
      //TODO: add paths as pages are added
      case '/':
        return 'Time Tracker';
      case '/people':
        return 'People';
      case '/settings':
        return 'Account Settings';
      case '/tokens':
        return 'Tokens';
      default:
        return '404 Page';
    }
  };
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className="layout-root">
      <LayoutSidebar collapsed={collapsed} />
      <Layout className="layout-root-site">
        <LayoutHeader collapsed={collapsed} setCollapsed={toggleCollapsed}>
          <h1 className="layout-root-title">{getTitle()}</h1>
          <HeaderMenu />
        </LayoutHeader>
        <LayoutContent>
          <Outlet />
          <ButtonCustom
            className="fixed bottom-12 left-10"
            onClick={() => openFeedbackModalHandler()}
            type="primary"
          >
            Send Feedback
          </ButtonCustom>
          <FeedbackModal
            open={feedbackModalIsOpen}
            onOk={closeFeedbackModalHandler}
            onCancel={closeFeedbackModalHandler}
          />
        </LayoutContent>
      </Layout>
    </Layout>
  );
};

export default LayoutPage;
