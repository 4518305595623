import React from 'react';
import { Helmet } from 'react-helmet';
import EditPersonalProfileTab from './EditPersonalProfileTab/EditPersonalProfileTab';
import EditExperienceAndEducationTab from './EditExperienceAndEducationTab/EditExperienceAndEducationTab';
import EditTechnologiesTab from './EditTechnologiesTab/EditTechnologiesTab';
import EditSocialMediaTab from './EditSocialMediaTab/EditSocialMediaTab';
import IntegrationsAndNotificationsTab from './IntegrationsAndNotificationsTab/IntegrationsAndNotificationsTab';
import Container from '../../Components/UI/Container/Container';
import Tabulation from '../../Components/Tabulation/Tabulation';
import { appTitle } from '../../Helpers/constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getSelectedUser } from '../../redux/reducers/user/userApi';
import { loggedUser } from '../../redux/reducers/user/userSelectors';
import useSuccessMessage from '../../hooks/useSuccessMessage';
import './SettingsPage.scss';

const SettingsPage: React.FC = () => {
  const currentLoggedUser = useAppSelector(loggedUser);
  const dispatch = useAppDispatch();
  useSuccessMessage();

  React.useEffect(() => {
    currentLoggedUser && dispatch(getSelectedUser(currentLoggedUser.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLoggedUser]);

  const tabItems = [
    {
      key: 'personalProfile',
      label: 'Personal Profile',
      children: <EditPersonalProfileTab />,
    },
    {
      key: 'experienceAndEducation',
      label: 'Experience and Education',
      children: <EditExperienceAndEducationTab />,
    },
    {
      key: 'technologies',
      label: 'Technologies',
      children: <EditTechnologiesTab />,
    },
    {
      key: 'socialMedia',
      label: 'Social Media',
      children: <EditSocialMediaTab />,
    },
    {
      key: 'integrationsAndNotifications',
      label: 'Integrations & Notifications',
      children: <IntegrationsAndNotificationsTab />,
    },
  ];
  return (
    <>
      <Helmet>
        <title>Account Settings | {appTitle} </title>
      </Helmet>
      <Container>
        <Tabulation items={tabItems} className="settings-tabs"></Tabulation>
      </Container>
    </>
  );
};

export default SettingsPage;
