/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { uid } from 'uid';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import TagCustom from '../TagCustom/TagCustom';
import IconCustom from '../../IconCustom/IconCustom';
import InputCustom from '../InputCustom/InputCustom';
import { ReactComponent as IconLabel } from '../../../assets/svg/icon-label.svg';
import './TagsSelect.scss';

export type Props = {
  id: string;
  label: string;
  values: Array<string>;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  onChange?: ([arg]?: any) => void;
};

const TagsSelect: React.FC<Props> = ({
  className = '',
  disabled,
  id,
  label,
  placeholder,
  required,
  values,
  onChange,
}) => {
  const inputProps = {
    id,
    disabled,
    label,
    placeholder,
  };

  const [tagsCollection, setTagsCollection] = React.useState<Set<string>>(new Set(values));
  const [currentValue, setCurrentValue] = React.useState('');

  React.useEffect(() => {
    onChange && onChange(Array.from(tagsCollection));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsCollection, tagsCollection.size]);

  const updateTags = (value: string, operation: 'add' | 'delete') => {
    const newTagsCollection = new Set(tagsCollection);
    newTagsCollection[operation](value);
    setTagsCollection(newTagsCollection);
  };

  const submitHandler = () => {
    if (currentValue) {
      updateTags(currentValue.trim(), 'add');
      setCurrentValue('');
    }
  };

  const removeTag = (tag: string) => updateTags(tag, 'delete');

  const tags = Array.from(tagsCollection).map((tag) => (
    <TagCustom closable key={uid()} onClose={() => removeTag(tag)}>
      {tag}
    </TagCustom>
  ));

  return (
    <div className={`${className} TagsSelect-root`}>
      <div className="relative">
        <InputCustom
          {...inputProps}
          type="text"
          className="tagsSelect"
          defaultValue=""
          value={currentValue}
          onChange={(e) => setCurrentValue(e.target.value)}
          onPressEnter={submitHandler}
          prefix={<IconCustom component={IconLabel} className="mr-1 text-lg"></IconCustom>}
          requiredGroup={required}
        />
        <ButtonCustom type="text" className="tagsSelect-button" onClick={submitHandler}>
          Add
        </ButtonCustom>
      </div>

      <div>{tags}</div>
    </div>
  );
};

export default TagsSelect;
