import * as React from 'react';
import { Tag } from 'antd';
import './tagCustom.scss';

export type Props = {
  children: React.ReactNode;
  className?: string;
  closable?: boolean;
  onClose?: () => void;
  onClick?: () => void;
};

const TagCustom: React.FC<Props> = ({ children, className = '', closable, onClose, onClick }) => {
  return (
    <Tag
      className={`tagCustom-root ${closable ? 'tagCustom-closable' : ''} ${className}`}
      closable={closable}
      onClose={onClose}
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};

export default TagCustom;
