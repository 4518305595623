import * as React from 'react';
import { Helmet } from 'react-helmet';
import CurrentBalanceSection from './CurrentBalanceSection';
import TransactionsHistorySection from './TransactionsHistorySection';
import Container from '../../Components/UI/Container/Container';
import { appTitle } from '../../Helpers/constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { loggedUser } from '../../redux/reducers/user/userSelectors';
import { getUserTokensBalance } from '../../redux/reducers/user/userApi';
import './TokensPage.scss';

const TokensPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(loggedUser);

  React.useEffect(() => {
    if (user?.id) {
      dispatch(getUserTokensBalance(user.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Tokens | {appTitle}</title>
      </Helmet>
      <Container className="tokensPage-root">
        <CurrentBalanceSection /> <TransactionsHistorySection user={user} />
      </Container>
    </>
  );
};

export default TokensPage;
