import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Typography } from 'antd';
import PersonalTimeTrackerTab from './PersonalTimeTrackerTab/PersonalTimeTrackerTab';
import TeamTimeTrackerTab from './TeamTimeTrackerTab/TeamTimeTrackerTab';
import Container from '../../Components/UI/Container/Container';
import Tabulation from '../../Components/Tabulation/Tabulation';
import { appTitle } from '../../Helpers/constants';
import { useAppSelector } from '../../redux/hooks';
import { loggedUser } from '../../redux/reducers/user/userSelectors';
import './TimeTrackerPage.scss';

const { Text, Link } = Typography;

const TimeTrackerPage: React.FC = () => {
  const userHasApiKey = useAppSelector(loggedUser)?.hasTeamworkApiKey;
  const tabItems = [
    {
      key: 'personal',
      label: 'Personal',
      children: <PersonalTimeTrackerTab />,
    },
    {
      key: 'team',
      label: 'Team',
      children: <TeamTimeTrackerTab />,
    },
  ];
  return (
    <>
      <Helmet>
        <title>Time Tracker | {appTitle}</title>
      </Helmet>
      <Container>
        {userHasApiKey ? (
          <Tabulation items={tabItems}></Tabulation>
        ) : (
          <div className="timeTracker-message">
            <Text className="timeTracker-message-text">
              It looks like you have not yet added a Teamwork API key. Visit your Teamwork Account
              Settings to retrieve it and add it to your Integrations in order to see your time
              logs. If you need assistance, please contact your team lead.
            </Text>
            <div className="timeTracker-message-actions">
              <Link
                target="_blank"
                href="https://industriatech.teamwork.com/app/time/user"
                className="link-as-button link-as-button-primary timeTracker-message-button"
              >
                Open Teamwork
              </Link>
              <RouterLink to="/settings">Go to Settings</RouterLink>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default TimeTrackerPage;
