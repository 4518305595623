import * as React from 'react';
import { Card } from 'antd';
import './cardCustom.scss';

export type Props = {
  children: React.ReactNode;
  className?: string;
  cover?: React.ReactNode;
  meta?: {
    avatar?: React.ReactNode;
    title?: string;
    description?: string;
  };
};

const { Meta } = Card;

const CardCustom: React.FC<Props> = ({ children, className = '', cover, meta }) => {
  return (
    <Card className={`${className} cardCustom-root`} cover={cover}>
      {meta && <Meta {...meta}></Meta>}
      {children}
    </Card>
  );
};

export default CardCustom;
