import { uid } from 'uid';
import FigmaLogo from '../../../assets/svg/apps/figma-logo.svg';
import SketchLogo from '../../../assets/svg/apps/sketch-logo.svg';
import InvisionLogo from '../../../assets/svg/apps/invision-logo.svg';
import AdobeProductsLogo from '../../../assets/svg/apps/adobe-products-logo.svg';
import AdobeIllustratorLogo from '../../../assets/svg/apps/illustrator-logo.svg';
import AdobeInDesignLogo from '../../../assets/svg/apps/indesign-logo.svg';

export const presetTechnologies = [
  {
    id: uid(),
    name: 'Figma',
    url: 'https://figma.com',
    imageSrc: FigmaLogo,
    experience: '',
  },
  {
    id: uid(),
    name: 'Sketch',
    url: 'https://sketch.com',
    imageSrc: SketchLogo,
    experience: '',
  },
  {
    id: uid(),
    name: 'Invision',
    url: 'https://invision.com',
    imageSrc: InvisionLogo,
    experience: '',
  },
  {
    id: uid(),
    name: 'Adobe Products',
    url: 'https://adobe.com',
    imageSrc: AdobeProductsLogo,
    experience: '',
  },
  {
    id: uid(),
    name: 'Adobe Illustrator',
    url: 'https://adobe.com',
    imageSrc: AdobeIllustratorLogo,
    experience: '',
  },
  {
    id: uid(),
    name: 'Adobe InDesign',
    url: 'https://adobe.com',
    imageSrc: AdobeInDesignLogo,
    experience: '',
  },
];
/// TODO for future iterations: add separate list of technologies for different
/// positions and only show the appropriate technologies to each user
