import * as React from 'react';

import DividedSection from '../../../Components/DividedSection/DividedSection';
import CardCustom from '../../../Components/UI/CardCustom/CardCustom';
import FormCustom from '../../../Components/FormCustom/FormCustom';
import InputCustom from '../../../Components/UI/InputCustom/InputCustom';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import ButtonCustom from '../../../Components/UI/ButtonCustom/ButtonCustom';
import useInputField from '../../../hooks/useInputField';
import { User } from '../../../redux/reducers/user/userInterface';
import { updateUserProfile } from '../../../redux/reducers/user/userApi';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { isPending } from '../../../redux/reducers/user/userSelectors';

export type Props = {
  user: User | null;
};

const BasicInformationSection: React.FC<Props> = ({ user }) => {
  //TODO: update profile picture
  const dispatch = useAppDispatch();
  const appIsLoading = useAppSelector(isPending);

  const firstName = useInputField(user?.firstName || '');
  const lastName = useInputField(user?.lastName || '');
  const nickname = useInputField(user?.nickname || '');
  const email = useInputField(user?.email || '');

  const cancelHandler = () => {
    firstName.reset();
    lastName.reset();
    nickname.reset();
    email.reset();
  };

  const buttonsAreActive = React.useMemo(
    () => firstName.dirty || lastName.dirty || email.dirty || nickname.dirty,
    [firstName.dirty, lastName.dirty, email.dirty, nickname.dirty],
  );

  const submitHandler = () => {
    if (user) {
      const updatedInfoSelectedUser = {
        ...user,
        currentUserId: user.id,
        firstName: firstName.value,
        lastName: lastName.value,
        nickname: nickname.value,
        email: email.value,
        hobbies: user.hobbies.filter((hobby) => hobby !== ''),
      };
      dispatch(updateUserProfile(updatedInfoSelectedUser));
    }
  };

  const canSubmitForm = React.useMemo(
    () => firstName.value && lastName.value && email.value,
    [firstName.value, lastName.value, email.value],
  );

  return (
    <DividedSection
      className="editProfile-root"
      title="Basic details"
      subtitle="Just add a nickname!"
    >
      <CardCustom>
        <FormCustom onSubmit={submitHandler}>
          <div className="editProfile-row">
            <InputCustom
              className="editProfile-row-item"
              type="text"
              label="First name"
              required
              disabled
              id="firstName"
              value={firstName.value}
              onChange={firstName.onChange}
            />
            <InputCustom
              className="editProfile-row-item"
              type="text"
              label="Last name"
              required
              disabled
              id="lastName"
              value={lastName.value}
              onChange={lastName.onChange}
            />
            <InputCustom
              className="editProfile-row-item"
              type="text"
              label="Nickname"
              id="nickname"
              placeholder="Enter your nickname"
              value={nickname.value}
              onChange={nickname.onChange}
            />
          </div>
          <InputCustom
            className="editProfile-email"
            type="email"
            label="Email address"
            id="email"
            required
            disabled
            value={email.value}
            onChange={email.onChange}
          />
          <DividerCustom />
          <div className="form-actions">
            <ButtonCustom type="default" onClick={cancelHandler} disabled={!buttonsAreActive}>
              Cancel
            </ButtonCustom>
            <ButtonCustom
              type="primary"
              htmlType="submit"
              disabled={!buttonsAreActive || !canSubmitForm}
              loading={appIsLoading}
            >
              Save changes
            </ButtonCustom>
          </div>
        </FormCustom>
      </CardCustom>
    </DividedSection>
  );
};

export default BasicInformationSection;
