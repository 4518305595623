import * as React from 'react';
import ProfessionalSkillsSection from './ProfessionalSkillsSection';
import EducationSection from './EducationSection';
import ExperienceSection from './ExperienceSection';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  selectedUser,
  selectedUserEducation,
  selectedUserExperience,
} from '../../../redux/reducers/user/userSelectors';
import './EditExperienceAndEducation.scss';
import { getEducationList, getExperience } from '../../../redux/reducers/user/userApi';

const EditExperienceAndEducationTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentSelectedUser = useAppSelector(selectedUser);
  const currentSelectedUserEducation = useAppSelector(selectedUserEducation);
  const currentSelectedUserExperience = useAppSelector(selectedUserExperience);

  const currentSelectedUserId = currentSelectedUser?.id;

  React.useEffect(() => {
    if (currentSelectedUserId) {
      dispatch(getEducationList(currentSelectedUserId));
      dispatch(getExperience(currentSelectedUserId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedUserId]);

  return (
    <>
      <ProfessionalSkillsSection
        user={currentSelectedUserId}
        skills={currentSelectedUser?.skills}
      />
      <DividerCustom />
      <EducationSection userId={currentSelectedUserId} education={currentSelectedUserEducation} />
      <DividerCustom />
      <ExperienceSection
        userId={currentSelectedUserId}
        experience={currentSelectedUserExperience}
      />
    </>
  );
};

export default EditExperienceAndEducationTab;
