import * as React from 'react';
import AvatarCustom from '../../Components/AvatarCustom/AvatarCustom';
import IconCustom from '../../Components/IconCustom/IconCustom';
import { TokensTransactionItem } from '../../redux/reducers/user/userInterface';
import { useAppSelector } from '../../redux/hooks';
import { loggedUser } from '../../redux/reducers/user/userSelectors';
import { ReactComponent as iconTransaction } from '../../assets/svg/icon-transaction.svg';

export type Props = {
  transaction: TokensTransactionItem;
};

const TransactionItem: React.FC<Props> = ({ transaction }) => {
  const user = useAppSelector(loggedUser);

  const receiverIsAdmin = transaction.receiver.id === '-1';
  const receiverIsUser = transaction.receiver.id === user?.id;

  const getInitials = (firstName: string, lastName?: string) => {
    if (firstName) {
      return lastName ? `${firstName[0]}${lastName[0]}` : firstName[0];
    }
  };

  return (
    <div className="transaction-container">
      <div className="transaction-initiator">
        <AvatarCustom
          size="md"
          imageSrc={transaction.initiator.profilePicture}
          fallbackText={getInitials(
            transaction.initiator.firstName,
            transaction.initiator.lastName,
          )}
          className="transaction-avatar"
          iconType={
            receiverIsAdmin ? 'redeemedTokens' : receiverIsUser ? 'receivedTokens' : 'sentTokens'
          }
        />
        <div>
          <p className="transaction-initiator-name">
            {transaction.initiator.firstName} {transaction.initiator.lastName}{' '}
            {transaction.initiator.id === user?.id ? '(You)' : ''}
          </p>
          <p>
            {receiverIsAdmin ? 'Redeemed' : 'Sent'}{' '}
            <span className="transaction-amount">{transaction.amount}</span>{' '}
            {transaction.amount === 1 ? 'token' : 'tokens'}
          </p>
        </div>
      </div>
      <div>
        <IconCustom component={iconTransaction} />
      </div>
      <div className="transaction-receiver">
        <AvatarCustom
          size="md"
          imageSrc={transaction.receiver.profilePicture}
          fallbackText={getInitials(transaction.receiver.firstName, transaction.receiver.lastName)}
          className="transaction-avatar"
        />
        <div>
          <p className="transaction-receiver-name">
            {transaction.receiver.firstName} {transaction.receiver.lastName}{' '}
            {receiverIsUser ? '(You)' : ''}
          </p>
          {receiverIsUser ? (
            ''
          ) : (
            <p className="transaction-receiver-job-role">{transaction.receiver.jobRole}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionItem;
