import React from 'react';
import { RcFile } from 'antd/es/upload';
import { BaseOptionType } from 'antd/es/select';
import dayjs, { Dayjs } from 'dayjs';
import sanitizeHtml from 'sanitize-html';
import { dateFormat, daysInAWeek, workingHoursInAWeek } from './constants';
import { BaseTimeEntry, TimeEntry } from '../redux/sharedInterface';

/// DATES AND TIME
export const formatDate = (date: Date | string | Dayjs, format: string = dateFormat) => {
  const formattedDate = dayjs(date).format(format);
  return formattedDate;
};

export const formatTimeString = (time: number) => {
  const hours = Math.floor(time);
  const hoursString = hours ? `${hours}h` : '';
  const minutes = Math.round((time - hours) * 60);
  const minutesString = minutes ? `${minutes}m` : '';
  return `${hoursString} ${minutesString}`;
};

export const formatWeekString = (startOfWeek: Dayjs, endOfWeek: Dayjs) => {
  const isDifferentMonth = startOfWeek.month() !== endOfWeek.month();
  const monday = startOfWeek.format(isDifferentMonth ? 'D MMMM' : 'D');
  const sunday = endOfWeek.format('D MMMM');
  return `${monday} - ${sunday}`;
};

export const getAllDatesInAWeek = (referenceDate: Dayjs) => {
  const dates = [];
  let dayOfWeek = 1;
  while (dayOfWeek < 8) {
    const day = referenceDate.day(dayOfWeek);
    dates.push(day);
    dayOfWeek++;
  }
  return dates;
};

export const getAllWeeksInAMonth = (referenceDate: Dayjs) => {
  const dates: Array<{ start: Dayjs; end: Dayjs }> = [];
  let dayOfMonth = referenceDate.startOf('month').startOf('week');
  do {
    const lastDay = dayOfMonth.endOf('week');
    dates.push({ start: dayOfMonth, end: lastDay });
    dayOfMonth = lastDay.add(1, 'day');
  } while (dayOfMonth.month() === referenceDate.month());
  return dates;
};

export const getWorkHoursInAMonth = (from: Dayjs) =>
  Math.ceil(from.endOf('month').endOf('week').diff(from.startOf('week'), 'days') / daysInAWeek) *
  workingHoursInAWeek;

/////////

/// STRING MANIPULATION
export const formatPhone = (phone: string) =>
  phone.startsWith('+') || phone.startsWith('0') ? phone : `+${phone}`;

export const truncateString = (string: string, numOfChars: number) =>
  string.length > numOfChars ? `${string.slice(0, numOfChars)}...` : string;

export const isValidUrl = (url: string) => {
  const urlExpression =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
  const urlRegex = new RegExp(urlExpression);
  return url.match(urlRegex);
};

const httpsString = 'https://';
const httpString = 'http://';
export const addHTTPSToUrl = (url: string) =>
  url.startsWith(httpsString) || url.startsWith(httpString) || !url.length
    ? url
    : `${httpsString}${url}`;

export const removeHTTPSFromUrl = (url: string) =>
  url.startsWith(httpsString)
    ? url.substr(httpsString.length)
    : url.startsWith(httpString)
    ? url.substr(httpString.length)
    : url;

export const capitalizeWord = (string: string) =>
  `${string[0].toUpperCase()}${string.slice(1).toLowerCase()}`;

export const stringToCamelCase = (string: string) =>
  string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : capitalizeWord(word)))
    .join('');

export const formatPercentageString = (number: number, totalNumber: number) => {
  const percentage = (number / totalNumber) * 100;
  return `${percentage.toFixed(percentage < 1 ? 2 : 0)}%`;
};

///////////

/// MISC
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getWidthOfElementInAGroup = (group: Array<any>, totalWidthInPercentage: number) =>
  `${(totalWidthInPercentage / group.length).toFixed(1)}%`;

export const getOptionFromString = (string = ''): BaseOptionType => {
  return { label: string, value: string };
};

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const getTotalTimeFromTimeEntries = (time: Array<BaseTimeEntry>) =>
  (time &&
    time.length &&
    time.reduce((accumulator, project) => accumulator + project.hoursDecimal, 0)) ||
  0;

export const getBillableAndUnbillableTime = (time: Array<TimeEntry>) => {
  const billableTime = time.filter((item) => item.isBillable);
  const nonBillableTime = time.filter((item) => !item.isBillable);
  return { billableTime, nonBillableTime };
};

export const calculateDifferenceInYearsAndMonths = (dateFrom: Date, dateTo: Date) => {
  const monthsElapsed =
    dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());
  const years = Math.floor(monthsElapsed / 12);
  const months = monthsElapsed % 12;
  return ` . ${years} yr ${months} mos`; //TODO: replace period with dot separator
};

export const findItemInArrayOfObjectsByName = (array: Array<{ name: string }>, name: string) =>
  array.find((item) => item.name === name);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createElementFromString = (htmlString: string): any[] => {
  const parser = new DOMParser();
  const htmlPurified = sanitizeHtml(htmlString, {
    allowedTags: ['strong', 'p', 'ol', 'li', 'ul', 'img', 'a'],
    allowedAttributes: {
      // eslint-disable-next-line prettier/prettier
      a: ['href'],
    },
  });
  const doc = parser.parseFromString(htmlPurified, 'text/html');
  const children = Array.from(doc.body.children);

  const createReactElement = (node: Element): JSX.Element => {
    const attributes: { [key: string]: string } = {};
    for (let i = 0; i < node.attributes.length; i++) {
      const { name, value } = node.attributes[i];
      attributes[name] = value;
    }

    const childNodes = Array.from(node.childNodes);
    const childrenToRender = childNodes.map((childNode) =>
      childNode.nodeType === Node.TEXT_NODE
        ? childNode.textContent
        : createReactElement(childNode as Element),
    );

    return React.createElement(node.tagName.toLowerCase(), attributes, ...childrenToRender);
  };

  return children.map((child) => createReactElement(child as Element));
};
