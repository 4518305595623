import * as React from 'react';
import UserPicker from '../UserPicker/UserPicker';
import InputCustom from '../UI/InputCustom/InputCustom';
import ModalCustom from '../UI/ModalCustom/ModalCustom';
import TextArea from '../UI/TextArea/TextArea';
import { User } from '../../redux/reducers/user/userInterface';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getUserTokensBalance,
  getUserTokensTransactionHistory,
  rewardUser,
} from '../../redux/reducers/user/userApi';
import {
  isPending,
  loggedUser,
  selectedUserTokensBalance,
} from '../../redux/reducers/user/userSelectors';
import useInputField from '../../hooks/useInputField';

export type Props = {
  open: boolean;
  user?: User;
  onCancel: () => void;
  onOk: () => void;
};

const SendTokensModal: React.FC<Props> = ({ open, user, onCancel, onOk }) => {
  const sendTo = useInputField(user ? user.id : '', true);
  const amount = useInputField(1, true);
  const description = useInputField('', true);

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(loggedUser);
  const appPending = useAppSelector(isPending);
  const balance = useAppSelector(selectedUserTokensBalance).find(
    (token) => token.tokenType === 'IND',
  )?.balance;

  const sendTokens = () => {
    if (currentUser?.id && sendTo.value && amount.value) {
      dispatch(
        rewardUser({
          currentUserId: currentUser.id,
          reward: {
            receiverId: sendTo.value,
            amount: amount.value,
            description: description.value,
          },
        }),
      ).then(() => {
        if (!appPending) {
          dispatch(getUserTokensBalance(currentUser.id));
          dispatch(getUserTokensTransactionHistory({ currentUserId: currentUser.id }));
          onOk();
        }
      });
    }
    sendTo.reset();
    amount.reset();
    description.reset();
  };

  const canSubmitForm = React.useMemo(
    () => sendTo.value && amount.value,
    [sendTo.value, amount.value],
  );

  return (
    <ModalCustom
      form
      withHeader
      cancelText="Cancel"
      footer="regular"
      okText="Send"
      size="medium"
      title="Send tokens"
      open={open}
      onCancel={onCancel}
      onOk={sendTokens}
      okButtonDisabled={!canSubmitForm}
      okButtonLoading={appPending}
    >
      <div className="send-tokens-modal-receiver">
        <UserPicker
          required
          label="Send to"
          className="send-tokens-modal-receiver-name"
          disabled={!!user}
          value={sendTo.value}
          onChange={sendTo.onChange}
        />
        <InputCustom
          className="send-tokens-modal-receiver-amount"
          iconLeft="MdAccountBalanceWallet"
          id="tokens-amount"
          label="Tokens"
          placeholder="Enter amt."
          type="number"
          value={amount.value}
          onChange={amount.onChange}
          max={balance}
          min={1}
        />
      </div>
      <TextArea
        id="description"
        label="Description"
        placeholder="Describe why you are thankful..."
        maxLength={213}
        value={description.value}
        onChange={description.onChange}
      />
    </ModalCustom>
  );
};

export default SendTokensModal;
