import * as React from 'react';

import SendTokensSuccessModal from './SendTokensSuccessModal';
import SendTokensModal from './SendTokensModal';
import { User } from '../../redux/reducers/user/userInterface';
import './SendTokensWizard.scss';

export type Props = {
  user?: User;
  wizardStarted: boolean;
  exitWizard: () => void;
};

const SendTokensWizard: React.FC<Props> = ({ user, wizardStarted, exitWizard }) => {
  const [sendTokensModalOpen, setSendTokensModalOpen] = React.useState(wizardStarted);
  const [sendTokensSuccessModalOpen, setSendTokensSuccessModalOpen] = React.useState(false);

  React.useEffect(() => {
    setSendTokensModalOpen(wizardStarted);
  }, [wizardStarted]);

  const goToSuccess = () => {
    setSendTokensModalOpen(false);
    setSendTokensSuccessModalOpen(true);
  };

  const cancelSuccessModal = () => {
    setSendTokensSuccessModalOpen(false);
    exitWizard();
  };

  return (
    <>
      <SendTokensModal
        open={sendTokensModalOpen}
        user={user}
        onCancel={exitWizard}
        onOk={goToSuccess}
      />
      <SendTokensSuccessModal open={sendTokensSuccessModalOpen} onCancel={cancelSuccessModal} />
    </>
  );
};

export default SendTokensWizard;
