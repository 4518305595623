import * as React from 'react';
import './feedbackModal.scss';
import { useState } from 'react';
import ModalCustom from '../UI/ModalCustom/ModalCustom';
import useInputField from '../../hooks/useInputField';
import InputCustom from '../UI/InputCustom/InputCustom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { isPending, loggedUser } from '../../redux/reducers/user/userSelectors';
import TextArea from '../UI/TextArea/TextArea';
import SelectCustom from '../UI/SelectCustom/SelectCustom';
import { sendFeedback } from '../../redux/reducers/user/userApi';

export type Props = {
  // optional props, define props here to utilize within the component
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const FeedbackModal: React.FC<Props> = (props: Props) => {
  const { onCancel, onOk, open } = props;

  const options = ['Comment', 'Issue'];
  const [currentOption, setCurrentOption] = useState('');

  const loggedInUser = useAppSelector(loggedUser);
  const appPending = useAppSelector(isPending);
  const dispatch = useAppDispatch();
  const description = useInputField('', true);
  const subject = useInputField('');

  const handleSubmitFeedback = () => {
    if (loggedInUser) {
      const feedbackPayload = {
        from: loggedInUser.email,
        url: window.location.href,
        type: currentOption,
        subject: subject.value,
        note: description.value,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      };
      dispatch(sendFeedback(feedbackPayload));
    }
    description.reset();
    subject.reset();
    onOk();
  };

  const canSubmitForm = React.useMemo(
    () => description.value && subject.value,
    [description.value, subject.value],
  );

  return (
    <ModalCustom
      form
      withHeader
      cancelText="Cancel"
      footer="regular"
      okText="Send"
      size="regular"
      title="Send Feedback"
      open={open}
      onCancel={onCancel}
      onOk={handleSubmitFeedback}
      okButtonDisabled={!canSubmitForm}
      okButtonLoading={appPending}
    >
      <InputCustom
        required
        id="subject"
        label="subject"
        placeholder="Subject"
        errorMessage="Please enter an subject."
        value={subject.value}
        onChange={subject.onChange}
      />
      <SelectCustom
        className="w-36"
        id="feedbackType"
        label="Feedback Type"
        value={{ label: currentOption, value: currentOption }}
        options={options.map((option) => {
          return { label: option, value: option };
        })}
        onChange={(event) => setCurrentOption(event)}
      />
      <TextArea
        id="description"
        label="Description"
        placeholder="Describe the issue"
        maxLength={255}
        value={description.value}
        onChange={description.onChange}
      />
    </ModalCustom>
  );
};

export default FeedbackModal;
