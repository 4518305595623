/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import AvatarCustom from '../AvatarCustom/AvatarCustom';
import InputCustom from '../UI/InputCustom/InputCustom';
import DividerCustom from '../UI/DividerCustom/DividerCustom';
import ButtonCustom from '../UI/ButtonCustom/ButtonCustom';
import IconCustom from '../IconCustom/IconCustom';
import './socialMediaBox.scss';

interface BasicProps {
  id: string;
  name: string;
  url: string;
  profileUrl: string;
  invalid?: boolean;
  lastItem?: boolean;
  status?: string;
  onChange: (arg: any) => void;
}

interface PresetSMProps extends BasicProps {
  imageUrl: string;
  custom?: never;
  onEdit?: never;
  onDelete?: never;
}

interface CustomSMProps extends BasicProps {
  custom: true;
  onEdit: () => void;
  onDelete: () => void;
  imageUrl?: never;
}

export type Props = PresetSMProps | CustomSMProps;

const SocialMediaBox: React.FC<Props> = ({
  custom,
  id,
  invalid,
  imageUrl,
  lastItem,
  name,
  status,
  profileUrl,
  url,
  onChange,
  onDelete,
  onEdit,
}) => {
  return (
    <>
      <div className="socialMediaBox-root">
        <div className="socialMediaBox-header">
          {!custom && <AvatarCustom size="sm" imageSrc={imageUrl} className="mr-4" />}
          <div className="socialMediaBox-info">
            <p className="socialMediaBox-name">
              {name}
              {status === 'Approved' && <IconCustom className="pl-1" avatarIcon="success" />}
              {status === 'Declined' && <IconCustom className="pl-1" avatarIcon="error" />}
              {status === 'Pending' && <IconCustom className="pl-1" avatarIcon="pending" />}
            </p>
            <p className="socialMediaBox-url">{url}</p>
          </div>
        </div>
        <div className="flex justify-end items-center">
          <InputCustom
            id={id}
            label=""
            value={profileUrl}
            onChange={onChange}
            placeholder={`Enter your ${name} url`}
            iconLeft="MdLink"
            invalid={invalid}
            errorMessage="Please enter a valid url"
          />
          {custom && (
            <div className="flex ml-4">
              <ButtonCustom type="text" className="socialMediaBox-button" onClick={onDelete}>
                <IconCustom icon="MdDeleteForever" className="text-lg" color="gray-400" />
              </ButtonCustom>
              <ButtonCustom type="text" className="socialMediaBox-button" onClick={onEdit}>
                <IconCustom icon="MdEdit" className="text-lg ml-1" color="gray-400" />
              </ButtonCustom>
            </div>
          )}
        </div>
      </div>
      {!lastItem && <DividerCustom className="my-4" />}
    </>
  );
};

export default SocialMediaBox;
