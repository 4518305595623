import * as React from 'react';
import DividedSection from '../DividedSection/DividedSection';
import ProjectCard from '../ProjectCard/ProjectCard';
import ButtonCustom from '../UI/ButtonCustom/ButtonCustom';
import { Project } from '../../redux/reducers/user/userInterface';
import './userActiveProjectsSection.scss';

export type Props = {
  projects: Array<Project>;
};

const cardsShownByDefault = 2;

const UserActiveProjectsSection: React.FC<Props> = ({ projects }) => {
  const [isShowingAll, setIsShowingAll] = React.useState(false);
  const [projectsCount, setProjectsCount] = React.useState<number>(cardsShownByDefault);
  const projectCards = projects.map((project) => {
    return <ProjectCard key={project.id} {...project} />;
  });

  const handleShowMore = () => {
    const allProjectsLength = projectCards.length;
    if (projectsCount < allProjectsLength) {
      setProjectsCount(projectsCount + 4);
      setIsShowingAll(false);
    } else {
      setProjectsCount(allProjectsLength);
      setIsShowingAll(true);
    }
  };

  const handleShowLess = () => {
    setIsShowingAll(false);
    setProjectsCount(cardsShownByDefault);
  };

  return (
    <DividedSection className="userActiveProjectsSection-root" title="Active Projects">
      <div className="projectSection-cards-grid">
        {isShowingAll ? projectCards : projectCards.slice(0, projectsCount)}
      </div>
      {!isShowingAll && projectCards.length > projectsCount ? (
        <ButtonCustom
          type="default"
          className="projectSection-button"
          onClick={() => handleShowMore()}
        >
          Show More
        </ButtonCustom>
      ) : (
        <ButtonCustom
          type="default"
          className="projectSection-button"
          onClick={() => handleShowLess()}
        >
          Show Less
        </ButtonCustom>
      )}
    </DividedSection>
  );
};

export default UserActiveProjectsSection;
