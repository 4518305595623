import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ModalCustom from '../../UI/ModalCustom/ModalCustom';
import WelcomeSuccessImage from '../../../assets/images/welcome-success.gif';
import { useAppSelector } from '../../../redux/hooks';
import { loggedUser } from '../../../redux/reducers/user/userSelectors';
import './WelcomeSuccess.scss';

type Props = {
  open?: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const WelcomeSuccess: React.FC<Props> = ({ open = false, onCancel, onOk }) => {
  const navigate = useNavigate();
  const userId = useAppSelector(loggedUser)?.id;

  const goToSettings = () => {
    navigate('/settings');
    onOk();
  };

  const goToProfile = () => {
    navigate(`/profile/${userId}`);
    onCancel();
  };

  return (
    <ModalCustom
      open={open}
      footer="fullLength"
      onCancel={goToProfile}
      onOk={goToSettings}
      cancelText="View your Profile"
      okText="Keep adding stuff"
    >
      <div className="small-modal-content">
        <img className="welcome-success-image" src={WelcomeSuccessImage} alt=" " />
        <h2 className="modal-heading">Thanks for taking the time!</h2>
        <p className="modal-subheading">
          There's a lot more you can do on INDUSTRIA Dashboard, so feel free to explore and ask
          around if you need any help.
        </p>
      </div>
    </ModalCustom>
  );
};

export default WelcomeSuccess;
