/* eslint-disable @typescript-eslint/no-explicit-any */

import { uid } from 'uid';
import { TechnologyActions } from './technologyActions';
import { presetTechnologies } from './presetTechnologies';
import { Technology } from '../user/userInterface';
import { UITechnology } from '../../../Helpers/generalTypes';

export const technologiesReducer = (
  state: any,
  action: {
    type: string;
    name?: string;
    experience?: string;
    url?: string;
    technologies?: Array<Technology>;
    custom?: boolean;
    id?: string;
    status?: string;
  },
) => {
  if (action.type === TechnologyActions.GET_TECHNOLOGIES) {
    if (!action.technologies) return action.custom ? [] : presetTechnologies;

    if (action.technologies) {
      const technologiesFromAction = [...action.technologies];

      const presetTechnologiesWithExperience = presetTechnologies.map((presetTech) => {
        const value = presetTech;
        technologiesFromAction.forEach((tech) => {
          if (tech.name === presetTech.name) {
            value.experience = tech.experience;
          }
        });
        return value;
      });

      const customTechnologies = technologiesFromAction
        .map((tech) => {
          const techIsPreset = presetTechnologies.find(
            (presetTech) => tech.name === presetTech.name,
          );
          return techIsPreset ? null : { ...tech, id: uid() };
        })
        .filter((item) => item !== null);
      return action.custom ? customTechnologies : presetTechnologiesWithExperience;
    }
  }
  if (action.type === TechnologyActions.UPDATE_TECHNOLOGY_EXPERIENCE) {
    const updatedState = state.map((tech: Technology) =>
      tech.name === action.name ? { ...tech, experience: action.experience } : tech,
    );
    return updatedState;
  }
  if (action.type === TechnologyActions.ADD_CUSTOM_TECHNOLOGY) {
    const newTechnology = {
      id: action.id,
      name: action.name,
      url: action.url,
      experience: action.experience,
    };
    const updatedState = [...state, newTechnology];
    return updatedState;
  }
  if (action.type === TechnologyActions.EDIT_CUSTOM_TECHNOLOGY) {
    const updatedState = state.map((tech: UITechnology) =>
      tech.id === action.id
        ? { ...tech, name: action.name, experience: action.experience, url: action.url }
        : tech,
    );
    return updatedState;
  }
  if (action.type === TechnologyActions.DELETE_TECHNOLOGY) {
    const updatedState = state.filter((tech: UITechnology) => tech.id !== action.id);
    return updatedState;
  }
};
