import * as React from 'react';
import { Typography } from 'antd';
import AvatarCustom from '../../../../Components/AvatarCustom/AvatarCustom';
import ButtonCustom from '../../../../Components/UI/ButtonCustom/ButtonCustom';
import IconCustom from '../../../../Components/IconCustom/IconCustom';
import { truncateString, formatTimeString } from '../../../../Helpers/helperFunctions';
import './ProjectTimeCard.scss';

export type Props = {
  companyLogo: string;
  companyName: string;
  description: string;
  hoursDecimal: number;
  isBillable: boolean;
  projectName: string;
};

const ProjectTotalTimeCard: React.FC<Props> = ({
  companyLogo,
  projectName,
  companyName,
  description,
  hoursDecimal,
  isBillable,
}) => {
  const { Title } = Typography;
  const [isShowingAll, setIsShowingAll] = React.useState(false);

  const descriptionMaxLength = 64;

  const descriptionString = isShowingAll
    ? description
    : truncateString(description, descriptionMaxLength);

  const billableString = isBillable ? 'billable' : 'non-billable';

  const toggleReadMore = () => setIsShowingAll((prevIsShowingAll) => !prevIsShowingAll);

  return (
    <div className="projectTimeCard-root projectTimeCard-total">
      <div className="projectTimeCard-info">
        <AvatarCustom
          className="projectTimeCard-avatar"
          size="xs"
          imageSrc={companyLogo}
          fallbackText={companyName[0]}
        />
        <div className="projectTimeCard-text">
          <Title level={4} className="projectTimeCard-title">
            {projectName}
          </Title>
          <p className="projectTimeCard-subheading">{companyName}</p>
          {description && (
            <p className="projectTimeCard-description">
              {descriptionString}{' '}
              {description.length > descriptionMaxLength && (
                <ButtonCustom type="text" onClick={toggleReadMore}>
                  {isShowingAll ? 'See less' : 'See more'}
                </ButtonCustom>
              )}
            </p>
          )}
        </div>
      </div>
      <div className="projectTimeCard-time">
        <p className="projectTimeCard-time-amount">
          <IconCustom icon="MdOutlineAccessTime" color="gray-400" className="mr-1" />{' '}
          {formatTimeString(hoursDecimal)}
        </p>
        <p className={`projectTimeCard-time-billable-status ${billableString}`}>{billableString}</p>
      </div>
    </div>
  );
};

export default ProjectTotalTimeCard;
