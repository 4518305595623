/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { DatePicker, Input, InputNumber, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { uid } from 'uid';

import PhoneInput from './PhoneInput/PhoneInput';
import IconCustom from '../../IconCustom/IconCustom';
import Label from '../Label/Label';

import type { Icon } from '../../../Helpers/generalTypes';

import './inputCustom.scss';

type DateTimeTypes = 'date' | 'week' | 'month' | 'quarter' | 'year';

interface BasicProps {
  className?: string;
  disabled?: boolean;
  id?: string;
  errorMessage?: string;
  label?: string;
  iconLeft?: Icon;
  iconRight?: Icon;
  invalid?: boolean;
  placeholder?: string;
  required?: boolean;
  tooltip?: string | React.ReactNode;
  onChange?: ([arg]?: any) => void;
  [key: string]: any;
}

interface StringInputProps extends BasicProps {
  value: string;
  requiredGroup?: boolean;
  type?: 'text' | 'email' | 'url' | 'datetime-local' | 'time'; //TODO: datetime-local and time to separate types
}

interface NumberInputProps extends BasicProps {
  value: number;
  type: 'number';
  max?: number;
  min?: number;
}

interface DateInputProps extends BasicProps {
  type: DateTimeTypes;
  dropdownOpen?: boolean;
  value?: Date | null;
  minDate?: Date;
}

interface TelInputProps extends BasicProps {
  type: 'tel';
  value: string;
  country: string;
}

export type Props = StringInputProps | NumberInputProps | DateInputProps | TelInputProps;

const InputCustom: React.FC<Props> = React.forwardRef(
  (
    {
      className = '',
      country,
      disabled,
      dropdownOpen,
      errorMessage,
      iconLeft,
      iconRight,
      invalid,
      id,
      label,
      max,
      min,
      minDate,
      placeholder,
      required,
      requiredGroup,
      tooltip,
      type = 'text',
      value,
      onChange,
      ...otherProps
    },
    ref: React.ForwardedRef<any>,
  ) => {
    const isDate =
      type === 'date' ||
      type === 'week' ||
      type === 'month' ||
      type === 'quarter' ||
      type === 'year';
    const [isTouched, setIsTouched] = React.useState(false);
    const [isInvalid, setIsInvalid] = React.useState<boolean | undefined>(false);

    React.useEffect(() => {
      setIsInvalid((isTouched && invalid) || (required && isTouched && !value));
    }, [isTouched, value, invalid, required]);

    const disabledDate = (date: Dayjs) => {
      return date && date < dayjs(minDate);
    };

    const iconPrefix: Array<React.ReactNode> = [];
    const iconSuffix: Array<React.ReactNode> = [];

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getIcon = (iconStr: Icon, className = '', color = '') => (
      <IconCustom
        icon={iconStr}
        color={color || 'gray-400'}
        className={`${className} text-base`}
        key={iconStr}
      />
    );

    if (iconLeft) {
      iconPrefix.push(getIcon(iconLeft, 'mr-1'));
    }

    if (isDate) {
      iconSuffix.push(getIcon('MdToday', 'h-4'));
    }

    if (iconRight) {
      iconSuffix.push(getIcon(iconRight));
    }

    if (isInvalid) {
      iconSuffix.push(getIcon('MdError', '', 'rose-500'));
    }

    if (disabled) {
      iconSuffix.push(getIcon('MdBlockFlipped'));
    }

    const inputProps: {
      size: 'large';
      id: string;
      onChange: ([arg]?: any) => void;
      [key: string]: any;
    } = {
      className: `input input-${type}`,
      id: id || uid(),
      size: 'large',
      status: isInvalid ? 'error' : '',
      onBlur: () => {
        setIsTouched(true);
      },
      value: isDate ? (value ? dayjs(value) : null) : value,
      disabled,
      ref,
      onChange: onChange ? (isDate ? (e: Dayjs) => onChange(e.toDate()) : onChange) : () => {},
    };

    const labelProps = { id: id || uid(), disabled, label, required: required || requiredGroup };

    const inputElement =
      type === 'number' ? (
        <InputNumber
          controls={{
            upIcon: <IconCustom className="text-xl" color="gray-400" icon="MdArrowDropUp" />,
            downIcon: <IconCustom className="text-xl" color="gray-400" icon="MdArrowDropDown" />,
          }}
          prefix={iconPrefix}
          max={max}
          min={min}
          {...inputProps}
          {...otherProps}
        />
      ) : type === 'tel' ? (
        <PhoneInput country={country} value={value as string} {...inputProps} />
      ) : isDate ? (
        <DatePicker
          {...inputProps}
          allowClear={false}
          format="DD/MM/YYYY"
          picker={type as DateTimeTypes}
          suffixIcon={iconSuffix}
          popupClassName="input-date-popup"
          superNextIcon={null}
          superPrevIcon={null}
          open={dropdownOpen}
          disabledDate={minDate && disabledDate}
          {...otherProps}
        />
      ) : (
        <Input
          placeholder={placeholder}
          prefix={iconPrefix}
          suffix={[
            ...iconSuffix,
            tooltip && (
              <Tooltip title={tooltip}>
                {' '}
                <IconCustom icon="MdInfoOutline" color="gray-400" className="text-base" />
              </Tooltip>
            ),
          ]}
          type={type}
          {...inputProps}
          {...otherProps}
        />
      );

    return (
      <div className={`${className} input-root`}>
        <Label {...labelProps} />
        {inputElement}
        {isInvalid && errorMessage && <p className="error-message">{errorMessage} </p>}
      </div>
    );
  },
);

export default InputCustom;
