import { createAsyncThunk } from '@reduxjs/toolkit';
import { MainApi } from './../../../axios-instance';
import {
  UserCoverImageArgs,
  UserEducationArgs,
  UserSkillsArgs,
  UserSocialMediaArgs,
  UserTechnologyArgs,
  UserProfileArgs,
  UserExperienceArgs,
  LoggedUser,
  TeamWorkApiArgs,
  UserListArgs,
  RewardUserArgs,
  ArgsWithLimit,
  ApprovalListArgs,
  UpdateTechnologyRequestStatusArgs,
  UpdateSkillRequestStatusArgs,
  UpdateExperienceRequestStatusArgs,
  UpdateSocialMediaRequestStatusArgs,
  UpdateEducationRequestStatusArgs,
  FeedbackArgs,
} from './userInterface';

// Get Requests
// Get all approval requests
export const getApprovalRequests = createAsyncThunk(
  'user/getApprovalRequests',
  async (args: ApprovalListArgs) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/approval-requests${args.userId ? `?userId=${args.userId}` : ''}`,
    });
    return response.data;
  },
);
// Get skill requests
export const getSkillRequests = createAsyncThunk(
  'user/getSkillRequests',
  async (userId: string) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/approval-requests/${userId}/skills`,
    });
    return response.data;
  },
);
// Get technology requests
export const getTechnologyRequests = createAsyncThunk(
  'user/getTechnologyRequests',
  async (userId: string) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/approval-requests/${userId}/technologies`,
    });
    return response.data;
  },
);
// Get social media requests
export const getSocialMediaRequests = createAsyncThunk(
  'user/getSocialMediaRequests',
  async (userId: string) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/approval-requests/${userId}/social-medias`,
    });
    return response.data;
  },
);
// Get education requests
export const getEducationRequests = createAsyncThunk(
  'user/getEducationRequests',
  async (userId: string) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/approval-requests/${userId}/education`,
    });
    return response.data;
  },
);
// Get experience requests
export const getExperienceRequests = createAsyncThunk(
  'user/getExperienceRequests',
  async (userId: string) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/approval-requests/${userId}/experience`,
    });
    return response.data;
  },
);

const getListOfUsers = async (args: UserListArgs) => {
  const api = new MainApi();
  const instance = api.getInstance();
  const response = await instance({
    method: 'GET',
    url: `/api/v1/users?offset=${args.offset}&limit=${args.usersPerPage}${
      args.teamId ? `&teamId=${args.teamId}` : ''
    }`,
  });
  return response.data;
};

export const getUsers = createAsyncThunk('user/getUsersList', async (args: UserListArgs) => {
  return getListOfUsers(args);
});

export const getUsersByTeamId = createAsyncThunk(
  'user/getUsersByTeamId',
  async (args: Partial<UserListArgs>) => {
    return getListOfUsers({ offset: 0, usersPerPage: 100, teamId: args.teamId });
  },
);

export const getLoggedUser = createAsyncThunk('user/getLoggedInUser', async () => {
  const api = new MainApi();
  const instance = api.getInstance();
  const response = await instance({
    method: 'GET',
    url: '/api/v1/users/current',
  });
  return response.data;
});

const getUserById = async (userId: string) => {
  const api = new MainApi();
  const instance = api.getInstance();
  const response = await instance({
    method: 'GET',
    url: `/api/v1/users/${userId}`,
  });
  return response.data;
};

export const getAllDataForLoggedUser = createAsyncThunk(
  'user/getAllDataForLoggedUser',
  async (userId: string) => {
    return getUserById(userId);
  },
);

export const getSelectedUser = createAsyncThunk('user/getUserById', async (userId: string) => {
  return getUserById(userId);
});

export const getEducationList = createAsyncThunk(
  'user/getUserEducationList',
  async (userId: string) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/users/${userId}/education`,
    });
    return response.data;
  },
);

export const getExperience = createAsyncThunk(
  'user/getUserExperienceList',
  async (userId: string) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/users/${userId}/experience`,
    });
    return response.data;
  },
);

export const getUserDailyTime = createAsyncThunk(
  'user/getUserDailyTime',
  async (timeParameterArgs: { userId: string; date: string }) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/users/${timeParameterArgs.userId}/times/day?fromDate=${timeParameterArgs.date}&toDate=${timeParameterArgs.date}`,
    });
    return response.data;
  },
);

export const getUserProjectTime = createAsyncThunk(
  'user/getUserProjectTime',
  async (timeParameterArgs: { userId: string; fromDate: string; toDate: string }) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/users/${timeParameterArgs.userId}/times/projects?fromDate=${timeParameterArgs.fromDate}&toDate=${timeParameterArgs.toDate}`,
    });
    return response.data;
  },
);

export const getUserBillableTime = createAsyncThunk(
  'user/getUserBillableTime',
  async (timeParameterArgs: { userId: string; fromDate: string; toDate: string }) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/users/${timeParameterArgs.userId}/times/projects?fromDate=${timeParameterArgs.fromDate}&toDate=${timeParameterArgs.toDate}`,
    });
    return response.data;
  },
);

export const getUserCustomTime = createAsyncThunk(
  'user/getUserCustomTime',
  async (timeParameterArgs: { userId: string; fromDate: string; toDate: string }) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/users/${timeParameterArgs.userId}/times/day?fromDate=${timeParameterArgs.fromDate}&toDate=${timeParameterArgs.toDate}`,
    });
    return response.data;
  },
);

export const getUserProjects = createAsyncThunk(
  'user/getUserProjects',
  async (args: ArgsWithLimit) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/users/${args.currentUserId}/projects?offset=0&limit=${
        args.limited ? 2 : 100
      }&status=ACTIVE`,
    });
    return response.data;
  },
);

export const getUserTokensTransactionHistory = createAsyncThunk(
  'user/getUserTokensTransactionHistory',
  async (tokensTransactionHistoryArgs: ArgsWithLimit) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/tokens/${
        tokensTransactionHistoryArgs.currentUserId
      }/transactions?offset=0&limit=${tokensTransactionHistoryArgs.limited ? 6 : 100}`,
    });
    return response.data;
  },
);

export const getUserTokensBalance = createAsyncThunk(
  'user/getUserTokensBalance',
  async (currentUserId: string) => {
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/tokens/${currentUserId}/balance`,
    });
    return response.data;
  },
);

// POST REQUESTS

export const addUserEducation = createAsyncThunk(
  'user/addUserEducation',
  async (educationArgs: UserEducationArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'POST',
      url: `/api/v1/users/${educationArgs.currentUserId}/education`,
      data: educationArgs.education,
    });
    return response.data;
  },
);

export const addUserWorkExperience = createAsyncThunk(
  'user/addUserWorkExperience',
  async (experienceArgs: UserExperienceArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'POST',
      url: `/api/v1/users/${experienceArgs.currentUserId}/experience`,
      data: experienceArgs.experience,
    });
    return response.data;
  },
);

export const addUserCoverImage = createAsyncThunk(
  'user/addUserCoverImage',
  async (coverImageArgs: UserCoverImageArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'POST',
      url: `/api/v1/users/${coverImageArgs.currentUserId}/cover`,
      data: coverImageArgs.coverImage,
    });
    return response.data;
  },
);

// POST REQUESTS - About Approve/Decline Request Proccess
//-- 1 -- technology
export const updateTechnologyRequestStatus = createAsyncThunk(
  'user/updateTechnologyRequestStatus',
  async (updateTechnologyRequestStatusArgs: UpdateTechnologyRequestStatusArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'POST',
      url: `/api/v1/approval-requests/${updateTechnologyRequestStatusArgs.userId}/technologies/${updateTechnologyRequestStatusArgs.technologyId}`,
      data: {
        action: updateTechnologyRequestStatusArgs.action,
        notes: updateTechnologyRequestStatusArgs.notes,
      },
    });
    return response.data;
  },
);
//-- 2 -- skill
export const updateSkillRequestStatus = createAsyncThunk(
  'user/updateSkillRequestStatus',
  async (updateSkillRequestStatusArgs: UpdateSkillRequestStatusArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'POST',
      url: `/api/v1/approval-requests/${updateSkillRequestStatusArgs.userId}/skills/${updateSkillRequestStatusArgs.skillId}`,
      data: {
        action: updateSkillRequestStatusArgs.action,
        notes: updateSkillRequestStatusArgs.notes,
      },
    });
    return response.data;
  },
);
//-- 3 -- experience
export const updateExperienceRequestStatus = createAsyncThunk(
  'user/updateExperienceRequestStatus',
  async (updateExperienceRequestStatusArgs: UpdateExperienceRequestStatusArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'POST',
      url: `/api/v1/approval-requests/${updateExperienceRequestStatusArgs.userId}/experience/${updateExperienceRequestStatusArgs.experienceId}`,
      data: {
        action: updateExperienceRequestStatusArgs.action,
        notes: updateExperienceRequestStatusArgs.notes,
      },
    });
    return response.data;
  },
);
//-- 4 -- social media
export const updateSocialMediaRequestStatus = createAsyncThunk(
  'user/updateSocialMediaRequestStatus',
  async (updateSocialMediaRequestStatusArgs: UpdateSocialMediaRequestStatusArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'POST',
      url: `/api/v1/approval-requests/${updateSocialMediaRequestStatusArgs.userId}/social-medias/${updateSocialMediaRequestStatusArgs.socialMediaId}`,
      data: {
        action: updateSocialMediaRequestStatusArgs.action,
        notes: updateSocialMediaRequestStatusArgs.notes,
      },
    });
    return response.data;
  },
);
//-- 5 -- education
export const updateEducationRequestStatus = createAsyncThunk(
  'user/updateEducationRequestStatus',
  async (updateEducationRequestStatusArgs: UpdateEducationRequestStatusArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'POST',
      url: `/api/v1/approval-requests/${updateEducationRequestStatusArgs.userId}/education/${updateEducationRequestStatusArgs.educationId}`,
      data: {
        action: updateEducationRequestStatusArgs.action,
        notes: updateEducationRequestStatusArgs.notes,
      },
    });
    return response.data;
  },
);

export const rewardUser = createAsyncThunk(
  'user/rewardUser',
  async (rewardUserArgs: RewardUserArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'POST',
      url: `/api/v1/tokens/${rewardUserArgs.currentUserId}/reward`,
      data: rewardUserArgs.reward,
    });
    return response.data;
  },
);

// PUT REQUESTS

export const updateUserSkills = createAsyncThunk(
  'user/updateUserSkills',
  async (skillsArgs: UserSkillsArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'PUT',
      url: `/api/v1/users/${skillsArgs.currentUserId}/skills`,
      data: { skills: skillsArgs.skills },
    });
    return response.data;
  },
);

export const updateUserTechnologies = createAsyncThunk(
  'user/updateUserTechnologies',
  async (technologyArgs: UserTechnologyArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'PUT',
      url: `/api/v1/users/${technologyArgs.currentUserId}/technologies`,
      data: { technologies: technologyArgs.technologies },
    });
    return response.data;
  },
);

export const updateUserSocialMedia = createAsyncThunk(
  'user/updateUserSocialMedia',
  async (socialMediaArgs: UserSocialMediaArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'PUT',
      url: `/api/v1/users/${socialMediaArgs.currentUserId}/social-media`,
      data: { socialMedia: socialMediaArgs.socialMedia },
    });
    return response.data;
  },
);

export const updateUserProfile = createAsyncThunk(
  'user/updateUserProfile',
  async (data: UserProfileArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'PUT',
      url: `/api/v1/users/${data.currentUserId}/profile`,
      data: data,
    });
    return response.data;
  },
);

export const updateUserExperience = createAsyncThunk(
  'user/updateUserExperience',
  async (experienceArgs: UserExperienceArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'PUT',
      url: `/api/v1/users/${experienceArgs.currentUserId}/experience/${experienceArgs.currentExperienceId}`,
      data: experienceArgs.experience,
    });
    return response.data;
  },
);

export const updateUserEducation = createAsyncThunk(
  'user/updateUserEducation',
  async (educationArgs: UserEducationArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'PUT',
      url: `/api/v1/users/${educationArgs.currentUserId}/education/${educationArgs.currentEducationId}`,
      data: educationArgs.education,
    });
    return response.data;
  },
);

// TODO: remove this for now as it is not a utilized endpoint @MG

export const updateUserProfilePicture = createAsyncThunk(
  'user/updateUserProfilePicture',
  async (data: LoggedUser) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'PUT',
      url: '/api/v1/users/current',
      data: data,
    });
    return response.data;
  },
);

export const updateUserTeamworkApiKey = createAsyncThunk(
  'user/updateUserTeamworkApiKey',
  async (teamworkApiArgs: TeamWorkApiArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    const response = await instance({
      method: 'PUT',
      url: `/api/v1/users/${teamworkApiArgs.currentUserId}/teamwork`,
      data: { apiKey: teamworkApiArgs.apiKey },
    });
    return response.data;
  },
);

/// DELETE REQUESTS
export const deleteUserExperience = createAsyncThunk(
  'user/deleteUserExperience',
  async (experienceArgs: UserExperienceArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    await instance({
      method: 'DELETE',
      url: `/api/v1/users/${experienceArgs.currentUserId}/experience/${experienceArgs.currentExperienceId}`,
    });
    return experienceArgs.currentExperienceId;
  },
);

export const deleteUserEducation = createAsyncThunk(
  'user/deleteUserEducation',
  async (educationArgs: UserEducationArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    await instance({
      method: 'DELETE',
      url: `/api/v1/users/${educationArgs.currentUserId}/education/${educationArgs.currentEducationId}`,
    });
    return educationArgs.currentEducationId;
  },
);

// send user feedback

export const sendFeedback = createAsyncThunk(
  'user/sendFeedback',
  async (feedbackArgs: FeedbackArgs) => {
    const api = new MainApi();
    await api.getCsrfToken();
    const instance = api.getInstance();
    await instance({
      method: 'POST',
      url: `/api/v1/feedback`,
      data: feedbackArgs,
    });
  },
);
