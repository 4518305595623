import * as React from 'react';
import { Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { uid } from 'uid';
import ProjectPercentageTimeCard from './PersonalTimeTrackerTab/ProjectTimeCard/ProjectPercentageTimeCard';
import AvatarCustom from '../../Components/AvatarCustom/AvatarCustom';
import DividerCustom from '../../Components/UI/DividerCustom/DividerCustom';
import StatusTag from '../../Components/StatusTag/StatusTag';
import {
  formatTimeString,
  getBillableAndUnbillableTime,
  getTotalTimeFromTimeEntries,
  getWorkHoursInAMonth,
} from '../../Helpers/helperFunctions';
import { CalendarModes, StatusTagProps } from '../../Helpers/generalTypes';
import { workingHoursInAWeek } from '../../Helpers/constants';
import { TimeEntry } from '../../redux/sharedInterface';
import { User } from '../../redux/reducers/user/userInterface';
import './TimeSummary.scss';

const { Text } = Typography;

export type Props = {
  from: Dayjs;
  mode: CalendarModes;
  time: Array<TimeEntry>;
  breakdownTitle?: string;
  className?: string;
  user?: User;
};

const TimeSummary: React.FC<Props> = ({
  breakdownTitle = 'Breakdown',
  className = '',
  from,
  mode,
  time,
  user,
}) => {
  const totalTime = getTotalTimeFromTimeEntries(time);
  const { billableTime, nonBillableTime } = getBillableAndUnbillableTime(time);

  const startOfCurrentPeriod =
    mode === 'week' ? dayjs().startOf('week') : dayjs().startOf('month').startOf('week');
  const isPastPeriod = from.isBefore(startOfCurrentPeriod);

  const benchmarkHours = mode === 'week' ? workingHoursInAWeek : getWorkHoursInAMonth(from);

  const status = !totalTime
    ? 'none'
    : totalTime > benchmarkHours
    ? 'overbooked'
    : totalTime === benchmarkHours
    ? 'complete'
    : 'incomplete';

  const projectsTime: Array<TimeEntry> = [];
  time.forEach((item) => {
    for (const dataItem of projectsTime) {
      if (item.companyName === dataItem.companyName && item.projectName === dataItem.projectName) {
        dataItem.hoursDecimal += item.hoursDecimal;
        return;
      }
    }
    projectsTime.push({
      ...item,
    });
  });

  const maxTime =
    projectsTime &&
    projectsTime.length &&
    projectsTime.reduce((prevProject, nextProject) =>
      prevProject.hoursDecimal > nextProject.hoursDecimal ? prevProject : nextProject,
    ).hoursDecimal;

  const projectCards = projectsTime
    .sort((a, b) => b.hoursDecimal - a.hoursDecimal)
    .map((project, i) => {
      return (
        <>
          <ProjectPercentageTimeCard {...project} key={uid()} maximum={maxTime || 0} />
          {i < time.length - 1 && <DividerCustom className="my-5" />}
        </>
      );
    });

  const statusTagConfig: {
    [x: string]: StatusTagProps;
  } = {
    complete: {
      icon: 'MdCheck',
      color: 'green',
      text: 'Fully logged',
    },
    incomplete: {
      icon: 'MdOutlineAccessTimeFilled',
      color: 'red',
      text: 'Partially logged',
    },
    overbooked: {
      icon: 'MdFlag',
      color: 'yellow',
      text: 'Overbooked',
    },
    none: {
      icon: 'MdOutlineAccessTimeFilled',
      color: 'red',
      text: 'No time logged',
    },
  };

  return (
    <div className={`timeSummary ${className}`}>
      <div className="timeSummary-data">
        {user && (
          <div className="timeSummary-data-user">
            <AvatarCustom
              className="timeSummary-data-user-avatar"
              imageSrc={user.profilePicture}
              size="md"
            />
            <div className="timeSummary-data-user-details">
              <Text className="timeSummary-data-user-name">
                {user.firstName} {user.lastName}
              </Text>
              <Text className="timeSummary-data-user-job">{user.jobRole}</Text>
            </div>
          </div>
        )}
        <div className="timeSummary-data-total">
          <Text className="timeSummary-data-name">Total hours</Text>
          <Text className={`timeSummary-data-total-hours ${user ? '' : 'text-base font-semibold'}`}>
            {totalTime ? formatTimeString(totalTime) : 0} / {`${formatTimeString(benchmarkHours)}`}
          </Text>
        </div>
        {billableTime.length ? (
          <div>
            <Text className="timeSummary-data-name">Billable</Text>
            <Text className="timeSummary-data-hours">
              {formatTimeString(getTotalTimeFromTimeEntries(billableTime))}
            </Text>
          </div>
        ) : null}
        {nonBillableTime.length ? (
          <div>
            <Text className="timeSummary-data-name non-billable">Non-billable</Text>
            <Text className="timeSummary-data-hours">
              {formatTimeString(getTotalTimeFromTimeEntries(nonBillableTime))}
            </Text>
          </div>
        ) : null}
        {isPastPeriod && (
          <div>
            <Text className="timeSummary-data-name">Status</Text>
            <StatusTag {...statusTagConfig[status]} />
          </div>
        )}
      </div>
      {projectsTime.length ? (
        <>
          <DividerCustom />
          <div className="timeSummary-projects">
            <Text className="inner-subheading">{breakdownTitle}</Text>
            <div className="timeSummary-projects-list">{projectCards}</div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TimeSummary;
