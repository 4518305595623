import * as React from 'react';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import { uid } from 'uid';
import ProjectTotalTimeCard from './ProjectTimeCard/ProjectTotalTimeCard';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import IconCustom from '../../../Components/IconCustom/IconCustom';
import {
  formatDate,
  formatTimeString,
  getTotalTimeFromTimeEntries,
} from '../../../Helpers/helperFunctions';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { selectedUserTime } from '../../../redux/reducers/user/userSelectors';
import { getUserDailyTime } from '../../../redux/reducers/user/userApi';

export type Props = {
  userId: string;
};

const fullWorkDay = 8;

const TodaySection: React.FC<Props> = ({ userId }) => {
  const { Link } = Typography;

  const dispatch = useAppDispatch();
  const currentSelectedUserTime = useAppSelector(selectedUserTime);

  const todayTime = currentSelectedUserTime?.today;
  const today = dayjs(new Date());

  React.useEffect(() => {
    if (userId) {
      dispatch(
        getUserDailyTime({
          userId,
          date: formatDate(today),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const totalTimeLogged = getTotalTimeFromTimeEntries(todayTime);

  const projectCards =
    todayTime &&
    todayTime.length &&
    todayTime.map((project, i: number) => (
      <>
        <ProjectTotalTimeCard {...project} key={uid()} />
        {i < todayTime.length - 1 && <DividerCustom className="my-5" />}
      </>
    ));

  const todayString = `Today, ${formatDate(today, 'DD MMM')}`;
  const timeFullyLogged = totalTimeLogged && totalTimeLogged >= fullWorkDay;

  return (
    <div className="todaySection-root">
      <div className="todaySection-header">
        <h2 className="todaySection-title">{todayString}</h2>
        <Link
          target="_blank"
          href="https://industriatech.teamwork.com/app/home/projects"
          className="link-as-button link-as-button-primary"
        >
          Open Teamwork
        </Link>
      </div>
      {projectCards ? <div className="todaySection-projects">{projectCards}</div> : null}
      <div className="todaySection-total-time">
        <span>Total Logged Time</span>
        <div>
          <span>
            <span className="font-bold">
              {totalTimeLogged ? formatTimeString(totalTimeLogged) : 0}
            </span>
            /{formatTimeString(fullWorkDay)}
          </span>
          {!timeFullyLogged && (
            <span className="todaySection-total-time-warning">
              <IconCustom icon="MdWarning" color="amber-300" className="text-sm" /> Please log your
              time!
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default TodaySection;
