/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import './layoutHeader.scss';

// imports from AntDesign
import { Header } from 'antd/es/layout/layout';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export type Props = {
  children?: React.ReactNode;
  collapsed?: boolean;
  setCollapsed: (arg: any) => void;
};

const LayoutHeader: React.FC<Props> = (props: Props) => {
  const { children, collapsed, setCollapsed } = props;

  return (
    <Header className="layoutHeader-root">
      {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
        className: 'trigger',
        onClick: setCollapsed,
      })}
      {children}
    </Header>
  );
};

export default LayoutHeader;
