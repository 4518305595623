import * as React from 'react';
import AllApprovalRequests from './AllApprovalRequests';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import { team } from '../../../redux/reducers/team/teamSelectors';
import { getTeam } from '../../../redux/reducers/team/teamApi';

export type Props = {
  className?: string;
};

const ApprovalRequestsTab: React.FC<Props> = ({ className }) => {
  //get teams
  const dispatch = useAppDispatch();
  const teams = useAppSelector(team);

  React.useEffect(() => {
    dispatch(getTeam());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <div className="AllApprovalRequests-root">
        <AllApprovalRequests teams={teams}></AllApprovalRequests>
      </div>
    </div>
  );
};

export default ApprovalRequestsTab;
