import * as React from 'react';
import { Typography } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import TeamTimeTrackerMonthly from './TeamTimeTrackerMonthly';
import TeamTimeTrackerWeekly from './TeamTimeTrackerWeekly';
import SelectCustom from '../../../Components/UI/SelectCustom/SelectCustom';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import HeadingWithBackButton from '../../../Components/HeadingWithBackButton/HeadingWithBackButton';
import TimeRangePicker from '../../../Components/TimeRangePicker/TimeRangePicker';
import { CalendarModes } from '../../../Helpers/generalTypes';
import { formatDate } from '../../../Helpers/helperFunctions';
import useMemoizedFromToTimeRange from '../../../hooks/useMemoizedFromToTimeRange';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { loggedUser } from '../../../redux/reducers/user/userSelectors';
import { getTeamTimeGrouped } from '../../../redux/reducers/team/teamApi';
import { teamTimeGrouped } from '../../../redux/reducers/team/teamSelectors';
import './TeamTimeTrackerTab.scss';
import { currentChosenDate } from '../../../redux/reducers/global/globalSelectors';

const { Link } = Typography;

const TeamTimeTrackerTab: React.FC = () => {
  const currentLoggedUser = useAppSelector(loggedUser);
  const teamMembersTime = useAppSelector(teamTimeGrouped);
  const dispatch = useAppDispatch();

  const [options, setOptions] = React.useState<Array<BaseOptionType>>([]);
  const [selectedTeam, setSelectedTeam] = React.useState<BaseOptionType>();
  const [mode, setMode] = React.useState<CalendarModes>('week');
  const currentDateSelected = useAppSelector(currentChosenDate);

  const isWeekly = React.useMemo(() => mode === 'week', [mode]);

  React.useEffect(() => {
    if (currentLoggedUser?.teams) {
      const teamsOptions = currentLoggedUser.teams.map((team) => {
        return { value: team.id, label: team.name };
      });
      setOptions(teamsOptions);
      setSelectedTeam(teamsOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLoggedUser]);

  const { from, to } = useMemoizedFromToTimeRange(currentDateSelected, mode);

  React.useEffect(() => {
    if (selectedTeam?.value) {
      dispatch(
        getTeamTimeGrouped({
          teamId: selectedTeam.value,
          fromDate: formatDate(isWeekly ? from : from.startOf('week')),
          toDate: formatDate(isWeekly ? to : to.endOf('week')),
          groupingPeriod: isWeekly ? 'DAY' : 'WEEK',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, mode, selectedTeam]);

  return (
    //TODO for future iterations: add functionality to Export button
    <>
      <div className="teamTimeTracker-header">
        <div>
          {options.length > 1 ? (
            <SelectCustom
              options={options}
              value={selectedTeam}
              id="teamTimeTracker-select"
              className="teamTimeTracker-select"
              label=""
              onChange={setSelectedTeam}
              noBorder
            />
          ) : (
            <HeadingWithBackButton showBackButton={false}>
              {options[0]?.label || ''}
            </HeadingWithBackButton>
          )}
        </div>
        <div className="teamTimeTracker-header-actions">
          <Link
            target="_blank"
            href="https://industriatech.teamwork.com/app/home/projects"
            className="teamTimeTracker-primary-button link-as-button link-as-button-primary"
          >
            Open Teamwork
          </Link>
        </div>
      </div>
      <DividerCustom />
      <div className="teamTimeTracker-calendar-options">
        <TimeRangePicker date={currentDateSelected} mode={mode} onModeChange={setMode} />{' '}
      </div>
      <div className="teamTimeTracker-calendar">
        {isWeekly ? (
          <TeamTimeTrackerWeekly from={from} groupedData={teamMembersTime} />
        ) : (
          <TeamTimeTrackerMonthly from={from} groupedData={teamMembersTime} />
        )}
      </div>{' '}
    </>
  );
};

export default TeamTimeTrackerTab;
