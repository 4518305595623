import * as React from 'react';
import { Helmet } from 'react-helmet';
import { uid } from 'uid';
import PersonBox from './PersonBox';
import Container from '../../Components/UI/Container/Container';
import PaginationCustom from '../../Components/PaginationCustom/PaginationCustom';
import SelectCustom from '../../Components/UI/SelectCustom/SelectCustom';
import { appTitle } from '../../Helpers/constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { usersList } from '../../redux/reducers/user/userSelectors';
import { getUsers } from '../../redux/reducers/user/userApi';
import './PeoplePage.scss';

const PeoplePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const peopleList = useAppSelector(usersList);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [usersPerPage, setUsersPerPage] = React.useState(12);

  React.useEffect(() => {
    const offset = (currentPage - 1) * usersPerPage;
    dispatch(getUsers({ usersPerPage, offset }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersPerPage, currentPage]);

  return (
    <>
      <Helmet>
        <title>People | {appTitle}</title>
      </Helmet>
      <Container className="peoplePage-root">
        <div className="people-header">
          <SelectCustom
            className="people-per-page-selector"
            label="People per page"
            id="people-per-page"
            value={{ label: usersPerPage, value: usersPerPage }}
            options={[
              { label: '9', value: '9' },
              { label: '12', value: '12' },
              { label: '15', value: '15' },
            ]}
            onChange={setUsersPerPage}
          ></SelectCustom>
        </div>
        <ul className="people-list">
          {peopleList?.data.map((person) => (
            <PersonBox person={person} key={uid()} />
          ))}
        </ul>
        <PaginationCustom
          pageSize={usersPerPage}
          current={currentPage}
          onChange={setCurrentPage}
          total={peopleList?.totalElements || 0}
        />
      </Container>
    </>
  );
};

export default PeoplePage;
