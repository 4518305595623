/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { TeamState } from './teamInterface';
import { getTeamTime, getTeamTimeGrouped, getTeam } from './teamApi';

const initialState: TeamState = {
  id: null,
  name: null,
  membersTimeEntries: [],
  membersTimeEntriesGrouped: [],
  teams: [],
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      ///TODO: add loading and error
      .addCase(getTeamTime.pending, (state) => {
        state = {
          id: null,
          name: null,
          membersTimeEntries: [],
          membersTimeEntriesGrouped: [],
        };
      })
      .addCase(getTeamTime.rejected, (state) => {
        state = {
          id: null,
          name: null,
          membersTimeEntries: [],
          membersTimeEntriesGrouped: [],
        };
      })
      .addCase(getTeamTime.fulfilled, (state, action) => {
        state.membersTimeEntries = action.payload.membersTimeEntries;
      })
      .addCase(getTeamTimeGrouped.pending, (state) => {
        state = {
          id: null,
          name: null,
          membersTimeEntries: [],
          membersTimeEntriesGrouped: [],
        };
      })
      .addCase(getTeamTimeGrouped.rejected, (state) => {
        state = {
          id: null,
          name: null,
          membersTimeEntries: [],
          membersTimeEntriesGrouped: [],
        };
      })
      .addCase(getTeamTimeGrouped.fulfilled, (state, action) => {
        state.membersTimeEntriesGrouped = action.payload.membersTimeEntries;
      })
      .addCase(getTeam.pending, (state) => {
        state = {
          id: null,
          name: null,
          membersTimeEntries: [],
          membersTimeEntriesGrouped: [],
          teams: [],
        };
      })
      .addCase(getTeam.rejected, (state) => {
        state = {
          id: null,
          name: null,
          membersTimeEntries: [],
          membersTimeEntriesGrouped: [],
          teams: [],
        };
      })
      .addCase(getTeam.fulfilled, (state, action) => {
        state.teams = action.payload.teams;
      });
  },
});

export default teamSlice.reducer;
