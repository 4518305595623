import { createAsyncThunk } from '@reduxjs/toolkit';
import { MainApi } from './../../../axios-instance';
import { TimeArgs } from './teamInterface';

export const getTeamTime = createAsyncThunk(
  'user/getTeamTime',
  async (timeParameterArgs: TimeArgs) => {
    const { teamId, fromDate, toDate } = timeParameterArgs;
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/teams/${teamId}/times?fromDate=${fromDate}&toDate=${toDate}`,
    });
    return response.data;
  },
);

export const getTeamTimeGrouped = createAsyncThunk(
  'user/getTeamTimeGrouped',
  async (timeParameterArgs: Required<TimeArgs>) => {
    const { teamId, fromDate, toDate, groupingPeriod } = timeParameterArgs;
    const api = new MainApi();
    const instance = api.getInstance();
    const response = await instance({
      method: 'GET',
      url: `/api/v1/teams/${teamId}/times/grouped?fromDate=${fromDate}&toDate=${toDate}&groupingPeriod=${groupingPeriod}`,
    });
    return response.data;
  },
);

export const getTeam = createAsyncThunk('user/getTeam', async () => {
  const api = new MainApi();
  const instance = api.getInstance();
  const response = await instance({
    method: 'GET',
    url: `/api/v1/teams?includeUsers=true`,
  });
  return response.data;
});
