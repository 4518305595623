/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { UserState, Education, WorkExperience } from './userInterface';
import {
  addUserEducation,
  getEducationList,
  getExperience,
  getLoggedUser,
  getSelectedUser,
  getUsers,
  updateUserProfile,
  addUserWorkExperience,
  updateUserSocialMedia,
  updateUserEducation,
  updateUserExperience,
  deleteUserEducation,
  deleteUserExperience,
  updateUserTeamworkApiKey,
  getUserCustomTime,
  getUserDailyTime,
  getUserProjectTime,
  getUserBillableTime,
  getUserProjects,
  getUserTokensTransactionHistory,
  updateUserSkills,
  updateUserTechnologies,
  getUserTokensBalance,
  rewardUser,
  getAllDataForLoggedUser,
  getUsersByTeamId,
  getApprovalRequests,
  getSkillRequests,
  getTechnologyRequests,
  getSocialMediaRequests,
  getEducationRequests,
  getExperienceRequests,
  updateTechnologyRequestStatus,
  updateSkillRequestStatus,
  updateExperienceRequestStatus,
  updateSocialMediaRequestStatus,
  updateEducationRequestStatus,
  sendFeedback,
} from './userApi';

const initialState: UserState = {
  isLoggedIn: null,
  initialUrl: window.location.pathname,
  loggedUser: null,
  selectedUser: null,
  usersList: null,
  selectedUserEducation: [], // is part of selectedUser
  selectedUserExperience: [], // is part of selectedUser
  // selectedUserTechnology: [], // is part of selectedUser
  selectedUserTime: { today: [], timeByBillable: [], timeByProject: [], customTime: [] }, // is part of selectedUser
  approvalRequests: [],
  usersByTeamId: null,
  skillRequests: [], // is part of selectedUser
  technologyRequests: [], // is part of selectedUser
  socialMediaRequests: [], // is part of selectedUser
  educationRequests: [], // is part of selectedUser
  experienceRequests: [], // is part of selectedUser
  projects: {
    hasNext: false,
    totalElements: 0,
    totalPages: 0,
    number: 0,
    numberOfElements: 0,
    data: [],
  }, // is part of selectedUser
  tokensTransactionHistory: {
    hasNext: false,
    totalElements: 0,
    totalPages: 0,
    number: 0,
    numberOfElements: 0,
    data: [],
  }, // is part of selectedUser
  tokensBalance: [],
  formSuccess: false,
  isPending: false,
  error: null,
};

/// TODO: Refactor into different slices

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // logout: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.isLoggedIn = false;
    // },
  },
  extraReducers: (builder) => {
    builder
      /// GET
      ///getUsers
      .addCase(getUsers.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.usersList = null;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.usersList = null;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.usersList = action.payload;
      })
      ///getUsersByTeamId
      .addCase(getUsersByTeamId.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.usersByTeamId = null;
      })
      .addCase(getUsersByTeamId.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.usersByTeamId = null;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getUsersByTeamId.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.usersByTeamId = action.payload;
      })
      ///getApprovalRequestsByUserId
      .addCase(getApprovalRequests.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.approvalRequests = [];
      })
      .addCase(getApprovalRequests.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.approvalRequests = [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getApprovalRequests.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.approvalRequests = action.payload.requests;
      })
      ///getSkillRequests
      .addCase(getSkillRequests.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.skillRequests = [];
      })
      .addCase(getSkillRequests.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.skillRequests = [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getSkillRequests.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.skillRequests = action.payload.requests;
      })
      ///getTechnologyRequests
      .addCase(getTechnologyRequests.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.technologyRequests = [];
      })
      .addCase(getTechnologyRequests.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.technologyRequests = [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getTechnologyRequests.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.technologyRequests = action.payload.requests;
      })
      ///getSocialMediaRequests
      .addCase(getSocialMediaRequests.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.socialMediaRequests = [];
      })
      .addCase(getSocialMediaRequests.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.socialMediaRequests = [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getSocialMediaRequests.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.socialMediaRequests = action.payload.requests;
      })
      ///getEducationRequests
      .addCase(getEducationRequests.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.educationRequests = [];
      })
      .addCase(getEducationRequests.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.educationRequests = [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getEducationRequests.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.educationRequests = action.payload.requests;
      })
      ///getExperienceRequests
      .addCase(getExperienceRequests.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.experienceRequests = [];
      })
      .addCase(getExperienceRequests.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.experienceRequests = [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getExperienceRequests.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.experienceRequests = action.payload.requests;
      })
      /// getLoggedUser
      .addCase(getLoggedUser.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.loggedUser = null;
        state.isLoggedIn = null;
      })
      .addCase(getLoggedUser.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.loggedUser = null;
        state.isLoggedIn = false;
      })
      .addCase(getLoggedUser.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.loggedUser = action.payload;
        state.isLoggedIn = true;
      })
      /// getAllDataForLoggedUser
      .addCase(getAllDataForLoggedUser.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(getAllDataForLoggedUser.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getAllDataForLoggedUser.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.loggedUser = action.payload;
      })
      /// getSelectedUser
      .addCase(getSelectedUser.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.selectedUser = null;
      })
      .addCase(getSelectedUser.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.selectedUser = null;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getSelectedUser.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.selectedUser = action.payload;
      })
      /// getEducationList
      .addCase(getEducationList.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.selectedUserEducation = [];
      })
      .addCase(getEducationList.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.selectedUserEducation = [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getEducationList.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.selectedUserEducation = action.payload.education;
      })
      /// getExperience
      .addCase(getExperience.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.selectedUserExperience = [];
      })
      .addCase(getExperience.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
        state.selectedUserExperience = [];
      })
      .addCase(getExperience.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.selectedUserExperience = action.payload.experience;
      })
      /// getUserDailyTime
      .addCase(getUserDailyTime.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.selectedUserTime.today = [];
      })
      .addCase(getUserDailyTime.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.selectedUserTime.today = [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getUserDailyTime.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.selectedUserTime.today = action.payload.timeEntries;
      })
      /// getUserCustomTime
      .addCase(getUserCustomTime.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.selectedUserTime.customTime = [];
      })
      .addCase(getUserCustomTime.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.selectedUserTime.customTime = [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getUserCustomTime.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.selectedUserTime.customTime = action.payload.timeEntries;
      })
      /// getUserProjectTime
      .addCase(getUserProjectTime.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.selectedUserTime.timeByProject = [];
      })
      .addCase(getUserProjectTime.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.selectedUserTime.timeByProject = [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getUserProjectTime.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.selectedUserTime.timeByProject = action.payload.projects;
      })
      /// getUserBillableTime
      .addCase(getUserBillableTime.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.selectedUserTime.timeByBillable = [];
      })
      .addCase(getUserBillableTime.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.selectedUserTime.timeByBillable = [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getUserBillableTime.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.selectedUserTime.timeByBillable = action.payload.projects;
      })
      /// getUserProjects
      .addCase(getUserProjects.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(getUserProjects.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getUserProjects.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.projects = action.payload;
      })
      /// getUserTokensTransactionHistory
      .addCase(getUserTokensTransactionHistory.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.tokensTransactionHistory = state.tokensTransactionHistory || null;
      })
      .addCase(getUserTokensTransactionHistory.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.tokensTransactionHistory = state.tokensTransactionHistory || null;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getUserTokensTransactionHistory.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.tokensTransactionHistory = action.payload;
      })
      /// getUserTokensBalance
      .addCase(getUserTokensBalance.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
        state.tokensBalance = state.tokensBalance || [];
      })
      .addCase(getUserTokensBalance.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        state.tokensBalance = state.tokensBalance || [];
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getUserTokensBalance.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.tokensBalance = action.payload.balanceForTokens;
      })

      /// POST
      /// addUserEducation
      .addCase(addUserEducation.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(addUserEducation.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(addUserEducation.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        state.selectedUserEducation = [...state.selectedUserEducation, action.payload];
      })
      /// addUserExperience
      .addCase(addUserWorkExperience.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(addUserWorkExperience.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(addUserWorkExperience.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        state.selectedUserExperience = [...state.selectedUserExperience, action.payload];
      })
      /// Approve/Decline Technology
      .addCase(updateTechnologyRequestStatus.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateTechnologyRequestStatus.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateTechnologyRequestStatus.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        if (state.technologyRequests) {
          const technologyToupdate = state.technologyRequests.find(
            (technologyRequest) => technologyRequest.id === action.payload.id,
          );
          if (technologyToupdate) {
            technologyToupdate.status = action.payload.status;
          }
        }
      })
      /// Approve/Decline Skill
      .addCase(updateSkillRequestStatus.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateSkillRequestStatus.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateSkillRequestStatus.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        if (state.skillRequests) {
          const skillToupdate = state.skillRequests.find(
            (skillRequest) => skillRequest.id === action.payload.id,
          );
          if (skillToupdate) {
            skillToupdate.status = action.payload.status;
          }
        }
      })
      /// Approve/Decline Experience
      .addCase(updateExperienceRequestStatus.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateExperienceRequestStatus.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateExperienceRequestStatus.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        if (state.experienceRequests) {
          const experienceToupdate = state.experienceRequests.find(
            (experienceRequest) => experienceRequest.id === action.payload.id,
          );
          if (experienceToupdate) {
            experienceToupdate.status = action.payload.status;
          }
        }
      })
      /// Approve/Decline SocialMedia
      .addCase(updateSocialMediaRequestStatus.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateSocialMediaRequestStatus.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateSocialMediaRequestStatus.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        if (state.socialMediaRequests) {
          const socialMediaToupdate = state.socialMediaRequests.find(
            (socialMediaRequest) => socialMediaRequest.id === action.payload.id,
          );
          if (socialMediaToupdate) {
            socialMediaToupdate.status = action.payload.status;
          }
        }
      })
      /// Approve/Decline Education
      .addCase(updateEducationRequestStatus.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateEducationRequestStatus.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateEducationRequestStatus.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        if (state.educationRequests) {
          const educationToupdate = state.educationRequests.find(
            (educationRequest) => educationRequest.id === action.payload.id,
          );
          if (educationToupdate) {
            educationToupdate.status = action.payload.status;
          }
        }
      })
      /// rewardUser
      .addCase(rewardUser.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(rewardUser.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(rewardUser.fulfilled, (state) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
      })

      /// PUT
      /// updateUserSocialMedia
      .addCase(updateUserSocialMedia.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateUserSocialMedia.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateUserSocialMedia.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        if (state.selectedUser) {
          state.selectedUser.socialMedia = [...action.payload.socialMedia];
        }
      })
      /// updateUserTechnologies
      .addCase(updateUserTechnologies.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateUserTechnologies.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateUserTechnologies.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        if (state.selectedUser) {
          state.selectedUser.technologies = [...action.payload.technologies];
        }
      })
      /// updateUserProfile
      .addCase(updateUserProfile.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        if (state.selectedUser) {
          state.selectedUser = { ...state.selectedUser, ...action.payload };
        }
      })
      /// updateUserSkills
      .addCase(updateUserSkills.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateUserSkills.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateUserSkills.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        if (state.selectedUser) {
          state.selectedUser.skills = [...state.selectedUser.skills, ...action.payload.skills];
        }
      })
      /// updateUserEducation
      .addCase(updateUserEducation.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateUserEducation.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateUserEducation.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        state.selectedUserEducation = [...state.selectedUserEducation].map((item: Education) =>
          item.id === action.payload.id ? action.payload : item,
        );
      })
      /// updateUserExperience
      .addCase(updateUserExperience.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateUserExperience.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateUserExperience.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        state.selectedUserExperience = [...state.selectedUserExperience].map(
          (item: WorkExperience) => (item.id === action.payload.id ? action.payload : item),
        );
      })
      /// updateUserTeamworkApiKey
      .addCase(updateUserTeamworkApiKey.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(updateUserTeamworkApiKey.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(updateUserTeamworkApiKey.fulfilled, (state, action) => {
        state.formSuccess = true;
        state.isPending = false;
        state.error = null;
        if (state.loggedUser) {
          state.loggedUser.hasTeamworkApiKey = action.payload.hasTeamworkApiKey;
        }
      })
      /// DELETE
      /// deleteUserEducation
      .addCase(deleteUserEducation.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(deleteUserEducation.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(deleteUserEducation.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.selectedUserEducation = [...state.selectedUserEducation].filter(
          (item: Education) => item.id !== action.payload,
        );
      })
      /// deleteUserExperience
      .addCase(deleteUserExperience.pending, (state) => {
        state.formSuccess = false;
        state.isPending = true;
        state.error = null;
      })
      .addCase(deleteUserExperience.rejected, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = action.error.message;
        if (action.error.message?.includes('401')) {
          state.isLoggedIn = false;
        }
      })
      .addCase(deleteUserExperience.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.isPending = false;
        state.error = null;
        state.selectedUserExperience = [...state.selectedUserExperience].filter(
          (item: WorkExperience) => item.id !== action.payload,
        );
      })
      .addCase(sendFeedback.rejected, (state, action) => {
        state.formSuccess = false;
        state.error = action.error.message;
      })
      .addCase(sendFeedback.fulfilled, (state, action) => {
        state.formSuccess = false;
        state.error = null;
      });
  },
});
// export const { logout } = userSlice.actions;

export default userSlice.reducer;
