import * as React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import './userProfileEmptySectionPrompt.scss';

export type Props = {
  section: string;
  emptySectionLinkPath: string;
};

const { Text } = Typography;

const UserProfileEmptySectionPrompt: React.FC<Props> = ({ section, emptySectionLinkPath }) => {
  return (
    <div className="userProfileEmptySectionPrompt-root">
      <Text className="empty-section-prompt">
        It looks like you haven't added {section}. Would you like to do that now?
      </Text>
      <Link className="empty-section-prompt-link" to={emptySectionLinkPath}>
        Edit {section}
      </Link>
    </div>
  );
};

export default UserProfileEmptySectionPrompt;
