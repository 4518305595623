import * as React from 'react';
import ModalCustom from '../../UI/ModalCustom/ModalCustom';
import TextArea from '../../UI/TextArea/TextArea';
import TagsSelect from '../../UI/TagsSelect/TagsSelect';
import useInputField from '../../../hooks/useInputField';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { UserProfileArgs } from '../../../redux/reducers/user/userInterface';
import { selectedUser } from '../../../redux/reducers/user/userSelectors';
import { updateUserProfile } from '../../../redux/reducers/user/userApi';
import WelcomeFormImage from '../../../assets/images/welcome-form.png';
import './WelcomeForm.scss';

export type Props = {
  open?: boolean;
  onCancel: () => void;
  onOk: () => void;
};

/// TODO: add profile picture functionality

const WelcomeForm: React.FC<Props> = ({ open = false, onCancel, onOk }) => {
  const currentSelectedUser = useAppSelector(selectedUser);
  const dispatch = useAppDispatch();

  const biography = useInputField(currentSelectedUser?.biography || '', true);
  const hobbies = useInputField(
    currentSelectedUser?.hobbies.filter((hobby) => hobby !== '') || [],
    true,
  );

  const setToDefaults = () => {
    biography.reset();
    hobbies.reset();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(setToDefaults, [currentSelectedUser]);

  const submitHandler = () => {
    if (currentSelectedUser) {
      const updatedInfoSelectedUser: UserProfileArgs | null = currentSelectedUser && {
        ...currentSelectedUser,
        currentUserId: currentSelectedUser.id,
        hobbies: hobbies.value,
        biography: biography.value,
      };
      updatedInfoSelectedUser && dispatch(updateUserProfile(updatedInfoSelectedUser));
      onOk();
    }
  };

  const cancelHandler = () => {
    setToDefaults();
    onCancel();
  };

  return (
    <ModalCustom
      form
      open={open}
      onCancel={cancelHandler}
      onOk={submitHandler}
      okText="Save and Continue"
      cancelText="Skip"
    >
      <div className="welcomeForm-header ">
        <div>
          <h2 className="modal-heading">
            Welcome{currentSelectedUser?.firstName ? `, ${currentSelectedUser?.firstName}` : ''}!
          </h2>
          <p className="modal-subheading">
            Share some interesting facts about yourself. No pressure - whatever feels comfortable.
          </p>
        </div>
        <img className="welcomeForm-image" src={WelcomeFormImage} alt=" " />
      </div>
      <TextArea
        id="biography"
        label="Bio"
        maxLength={500}
        placeholder="Introduce yourself to the team"
        value={biography.value}
        onChange={biography.onChange}
      />
      <TagsSelect
        id="hobbies"
        label="Hobbies"
        placeholder="Enter your hobbies"
        values={hobbies.value}
        onChange={hobbies.onChange}
      />
    </ModalCustom>
  );
};

export default WelcomeForm;
