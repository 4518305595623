import * as React from 'react';
import { useParams } from 'react-router-dom';
import TimeSummary from '../TimeSummary';
import TeamTimeTrackerWeekly from '../TeamTimeTrackerTab/TeamTimeTrackerWeekly';
import TeamTimeTrackerMonthly from '../TeamTimeTrackerTab/TeamTimeTrackerMonthly';
import TimeRangePicker from '../../../Components/TimeRangePicker/TimeRangePicker';
import HeadingWithBackButton from '../../../Components/HeadingWithBackButton/HeadingWithBackButton';
import EmptyState from '../../../Components/EmptyState/EmptyState';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import Container from '../../../Components/UI/Container/Container';
import { CalendarModes } from '../../../Helpers/generalTypes';
import { formatDate } from '../../../Helpers/helperFunctions';
import useMemoizedFromToTimeRange from '../../../hooks/useMemoizedFromToTimeRange';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getSelectedUser, getUserCustomTime } from '../../../redux/reducers/user/userApi';
import { selectedUser, selectedUserTime } from '../../../redux/reducers/user/userSelectors';
import { TimeEntry } from '../../../redux/sharedInterface';
import { TeamMember } from '../../../redux/reducers/team/teamInterface';
import './DetailsReview.scss';
import { currentChosenDate } from '../../../redux/reducers/global/globalSelectors';

const DetailsReview: React.FC = () => {
  const { userId } = useParams();

  const dispatch = useAppDispatch();
  const currentSelectedUser = useAppSelector(selectedUser);
  const currentSelectedUserTime = useAppSelector(selectedUserTime).customTime;

  const [mode, setMode] = React.useState<CalendarModes>('week');
  const currentDateSelected = useAppSelector(currentChosenDate);
  const { from, to } = useMemoizedFromToTimeRange(currentDateSelected, mode);

  const user: TeamMember<TimeEntry> = {
    id: currentSelectedUser?.id || '',
    firstName: currentSelectedUser?.firstName || '',
    lastName: currentSelectedUser?.lastName || '',
    jobRole: currentSelectedUser?.jobRole || '',
    profilePicture: currentSelectedUser?.profilePicture || '',
    timeEntries: currentSelectedUserTime,
  };

  React.useEffect(() => {
    if (userId) {
      dispatch(getSelectedUser(userId));
      dispatch(
        getUserCustomTime({
          userId,
          fromDate: formatDate(mode === 'week' ? from : from.startOf('week')),
          toDate: formatDate(mode === 'week' ? to : to.endOf('week')),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, from, mode, to]);

  return (
    <Container className="detailsReview-root">
      <div className="detailsReview-header">
        <HeadingWithBackButton showBackButton>Details review</HeadingWithBackButton>
      </div>
      <DividerCustom />
      <TimeRangePicker
        className="mb-8"
        date={currentDateSelected}
        mode={mode}
        onModeChange={setMode}
      />
      {user.timeEntries.length ? (
        mode === 'week' ? (
          <TeamTimeTrackerWeekly from={currentDateSelected} data={[user]} singlePerson />
        ) : (
          <TeamTimeTrackerMonthly from={currentDateSelected} data={[user]} singlePerson />
        )
      ) : (
        <EmptyState description="No time logged for selected time period." />
      )}
      <TimeSummary
        breakdownTitle="Details from time"
        className="mt-5"
        from={currentDateSelected}
        mode={mode}
        time={currentSelectedUserTime}
        user={currentSelectedUser || undefined}
      />
    </Container>
  );
};

export default DetailsReview;
