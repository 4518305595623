import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import teamReducer from './reducers/team/teamSlice';
import userReducer from './reducers/user/userSlice';
import globalReducer from './reducers/global/globalSlice';

export const store = configureStore({
  reducer: {
    team: teamReducer,
    user: userReducer,
    global: globalReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
// stat obj
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
