import * as React from 'react';
import { Typography } from 'antd';
import { uid } from 'uid';
import IconCustom from '../IconCustom/IconCustom';
import { SocialMedia } from '../../redux/reducers/user/userInterface';

export type Props = {
  socialMediaLinks: Array<SocialMedia>;
  resume?: string;
};

const { Text, Link } = Typography;

const UserProfileLinks: React.FC<Props> = ({ socialMediaLinks, resume }) => {
  // TODO: Add resume
  return (
    <div className="user-links-container">
      {socialMediaLinks.map((socialMediaLink) => {
        return (
          <div className="user-single-link" key={uid()}>
            <Text className="social-media-label">{socialMediaLink.name}</Text>
            <Link className="user-link" href={socialMediaLink.url} target="_blank">
              {socialMediaLink.url} <IconCustom color="sky-400" icon="MdArrowOutward" />
            </Link>
          </div>
        );
      })}
      {resume && (
        <div className="user-single-link">
          <Text className="social-media-label">{resume}</Text>
          <Link className="user-link" href={resume}>
            {resume} <IconCustom color="sky-400" icon="MdArrowOutward" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default UserProfileLinks;
