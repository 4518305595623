import * as React from 'react';
import AvatarCustom from '../AvatarCustom/AvatarCustom';
import SelectCustom from '../UI/SelectCustom/SelectCustom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { loggedUser, usersList } from '../../redux/reducers/user/userSelectors';
import { getUsers } from '../../redux/reducers/user/userApi';
import { ExtendedUser } from '../../redux/reducers/user/userInterface';
import './userPicker.scss';

export type Props = {
  value: string | undefined;
  className?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  required?: boolean;
  onChange: (value: string) => void;
};

const UserPicker: React.FC<Props> = ({
  className = '',
  disabled,
  label,
  placeholder,
  required,
  value,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const usersData = useAppSelector(usersList);
  const currentLoggedUser = useAppSelector(loggedUser);
  const allUsers = usersData?.data || [];

  React.useEffect(() => {
    dispatch(getUsers({ usersPerPage: 100, offset: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOptionElementContent = (user?: ExtendedUser) =>
    user ? (
      <div className="userPicker-option">
        <AvatarCustom
          size="sm"
          imageSrc={user.profilePicture}
          fallbackText={`${user.firstName[0]}${user.lastName[0]}`}
        />{' '}
        <div className="userPicker-option-data">
          <p className="userPicker-option-name">
            {user.firstName} {user.lastName}
          </p>
          <p className="userPicker-option-email"> {user.email}</p>
        </div>
      </div>
    ) : null;

  const getPreselectedUser = (id?: string) => allUsers.find((user) => user.id === id);

  const optionsItems = allUsers
    .filter((user) => user.id !== currentLoggedUser?.id)
    .map((user) => {
      return { value: user.id, label: getOptionElementContent(user) };
    });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterOption = (input: string, option: any) => {
    const filteredUsers = allUsers.filter((user) =>
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(input.toLowerCase()),
    );
    return !!filteredUsers.find((user) => user.id === option.value);
  };

  return (
    <SelectCustom
      showSearch
      id="sendTo"
      required={required}
      showArrow={false}
      className={`userPicker-root ${className}`}
      label={label || ''}
      placeholder={placeholder}
      disabled={disabled}
      value={{ value, label: getOptionElementContent(getPreselectedUser(value)) }}
      options={optionsItems}
      onChange={onChange}
      filterOption={filterOption}
    />
  );
};

export default UserPicker;
