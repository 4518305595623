/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import InputCustom from '../../../../Components/UI/InputCustom/InputCustom';
import CheckboxCustom from '../../../../Components/CheckboxCustom/CheckboxCustom';
import TextArea from '../../../../Components/UI/TextArea/TextArea';
import ModalCustom from '../../../../Components/UI/ModalCustom/ModalCustom';

import useInputField from '../../../../hooks/useInputField';
import { formatDate } from '../../../../Helpers/helperFunctions';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { Education } from '../../../../redux/reducers/user/userInterface';
import { addUserEducation, updateUserEducation } from '../../../../redux/reducers/user/userApi';
import { isPending } from '../../../../redux/reducers/user/userSelectors';

type Props = {
  educationItem?: Education;
  open?: boolean;
  userId?: string;
  onCancel: () => void;
  onOk: ([arg]?: any) => void;
};

const EducationForm: React.FC<Props> = ({
  educationItem,
  open = false,
  userId,
  onCancel,
  onOk,
}) => {
  const dispatch = useAppDispatch();
  const appIsLoading = useAppSelector(isPending);

  const defaultOngoingEducation = !educationItem?.endDate;
  const institution = useInputField(educationItem?.institution || '');
  const degree = useInputField(educationItem?.degree || '');
  const fieldOfStudy = useInputField(educationItem?.fieldOfStudy || '');
  const startDate = useInputField(
    educationItem ? new Date(educationItem.startDate) : new Date(),
    true,
  );
  const endDate = useInputField(
    educationItem && educationItem.endDate ? new Date(educationItem.endDate) : null,
    true,
  );
  const description = useInputField(educationItem?.description || '', true);
  const [isOngoingEducation, setIsOngoingEducation] = React.useState(defaultOngoingEducation);

  const ongoingEducationChangeHandler = (e: CheckboxChangeEvent) => {
    endDate.setValueManually(null);
    setIsOngoingEducation(e.target.checked);
  };

  const buttonsAreActive = React.useMemo(
    () =>
      institution.dirty || degree.dirty || fieldOfStudy.dirty || startDate.dirty || endDate.dirty,
    [institution.dirty, degree.dirty, fieldOfStudy.dirty, startDate.dirty, endDate.dirty],
  );

  const submitHandler = () => {
    const education: Education = {
      startDate: formatDate(startDate.value, 'YYYY'),
      endDate: isOngoingEducation ? null : formatDate(endDate.value as Date, 'YYYY'),
      institution: institution.value,
      degree: degree.value,
      fieldOfStudy: fieldOfStudy.value,
      description: description.value,
      logo: 'https://via.placeholder.com/150.png?text=TU', //TODO: use real logo
    };
    if (userId) {
      if (educationItem && educationItem.id) {
        dispatch(
          updateUserEducation({
            currentUserId: +userId,
            currentEducationId: educationItem.id,
            education,
          }),
        );
      } else {
        dispatch(addUserEducation({ currentUserId: +userId, education }));
      }
    }
    onOk();
  };

  const cancelHandler = () => {
    institution.reset();
    degree.reset();
    fieldOfStudy.reset();
    startDate.reset();
    endDate.reset();
    description.reset();
    setIsOngoingEducation(defaultOngoingEducation);
  };

  const closeHandler = () => {
    cancelHandler();
    onCancel();
  };

  const canSubmitForm = React.useMemo(
    () =>
      institution.value &&
      degree.value &&
      fieldOfStudy.value &&
      startDate.value &&
      (isOngoingEducation || endDate.value),
    [
      institution.value,
      degree.value,
      fieldOfStudy.value,
      startDate.value,
      endDate.value,
      isOngoingEducation,
    ],
  );
  const canCancelForm = React.useMemo(
    () => institution.value || degree.value || fieldOfStudy.value,
    [institution.value, degree.value, fieldOfStudy.value],
  );

  return (
    <ModalCustom
      form
      withHeader
      formClassName="modal-form-grid gap-y-7"
      title={`${educationItem ? 'Edit' : 'Add'} Education`}
      okText="Send for approval"
      cancelText="Cancel"
      open={open}
      okButtonDisabled={!buttonsAreActive || !canSubmitForm}
      okButtonLoading={appIsLoading}
      cancelButtonDisabled={!buttonsAreActive || !canCancelForm}
      onCancelButton={cancelHandler}
      onCancel={closeHandler}
      onOk={submitHandler}
    >
      <InputCustom
        required
        id="institution"
        label="Institution"
        placeholder="Ex. University of Oxford"
        errorMessage="Please enter an institution."
        value={institution.value}
        onChange={institution.onChange}
      />
      <InputCustom
        required
        id="degree"
        label="Degree"
        placeholder="Ex. Bachelor"
        tooltip="lorem ipsum"
        errorMessage="Please enter a degree."
        value={degree.value}
        onChange={degree.onChange}
      />
      <InputCustom
        required
        id="field-of-study"
        label="Field of study"
        className="col-span-full"
        placeholder="Enter field of study"
        tooltip="lorem ipsum"
        iconLeft="MdBook"
        errorMessage="Please enter a field of study."
        value={fieldOfStudy.value}
        onChange={fieldOfStudy.onChange}
      />
      <InputCustom
        required
        type="date"
        id="start-date"
        label="Start date"
        placeholder="Choose start date"
        errorMessage="Please select a start date."
        value={startDate.value}
        onChange={startDate.onChange}
      />
      <InputCustom
        required={!isOngoingEducation}
        disabled={isOngoingEducation}
        id="end-date"
        type="date"
        label="End date"
        placeholder="Choose end date"
        errorMessage="Please select an end date."
        minDate={startDate.value}
        value={endDate.value}
        onChange={endDate.onChange}
      />
      <CheckboxCustom
        className="col-span-full"
        label="I still go here"
        checked={isOngoingEducation}
        onChange={ongoingEducationChangeHandler}
      />
      <TextArea
        id="description"
        label="Description"
        maxLength={120}
        className="col-span-full"
        placeholder="Tell us what you studied"
        value={description.value}
        onChange={description.onChange}
      />
    </ModalCustom>
  );
};

export default EducationForm;
