/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Select } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import ReactFlagsSelect from 'react-flags-select';
import IconCustom from '../../IconCustom/IconCustom';
import Label from '../Label/Label';

import './selectCustom.scss';

interface BasicProps {
  id: string;
  label: string;
  className?: string;
  disabled?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  popupClassName?: string;
  required?: boolean;
  showArrow?: boolean;
  showSearch?: boolean;
  onChange: ([arg]?: any) => void;
}

interface PropsSelect extends BasicProps {
  value?: BaseOptionType;
  countries?: false;
  options?: Array<BaseOptionType>;
  children?: React.ReactNode;
  filterOption?: (input: string, option: any) => boolean;
}

interface PropsCountries extends BasicProps {
  value: string; //TODO: only accept countries codes
  countries: true;
  options?: never;
  children?: never;
  filterOption?: never;
}

export type Props = PropsSelect | PropsCountries;

const SelectCustom: React.FC<Props> = ({
  className = '',
  children,
  countries,
  disabled,
  id,
  label,
  noBorder = false,
  options,
  placeholder,
  popupClassName = '',
  required,
  showArrow = true,
  showSearch,
  value,
  filterOption,
  onChange,
}) => {
  const selectProps = {
    bordered: !noBorder,
    className: `w-full ${noBorder ? '' : 'border-gray-300'}`,
    suffixIcon: <IconCustom icon="MdArrowDropDown" color="gray-400" className="text-xl" />,
    value: value || undefined,
    disabled,
    id,
    options,
    placeholder,
    popupClassName,
    showArrow,
    showSearch,
    onChange,
    filterOption,
  };

  const labelProps = { id, label, required };
  return (
    <div className={`${className} selectCustom-root`}>
      <Label {...labelProps} />
      {countries ? (
        <ReactFlagsSelect
          searchable
          className="select-flags"
          placeholder={placeholder}
          selected={value as string}
          onSelect={onChange}
        />
      ) : (
        <Select size="large" placement="bottomLeft" {...selectProps}>
          {children}
        </Select>
      )}
    </div>
  );
};

export default SelectCustom;
