import * as React from 'react';
import { notification } from 'antd';
import { useAppSelector } from '../redux/hooks';
import { isPending, formSuccess } from '../redux/reducers/user/userSelectors';

const useSuccessMessage = (
  message = 'Submitted!',
  description = 'Your information has been submitted. You can view it in your profile.',
) => {
  const pending = useAppSelector(isPending);
  const formSuccessful = useAppSelector(formSuccess);

  React.useEffect(() => {
    if (!pending) {
      if (formSuccessful) {
        notification.success({ message, description });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending, formSuccessful]);

  return null;
};

export default useSuccessMessage;
