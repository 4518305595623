import * as React from 'react';
import { Empty } from 'antd';
import './emptyState.scss';

export type Props = {
  className?: string;
  description?: string;
};

const EmptyState: React.FC<Props> = ({ className, description }) => {
  return <Empty description={description} className={`emptyState-root ${className}`} />;
};

export default EmptyState;
