import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { uid } from 'uid';
import TagCustom from '../UI/TagCustom/TagCustom';
import ModalCustom from '../UI/ModalCustom/ModalCustom';
import DividerCustom from '../UI/DividerCustom/DividerCustom';
import { Technology } from '../../redux/reducers/user/userInterface';
import { presetTechnologies } from '../../redux/reducers/technologies/presetTechnologies';
import './UserTechnologies.scss';

export type Props = {
  technologies: Array<Technology>;
  isDifferentProfile?: boolean;
};

const { Text } = Typography;

const UserTechnologies: React.FC<Props> = ({ isDifferentProfile, technologies }) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = React.useState(false);

  const closeModal = () => setModalOpen(false);

  const goToSettings = () => {
    navigate('/settings');
    closeModal();
  };

  const sortedTechnologies: {
    preset: { [x: string]: Array<string> };
    custom: Array<Technology>;
  } = {
    preset: {
      Expert: [],
      Advanced: [],
      Intermediate: [],
      Novice: [],
    },
    custom: [],
  };

  technologies.forEach((tech) => {
    const presetTech = presetTechnologies.find(
      (presetTechnology) => presetTechnology.name === tech.name,
    );
    if (presetTech) {
      sortedTechnologies.preset[tech.experience] &&
        sortedTechnologies.preset[tech.experience].push(presetTech.imageSrc);
    } else {
      sortedTechnologies.custom && sortedTechnologies.custom.push(tech);
    }
  });

  const getTagsFromArrayOfStrings = (techs: Array<Technology>) =>
    techs.map((tech) => (
      <TagCustom className="user-technologies-item" key={uid()}>
        {tech.name} ({tech.experience})
      </TagCustom>
    ));

  const modalLevels: Array<React.ReactNode> = [];
  return (
    <div className="userTechnologies-root">
      <Space onClick={() => setModalOpen(true)}>
        <>
          {getTagsFromArrayOfStrings(sortedTechnologies.custom.slice(0, 3))}
          {technologies.length > 3 && (
            <Text className="user-technologies-see-more user-technologies-item">
              +{technologies.length - 3}
            </Text>
          )}
        </>
      </Space>
      <ModalCustom
        withHeader
        size="small"
        footer={isDifferentProfile ? undefined : 'regular'}
        title="Technologies"
        open={modalOpen}
        okText={isDifferentProfile ? undefined : 'Edit information'}
        onOk={isDifferentProfile ? closeModal : goToSettings}
        onCancel={closeModal}
        className="user-technologies-modal"
      >
        {modalLevels}
        {sortedTechnologies.custom.length ? (
          <div className="user-technologies-modal-level">
            <Text className="user-technologies-modal-level-label">Additional technologies</Text>
            <div className="technology-modal-level-content">
              {getTagsFromArrayOfStrings(sortedTechnologies.custom)}
            </div>
          </div>
        ) : null}
        {!isDifferentProfile && <DividerCustom className="mb-4" />}
      </ModalCustom>
    </div>
  );
};

export default UserTechnologies;
