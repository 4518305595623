import { uid } from 'uid';
import FacebookLogo from '../../../assets/svg/apps/facebook-logo.svg';
import LinkedInLogo from '../../../assets/svg/apps/linkedin-logo.svg';
import GitHubLogo from '../../../assets/svg/apps/github-logo.svg';
import PersonalBlogIcon from '../../../assets/svg/icon-your-blog.svg';
import TwitterLogo from '../../../assets/svg/apps/twitter-logo.svg';
import DribbbleLogo from '../../../assets/svg/apps/dribbble-logo.svg';

export const presetSocialMedia = [
  {
    name: 'Facebook',
    url: 'facebook.com',
    id: uid(),
    imageSrc: FacebookLogo,
    profileUrl: '',
    status: '',
  },
  {
    name: 'LinkedIn',
    url: 'linkedin.com',
    id: uid(),
    imageSrc: LinkedInLogo,
    profileUrl: '',
    status: '',
  },
  {
    name: 'GitHub',
    url: 'github.com',
    id: uid(),
    imageSrc: GitHubLogo,
    profileUrl: '',
    status: '',
  },
  {
    name: 'Personal Website/Blog',
    url: 'yoursite.com',
    id: uid(),
    imageSrc: PersonalBlogIcon,
    profileUrl: '',
    status: '',
  },
  {
    name: 'Twitter',
    url: 'twitter.com',
    id: uid(),
    imageSrc: TwitterLogo,
    profileUrl: '',
    status: '',
  },
  {
    name: 'Dribbble',
    url: 'dribbble.com',
    id: uid(),
    imageSrc: DribbbleLogo,
    profileUrl: '',
    status: '',
  },
];
