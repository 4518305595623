import React, { createElement } from 'react';

import Icon from '@ant-design/icons';
import * as AiIcons from 'react-icons/ai';
import * as MdIcons from 'react-icons/md';
import type {
  AvatarIcons,
  PropsWithIcon,
  PropsWithComponent,
  AiIcon,
  MdIcon,
} from '../../Helpers/generalTypes';
import { ReactComponent as ReceivedTokensIcon } from '../../assets/svg/icon-received-tokens.svg';
import { ReactComponent as NewIcon } from '../../assets/svg/icon-new.svg';

interface BasicProps {
  bgColor?: string;
  className?: string;
  color?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: ([arg]?: any) => any;
}

interface BasicPropsWithIcon extends BasicProps, PropsWithIcon {
  avatarIcon?: never;
}

interface BasicPropsWithAvatarIcon extends BasicProps {
  avatarIcon: AvatarIcons;
  component?: never;
  icon?: never;
}

interface BasicPropsWithComponent extends BasicProps, PropsWithComponent {
  avatarIcon?: never;
}

export type Props = BasicPropsWithIcon | BasicPropsWithAvatarIcon | BasicPropsWithComponent;

const IconCustom: React.FC<Props> = (props: Props) => {
  const { className, icon, avatarIcon, component, onClick } = props;

  const iconConfig = {
    icon,
    component,
    onClick,
    color: props.color || 'gray-900',
    bgColor: props.bgColor || 'transparent',
    extraClasses: '',
  };

  const configureAvatarIcon = () => {
    switch (avatarIcon) {
      case 'success':
        iconConfig.icon = 'MdCheckCircle';
        iconConfig.color = 'emerald-600';
        iconConfig.bgColor = 'white';
        break;
      case 'error':
        iconConfig.icon = 'MdCancel';
        iconConfig.color = 'rose-500';
        iconConfig.bgColor = 'white';
        break;
      case 'pending':
        iconConfig.component = 'MdTimelapse';
        iconConfig.color = 'blue-700';
        iconConfig.bgColor = 'white';
        break;
      case 'delete':
        iconConfig.icon = 'MdClose';
        iconConfig.bgColor = 'white';
        iconConfig.extraClasses = 'w-5 h-5 shadow-sm';
        break;
      case 'new':
        iconConfig.component = NewIcon; //TODO - icon to be changed with icon when received
        break;
      case 'remove':
        iconConfig.icon = 'MdBlockFlipped';
        iconConfig.color = 'white';
        iconConfig.bgColor = 'gray-900';
        break;
      case 'addedTechnology':
        iconConfig.icon = 'MdRadioButtonChecked';
        iconConfig.color = 'blue-700';
        iconConfig.bgColor = 'white';
        break;
      case 'addedExperience':
        iconConfig.icon = 'MdWork';
        iconConfig.color = 'amber-600';
        iconConfig.bgColor = 'white';
        break;
      case 'addedSkill':
        iconConfig.icon = 'MdStarRate';
        iconConfig.color = 'green-600';
        iconConfig.bgColor = 'white';
        break;
      case 'addedEducation':
        iconConfig.icon = 'MdBook';
        iconConfig.color = 'sky-400';
        iconConfig.bgColor = 'white';
        break;
      case 'addedSocialMedia':
        iconConfig.icon = 'MdOutlineDiversity3';
        iconConfig.color = 'violet-800';
        iconConfig.bgColor = 'white';
        break;

      case 'startedProject':
        iconConfig.icon = 'MdAssignment';
        iconConfig.color = 'fuchsia-700';
        iconConfig.bgColor = 'white';
        break;
      case 'updatedProfile':
        iconConfig.icon = 'MdAccountBox';
        iconConfig.color = 'blue-600';
        iconConfig.bgColor = 'white';
        break;
      case 'updatedPosition':
        iconConfig.icon = 'MdStarRate';
        iconConfig.color = 'sky-400';
        iconConfig.bgColor = 'white';
        break;
      case 'receivedTokens':
        iconConfig.component = ReceivedTokensIcon; //TODO - icon to be changed with icon when received
        iconConfig.bgColor = 'white';
        break;
      case 'sentTokens':
        iconConfig.icon = 'MdAccountBalanceWallet';
        iconConfig.color = 'amber-600';
        iconConfig.bgColor = 'white';
        break;
      case 'redeemedTokens':
        iconConfig.icon = 'MdAccountBalance';
        iconConfig.color = 'green-600';
        iconConfig.bgColor = 'white';
        break;
      case 'addPicture':
        iconConfig.icon = 'MdOutlinePhotoCamera';
    }
  };

  if (avatarIcon) configureAvatarIcon();

  let iconElement = null;

  if (iconConfig.icon?.startsWith('Ai')) {
    iconElement = createElement(AiIcons[iconConfig.icon as AiIcon]);
  }

  if (iconConfig.icon?.startsWith('Md')) {
    iconElement = createElement(MdIcons[iconConfig.icon as MdIcon]);
  }

  return (
    <Icon
      className={`${className} text-${iconConfig.color} bg-${iconConfig.bgColor} ${iconConfig.extraClasses}`}
      component={iconConfig.component}
      onClick={iconConfig.onClick}
    >
      {iconElement}
    </Icon>
  );
};

export default IconCustom;
