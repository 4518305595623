import { Menu, MenuProps, Typography } from 'antd';
import * as React from 'react';

// assets and styles
import { ReactComponent as Dots } from '../../assets/svg/header-menu/dots.svg';
import { ReactComponent as TeamWorkLogo } from '../../assets/svg/apps/teamwork-logo.svg';
import { ReactComponent as ConfluenceLogo } from '../../assets/svg/apps/confluence-logo.svg';
import { ReactComponent as SlackLogo } from '../../assets/svg/apps/slack-logo.svg';
import './headerMenu.scss';

// components
import IconCustom from '../IconCustom/IconCustom';
import ProfileInHeader from '../ProfileInHeader/ProfileInHeader';
import UserAvatarCustom from '../AvatarCustom/UserAvatarCustom';

const { Link } = Typography;

type MenuItem = Required<MenuProps>['items'][number];
// header menu
const getItem = (
  label: React.ReactNode, // string OR react element
  key: React.Key, // string OR null
  icon?: React.ReactNode, // icon element OR null
  children?: MenuItem[], //  array of getItem()
  type?: 'group', // reset active class and group submenus when click be
): MenuItem => {
  return {
    label,
    key,
    icon,
    children,
    type,
  } as MenuItem;
};

const menuHeaderItems: MenuItem[] = [
  getItem(<IconCustom component={Dots} className="app-icon" />, 'apps', null, [
    getItem(
      <Link
        className="headerMenu-root-app"
        href="https://industriatech.teamwork.com/"
        target="_blank"
        rel="noreferrer"
      >
        <IconCustom component={TeamWorkLogo} className="app-icon mr-3" />
        Teamwork
      </Link>,
      'Teamwork',
    ),
    getItem(
      <Link
        className="headerMenu-root-app"
        href="https://industriatech.atlassian.net"
        target="_blank"
        rel="noreferrer"
      >
        <IconCustom component={ConfluenceLogo} className="app-icon mr-3" />
        Confluence
      </Link>,
      'Confluence',
    ),
    getItem(
      <Link
        className="headerMenu-root-app"
        href="https://industriatech.slack.com/"
        target="_blank"
        rel="noreferrer"
      >
        <IconCustom component={SlackLogo} className="app-icon mr-3" />
        Slack
      </Link>,
      'Slack',
    ),
  ]),
  getItem(
    <div className="headerMenu-user">
      <UserAvatarCustom size={'xs'} className="headerMenu-avatar" />
      <IconCustom icon="MdArrowDropDown" className="userArrow text-2xl text-gray-400" />
    </div>,
    'Profile',
    null,
    [getItem(<ProfileInHeader />, 11)],
  ),
];

export type Props = {
  children?: React.ReactNode;
};

const HeaderMenu: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return (
    <div className="headerMenu-root">
      <Menu
        triggerSubMenuAction="click"
        mode="horizontal"
        items={menuHeaderItems}
        className="headerMenu"
        selectable={false}
      ></Menu>
      {children}
    </div>
  );
};

export default HeaderMenu;
