/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { VictoryChart, VictoryBar, VictoryTheme, VictoryStack, VictoryAxis } from 'victory';
import { uid } from 'uid';
import { CalendarModes } from '../../../../Helpers/generalTypes';
import {
  formatWeekString,
  formatDate,
  getAllDatesInAWeek,
  getAllWeeksInAMonth,
} from '../../../../Helpers/helperFunctions';
import { workingHoursInADay, workingHoursInAWeek } from '../../../../Helpers/constants';
import { TimeEntry } from '../../../../redux/sharedInterface';

export type Props = {
  dataBillable: Array<TimeEntry>;
  dataNonbillable: Array<TimeEntry>;
  fromDate: Dayjs;
  mode: CalendarModes;
};

const BarGraph: React.FC<Props> = ({ dataBillable, dataNonbillable, fromDate, mode }) => {
  const isWeekly = mode === 'week';

  const weekDates = getAllDatesInAWeek(fromDate);
  const weekDatesStrings = weekDates.map((dateItem) => formatDate(dateItem));

  const monthDates = getAllWeeksInAMonth(fromDate);
  const monthDatesStrings = monthDates.map((monthDate) =>
    formatWeekString(monthDate.start, monthDate.end),
  );

  const getGraphableData = (dataToGraph: Array<TimeEntry>) => {
    const mapToGraphable = (array: Array<string>) =>
      array.map((item) => {
        return { day: item, hoursDecimal: 0 };
      });
    const graphableData = mapToGraphable(isWeekly ? weekDatesStrings : monthDatesStrings);
    dataToGraph.forEach((item) => {
      if (isWeekly) {
        graphableData.forEach((date) => {
          if (date.day === item.date) {
            date.hoursDecimal += item.hoursDecimal;
          }
        });
      } else {
        const itemDate = dayjs(item.date);
        monthDates.forEach((week, index) => {
          if (itemDate > week.start && itemDate < week.end) {
            graphableData[index].hoursDecimal += item.hoursDecimal;
          }
        });
      }
    });
    return graphableData;
  };

  const graphData = [getGraphableData(dataBillable), getGraphableData(dataNonbillable)];

  const barRoundedCorners: any = {};

  const calculateCornerRadius = (barData: any) => {
    const { datum } = barData;

    if (datum._y > 0 && !(datum._group in barRoundedCorners)) {
      barRoundedCorners[datum._group] = datum._stack;
    }

    const shouldBeRounded =
      datum._group in barRoundedCorners && datum._stack === barRoundedCorners[datum._group];

    return shouldBeRounded ? 3 : 0;
  };

  const tickFormat = isWeekly ? ['M', 'T', 'W', 'T', 'F', 'S', 'S'] : monthDatesStrings;

  const sharedAxisLabelsStyles = { fontSize: 5.3, fontWeight: 600, fill: '#9ea0a4' };

  return (
    <div>
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={20}
        padding={{ top: 20, right: 0, bottom: 20, left: 20 }}
        height={130}
      >
        <VictoryAxis
          tickValues={isWeekly ? weekDatesStrings : null}
          tickFormat={tickFormat}
          style={{
            axis: { strokeWidth: 0.3 },
            grid: { strokeWidth: 0 },
            tickLabels: sharedAxisLabelsStyles,
          }}
        />
        <VictoryAxis
          dependentAxis
          domain={isWeekly ? [0, workingHoursInADay] : [0, workingHoursInAWeek]}
          style={{
            axis: { strokeWidth: 0.3 },
            tickLabels: { ...sharedAxisLabelsStyles, marginTop: 0 },
          }}
        />
        <VictoryStack colorScale={['#5b7587', '#F48E87']}>
          {graphData.map((dataItem) => (
            <VictoryBar
              barRatio={0.4}
              x="day"
              y="hoursDecimal"
              key={uid()}
              cornerRadius={{
                top: (barData) => calculateCornerRadius(barData),
              }}
              data={dataItem}
            />
          ))}
        </VictoryStack>
      </VictoryChart>
    </div>
  );
};

export default BarGraph;
