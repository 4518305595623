/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import SocialMediaForm from './SocialMediaForm/SocialMediaForm';
import SocialMediaBox from '../../../Components/SocialMediaBox/SocialMediaBox';
import DividedSection from '../../../Components/DividedSection/DividedSection';
import FormCustom from '../../../Components/FormCustom/FormCustom';
import ButtonCustom from '../../../Components/UI/ButtonCustom/ButtonCustom';
import { UISocialMedia } from '../../../Helpers/generalTypes';
import {
  addHTTPSToUrl,
  removeHTTPSFromUrl,
  findItemInArrayOfObjectsByName,
  isValidUrl,
} from '../../../Helpers/helperFunctions';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { selectedUser } from '../../../redux/reducers/user/userSelectors';
import { updateUserSocialMedia } from '../../../redux/reducers/user/userApi';
import { SocialMediaActions } from '../../../redux/reducers/socialMedia/socialMediaActions';
import { socialMediaReducer } from '../../../redux/reducers/socialMedia/socialMediaReducer';
import { SocialMedia } from '../../../redux/reducers/user/userInterface';

const EditSocialMediaTab: React.FC = () => {
  /// SETUP
  const currentSelectedUser = useAppSelector(selectedUser);
  const dispatch = useAppDispatch();

  const [socialMedia, dispatchSocialMedia] = React.useReducer(socialMediaReducer, []);
  const [customSocialMedia, dispatchCustomSocialMedia] = React.useReducer(socialMediaReducer, []);

  const setDefaultSocialMedia = () => {
    dispatchSocialMedia({
      type: SocialMediaActions.GET_SOCIAL_MEDIA,
      socialMedia: currentSelectedUser?.socialMedia,
      custom: false,
    });
    dispatchCustomSocialMedia({
      type: SocialMediaActions.GET_SOCIAL_MEDIA,
      socialMedia: currentSelectedUser?.socialMedia,
      custom: true,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(setDefaultSocialMedia, []);

  /// MODAL
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalEditItem, setModalEditItem] = React.useState<UISocialMedia | undefined>();

  React.useEffect(() => {
    if (!modalOpen) {
      setModalEditItem(undefined);
    }
  }, [modalOpen]);

  React.useEffect(() => {
    if (modalEditItem) {
      setModalOpen(true);
    }
  }, [modalEditItem]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const editHandler = (socialMediaItem: UISocialMedia) => {
    setModalEditItem(socialMediaItem);
  };

  const deleteHandler = (socialMediaItemId: string) => {
    dispatchCustomSocialMedia({
      type: SocialMediaActions.DELETE_SOCIAL_MEDIA,
      id: socialMediaItemId,
    });
  };

  const submitModalFormHandler = (e: UISocialMedia) => {
    const { name, url, profileUrl, id } = e;
    if (modalEditItem) {
      dispatchCustomSocialMedia({
        type: SocialMediaActions.EDIT_CUSTOM_SOCIAL_MEDIA,
        name,
        url,
        profileUrl,
        id,
      });
    } else if (findItemInArrayOfObjectsByName(customSocialMedia, name)) {
      // TODO: Actual message instead of alert when design is available
      alert('This social media has already been added. Did you mean to edit it instead?');
    } else if (findItemInArrayOfObjectsByName(socialMedia, name)) {
      // TODO: Actual message instead of alert when design is available
      alert(
        'This social media is available by default. Did you mean to edit your profile instead?',
      );
    } else {
      dispatchCustomSocialMedia({
        type: SocialMediaActions.ADD_CUSTOM_SOCIAL_MEDIA,
        name,
        url,
        id,
        profileUrl,
      });
    }
    closeModal();
  };

  /// GENERAL
  const submitHandler = () => {
    const allSocialMedia: Array<SocialMedia> = [...socialMedia, ...customSocialMedia]
      .map((socialMediaItem: UISocialMedia) => {
        return { name: socialMediaItem.name, url: addHTTPSToUrl(socialMediaItem.profileUrl) };
      })
      .filter((socialMediaItem) => socialMediaItem.url);
    currentSelectedUser &&
      dispatch(
        updateUserSocialMedia({
          currentUserId: currentSelectedUser.id,
          socialMedia: allSocialMedia,
        }),
      );
  };

  const cancelHandler = () => setDefaultSocialMedia();

  const generateElementsData = (socialMediaArray: Array<UISocialMedia>, custom = false) =>
    socialMediaArray.map((socialMediaItem: UISocialMedia) => {
      return {
        custom,
        id: socialMediaItem.id,
        name: socialMediaItem.name,
        invalid: !!socialMediaItem.profileUrl && !isValidUrl(socialMediaItem.profileUrl),
        imageUrl: custom ? null : socialMediaItem.imageSrc,
        url: removeHTTPSFromUrl(socialMediaItem.url),
        profileUrl: socialMediaItem.profileUrl,
        status: socialMediaItem.status,
        onChange: (e: any) => {
          const dispatchObject = {
            type: SocialMediaActions.UPDATE_SOCIAL_MEDIA_URL,
            id: socialMediaItem.id,
            profileUrl: e.target.value,
          };
          if (custom) {
            dispatchCustomSocialMedia(dispatchObject);
          } else dispatchSocialMedia(dispatchObject);
        },
        onEdit: custom
          ? () =>
              editHandler({
                name: socialMediaItem.name,
                url: socialMediaItem.url,
                id: socialMediaItem.id,
                profileUrl: socialMediaItem.profileUrl,
              })
          : null,
        onDelete: custom ? () => deleteHandler(socialMediaItem.id) : null,
      };
    });

  const generateElements = (socialMediaArray: Array<UISocialMedia>, custom = false) => {
    const data = generateElementsData(socialMediaArray, custom);
    return data.map((socialMediaElement: any) => {
      return <SocialMediaBox key={socialMediaElement.id} {...socialMediaElement} />;
    });
  };

  const socialMediaElements = socialMedia?.length ? generateElements(socialMedia) : null;
  console.log(customSocialMedia, 'custom');
  const customSocialMediaElements = customSocialMedia?.length
    ? generateElements(customSocialMedia, true)
    : null;

  const isFormInvalid =
    generateElementsData([...socialMedia, ...customSocialMedia]).filter(
      (element) => element.invalid,
    ).length > 0;

  return (
    <>
      <DividedSection title="Social Media" subtitle="Where can we find you?">
        <FormCustom onSubmit={submitHandler}>
          {socialMediaElements}
          {customSocialMediaElements?.length ? (
            <>
              <h3 className="inner-subheading">Custom added</h3>
              {customSocialMediaElements}
            </>
          ) : (
            ''
          )}
          <ButtonCustom type="text" onClick={() => setModalOpen(true)}>
            Add Additional Social Media
          </ButtonCustom>
          <div className="form-actions my-10">
            <ButtonCustom type="default" onClick={cancelHandler}>
              Cancel
            </ButtonCustom>
            <ButtonCustom disabled={isFormInvalid} type="primary" htmlType="submit">
              Send for approval
            </ButtonCustom>
          </div>
        </FormCustom>
      </DividedSection>
      {modalOpen && (
        <SocialMediaForm
          onCancel={closeModal}
          onOk={submitModalFormHandler}
          open={modalOpen}
          socialMediaItem={modalEditItem}
        />
      )}
    </>
  );
};

export default EditSocialMediaTab;
