/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import './checkboxCustom.scss';
import { Checkbox } from 'antd';

export type Props = {
  checked: boolean;
  label: string;
  className?: string;
  onChange: ([arg]?: any) => void;
  [key: string]: any;
};

const CheckboxCustom: React.FC<Props> = ({
  checked,
  className = '',
  label,
  onChange,
  ...otherProps
}) => {
  return (
    <Checkbox
      checked={checked}
      className={`checkboxCustom-root ${className}`}
      onChange={onChange}
      {...otherProps}
    >
      {label}
    </Checkbox>
  );
};

export default CheckboxCustom;
