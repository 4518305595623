import * as React from 'react';
import { uid } from 'uid';

import InputCustom from '../../../../Components/UI/InputCustom/InputCustom';
import SelectCustom from '../../../../Components/UI/SelectCustom/SelectCustom';
import ModalCustom from '../../../../Components/UI/ModalCustom/ModalCustom';
import { levels } from '../../../../Helpers/constants';
import { UITechnology } from '../../../../Helpers/generalTypes';
import { isValidUrl } from '../../../../Helpers/helperFunctions';
import useInputField from '../../../../hooks/useInputField';
import { useAppSelector } from '../../../../redux/hooks';
import { isPending } from '../../../../redux/reducers/user/userSelectors';

type Props = {
  open?: boolean;
  technology?: UITechnology | null;
  onCancel: () => void;
  onOk: (technology: UITechnology) => void;
};

const nameMinLength = 3;
const nameMaxLength = 30;

const TechnologyForm: React.FC<Props> = ({ technology, open = false, onCancel, onOk }) => {
  const appIsLoading = useAppSelector(isPending);

  const name = useInputField(
    technology?.name || '',
    false,
    (value: string) => value.length >= nameMinLength && value.length <= nameMaxLength,
  );
  const url = useInputField(technology?.url || '', false, (value: string) => !!isValidUrl(value));
  const experience = useInputField(technology?.experience || '', true);

  React.useEffect(() => {
    name.reset();
    url.reset();
    experience.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technology]);

  const submitHandler = () => {
    const technologyItem: UITechnology = {
      name: name.value,
      url: url.value,
      experience: experience.value,
      id: technology?.id || uid(),
    };
    onOk(technologyItem);
  };

  const canSubmitForm = React.useMemo(
    () => name.value && !name.invalid && url.value && !url.invalid,
    [name.value, name.invalid, url.value, url.invalid],
  );

  return (
    <ModalCustom
      form
      withHeader
      formClassName="modal-form-grid gap-y-5"
      title={technology ? 'Edit Technology' : 'Add Technology'}
      okText="Save and Submit"
      cancelText="Cancel"
      open={open}
      okButtonDisabled={!canSubmitForm}
      okButtonLoading={appIsLoading}
      onCancel={onCancel}
      onOk={submitHandler}
    >
      <InputCustom
        required
        id="technology"
        label="Technology"
        placeholder="Enter name"
        errorMessage={`Please enter a name with ${nameMinLength}-${nameMaxLength} characters.`}
        invalid={name.invalid}
        value={name.value}
        onChange={name.onChange}
        disabled={!!technology}
      />
      <InputCustom
        required
        id="url"
        label="URL"
        type="url"
        placeholder="Enter URL"
        errorMessage="Please enter a valid URL."
        invalid={url.invalid}
        value={url.value}
        onChange={url.onChange}
        disabled={!!technology}
      />
      <SelectCustom
        required
        id="experience"
        label="Experience"
        className="col-span-full"
        placeholder="Please select level"
        value={{ label: experience.value, value: experience.value }}
        onChange={experience.onChange}
        options={levels.map((level) => {
          return { label: level, value: level };
        })}
      />
    </ModalCustom>
  );
};

export default TechnologyForm;
