import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import DividerCustom from '../../UI/DividerCustom/DividerCustom';
import IconCustom from '../../IconCustom/IconCustom';
import ModalCustom from '../../UI/ModalCustom/ModalCustom';
import useIsSameProfile from '../../../hooks/useIsSameProfile';
import { ReactComponent as SlackLogo } from '../../../assets/svg/apps/slack-logo.svg';
import './ContactModal.scss';

const { Link } = Typography;
export type Props = {
  email: string;
  name: string;
  open: boolean;
  phone: string;
  closeModal: () => void;
};

const ContactModal: React.FC<Props> = ({ email, name, open, phone, closeModal }) => {
  const navigate = useNavigate();
  const isSameUser = useIsSameProfile();

  const onOk = () => {
    closeModal();
    navigate('/settings');
  };

  return (
    <ModalCustom
      withHeader
      size="small"
      footer={isSameUser ? 'regular' : undefined}
      title={`Contact ${name}`}
      okText={isSameUser ? 'Edit information' : undefined}
      open={open}
      onCancel={closeModal}
      onOk={onOk}
    >
      <div className={`contactModal-root ${isSameUser ? '' : 'mb-8'}`}>
        <div className="contactModal-item">
          <IconCustom className="contactModal-item-icon" icon="MdPhone" color="sky-400" />
          <div className="contactModal-item-info">
            <p className="contactModal-item-data">{phone}</p>
            <p className="contactModal-item-label">Phone</p>
          </div>
        </div>
        <div className="contactModal-item">
          <IconCustom className="contactModal-item-icon" icon="MdEmail" color="sky-400" />
          <div className="contactModal-item-info">
            <p className="contactModal-item-data">{email}</p>
            <p className="contactModal-item-label">E-mail</p>
          </div>
        </div>
        <Link
          className="ant-btn ant-btn-default button-root default contactModal-button"
          aria-disabled="true"
          role="button"
          target="_blank"
          href="slack://channel?team=T0SH98879&id=C0SH989DM"
        >
          <IconCustom className="mr-3" component={SlackLogo} />
          Chat in Slack
        </Link>
      </div>
      {isSameUser && <DividerCustom />}
    </ModalCustom>
  );
};

export default ContactModal;
