import * as React from 'react';

import { Link } from 'react-router-dom';
import TableCustom from '../../../Components/TableCustom/TableCustom';
import AvatarCustom from '../../../Components/AvatarCustom/AvatarCustom';

import { ApprovalRequest, NotificationType } from '../../../Helpers/generalTypes';
import { formatDate } from '../../../Helpers/helperFunctions';

export type Props = {
  requests: Array<ApprovalRequest>;
};

const RequestsTable: React.FC<Props> = ({ requests }) => {
  const getReviewRequestLink = (id: string, type: string) => {
    return (
      <span className="user-edit-link-wrapper">
        <Link
          className="user-edit-link"
          to={`/user-management/${id}/${type.toLowerCase()}`}
          title="go to user profile page"
        >
          <span className="user-edit-link-wrapper">Review</span>
        </Link>
      </span>
    );
  };

  const data = requests?.length
    ? requests?.map((requestItem: ApprovalRequest, i) => {
        let iconType: NotificationType;

        if (requestItem.type === 'Technology') {
          iconType = 'addedTechnology';
        } else if (requestItem.type === 'Skill') {
          iconType = 'addedSkill';
        } else if (requestItem.type === 'Experience') {
          iconType = 'addedExperience';
        } else if (requestItem.type === 'Education') {
          iconType = 'addedEducation';
        } else if (requestItem.type === 'Social media') {
          iconType = 'addedSocialMedia';
        } else {
          // Provide a default/fallback value in case none of the conditions are met
          iconType = '';
        }

        return {
          key: i,
          name: (
            <div className="item-with-avatar-and-data">
              <AvatarCustom
                size="sm"
                imageSrc={requestItem.profilePicture}
                iconType={iconType}
                iconPosition="bottom"
              />
              <div className="item-data">
                <span className="item-data-main">
                  {requestItem.firstName} {requestItem.lastName}
                </span>
                <span className="item-data-sub">{requestItem.type}</span>
              </div>
            </div>
          ),
          dateAdded: (
            <span className="user-joined">{formatDate(requestItem.createdAt, 'DD.MM.YYYY')}</span>
          ),
          requests: <span className="user-joined">{requestItem.numberOfRequests}</span>,
          review: getReviewRequestLink(requestItem.userId.toString(), requestItem.type),
        };
      })
    : null;

  return data && data.length ? (
    <TableCustom
      className="user-management"
      columns={['Name', 'Date Added', 'Requests', 'Review']}
      dataSource={data}
    />
  ) : null;
};

export default RequestsTable;
