import { RootState } from '../../store';

export const loggedUser = (state: RootState) => state.user.loggedUser;
export const userStatus = (state: RootState) => state.user.isLoggedIn;
export const initialUrl = (state: RootState) => state.user.initialUrl;
export const usersList = (state: RootState) => state.user.usersList;
export const usersByTeamId = (state: RootState) => state.user.usersByTeamId;
export const approvalRequests = (state: RootState) => state.user.approvalRequests;
export const skillRequests = (state: RootState) => state.user.skillRequests;
export const technologyRequests = (state: RootState) => state.user.technologyRequests;
export const socialMediaRequests = (state: RootState) => state.user.socialMediaRequests;
export const educationRequests = (state: RootState) => state.user.educationRequests;
export const experienceRequests = (state: RootState) => state.user.experienceRequests;
export const selectedUser = (state: RootState) => state.user.selectedUser;
export const selectedUserId = (state: RootState) => state.user.selectedUser?.id;
export const selectedUserEducation = (state: RootState) => state.user.selectedUserEducation;
export const selectedUserExperience = (state: RootState) => state.user.selectedUserExperience;
export const selectedUserSkills = (state: RootState) => state.user.selectedUser?.skills;
export const selectedUserTechnologies = (state: RootState) => state.user.selectedUser?.technologies;
export const selectedUserSocialMedia = (state: RootState) => state.user.selectedUser?.socialMedia;
export const selectedUserTime = (state: RootState) => state.user.selectedUserTime;
export const selectedUserProjects = (state: RootState) => state.user.projects;
export const selectedUserTransactionHistory = (state: RootState) =>
  state.user.tokensTransactionHistory;
export const selectedUserTokensBalance = (state: RootState) => state.user.tokensBalance;
export const error = (state: RootState) => state.user.error;
export const isPending = (state: RootState) => state.user.isPending;
export const formSuccess = (state: RootState) => state.user.formSuccess;
