/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import { uid } from 'uid';
import TagCustom from '../TagCustom/TagCustom';
import InputCustom from '../InputCustom/InputCustom';
import IconCustom from '../../IconCustom/IconCustom';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import SelectCustom from '../SelectCustom/SelectCustom';
import { Skill, SkillLevels } from '../../../Helpers/generalTypes';
import { ReactComponent as IconLabel } from '../../../assets/svg/icon-label.svg';
import { capitalizeWord } from '../../../Helpers/helperFunctions';
import { levels } from '../../../Helpers/constants';
import { skillsReducer } from '../../../redux/reducers/skills/skillsReducer';

export type Props = {
  id: string;
  label: string;
  values: Array<Skill>;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: ([arg]?: any) => void;
};

const TagsSelectWithLevels: React.FC<Props> = ({
  className,
  disabled,
  id,
  label,
  placeholder,
  values,
  onChange,
}) => {
  const [currentValue, setCurrentValue] = React.useState<string>('');
  const [currentLevel, setCurrentLevel] = React.useState<SkillLevels | undefined>(undefined);
  const [tagsCollection, dispatchTagsCollection] = React.useReducer(skillsReducer, values);

  const inputProps = {
    id,
    disabled,
    label,
    placeholder,
  };

  React.useEffect(() => {
    onChange && onChange(tagsCollection);
  }, [tagsCollection, tagsCollection.length, onChange]);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^a-zA-Z ]/g, '').trimStart();
    setCurrentValue(value);
    if (!value) setCurrentLevel(undefined);
  };

  const submitHandler = () => {
    if (currentValue && currentLevel) {
      const value = { skill: currentValue, level: currentLevel };
      dispatchTagsCollection({ type: 'ADD_SKILL', skill: value });
      setCurrentValue('');
      setCurrentLevel(undefined);
    }
  };

  const editTag = (value: Skill) => {
    setCurrentValue(value.skill);
    setCurrentLevel(value.level);
    dispatchTagsCollection({ type: 'REMOVE_SKILL', skillName: value.skill });
  };

  const removeTag = (value: Skill) => {
    dispatchTagsCollection({ type: 'REMOVE_SKILL', skillName: value.skill });
  };

  const tags = tagsCollection.map((tag: Skill) => (
    <TagCustom closable key={uid()} onClose={() => removeTag(tag)} onClick={() => editTag(tag)}>
      {tag.status === 'Approved' && <IconCustom className="pr-1" avatarIcon="success" />}
      {tag.status === 'Declined' && <IconCustom className="pr-1" avatarIcon="error" />}
      {tag.status === 'Pending' && <IconCustom className="pr-1" avatarIcon="pending" />}
      {tag.skill} ({tag.level && capitalizeWord(tag.level)})
    </TagCustom>
  ));

  return (
    <div className={`${className} TagsSelect-root`}>
      <div className="relative">
        <InputCustom
          {...inputProps}
          value={currentValue}
          type="text"
          className="tagsSelect"
          onChange={changeHandler}
          onPressEnter={submitHandler}
          requiredGroup
          prefix={<IconCustom component={IconLabel} className="mr-1 text-lg"></IconCustom>}
        />
        <SelectCustom
          className={`tagsSelect-level-selector ${
            currentValue ? '' : 'tagsSelect-level-selector-disabled'
          }`}
          noBorder
          popupClassName="tagsSelect-select-dropdown"
          id={`${id}-level`}
          value={currentLevel ? { label: currentLevel, value: currentLevel } : undefined}
          label=""
          onChange={setCurrentLevel}
          options={levels.map((option) => {
            return { label: option, value: option };
          })}
          placeholder="Select level"
          disabled={!currentValue}
        ></SelectCustom>
        <ButtonCustom
          type="text"
          className="tagsSelect-button"
          onClick={submitHandler}
          disabled={!(currentValue && currentLevel)}
        >
          Add
        </ButtonCustom>
      </div>

      <div>{tags}</div>
    </div>
  );
};

export default TagsSelectWithLevels;
