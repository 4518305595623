import React from 'react';
import { Helmet } from 'react-helmet';
import { Badge } from 'antd';
import TeamsTab from './TeamsTab/TeamsTab';
import UsersTab from './UsersTab/UsersTab';

import ApprovalRequestsTab from './ApprovalRequestsTab/ApprovalRequestsTab';
import Container from '../../Components/UI/Container/Container';
import Tabulation from '../../Components/Tabulation/Tabulation';
import { appTitle } from '../../Helpers/constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getApprovalRequests, getSelectedUser } from '../../redux/reducers/user/userApi';
import { approvalRequests, loggedUser } from '../../redux/reducers/user/userSelectors';
import useSuccessMessage from '../../hooks/useSuccessMessage';
import './userManagement.scss';

const UserManagementPage: React.FC = () => {
  const currentLoggedUser = useAppSelector(loggedUser);
  const dispatch = useAppDispatch();
  const requests = useAppSelector(approvalRequests);
  useSuccessMessage();

  React.useEffect(() => {
    currentLoggedUser && dispatch(getSelectedUser(currentLoggedUser.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLoggedUser]);

  const tabItems = [
    {
      key: 'users',
      label: 'Users',
      children: <UsersTab />,
    },
    {
      key: 'teams',
      label: 'Teams',
      children: <TeamsTab />,
    },
    {
      key: 'approvalRequests',
      label: (
        <Badge count={requests.length} offset={[15, 5]}>
          Approval Requests
        </Badge>
      ),
      children: <ApprovalRequestsTab className="ApprovalRequestsTab" />,
    },
    // {
    //   key: 'requestsHistory',
    //   label: 'Requests History',
    //   children: <RequestsHistoryTab />,
    // },
  ];

  React.useEffect(() => {
    dispatch(getApprovalRequests({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>User Management | {appTitle} </title>
      </Helmet>
      <Container>
        <Tabulation items={tabItems} className="settings-tabs"></Tabulation>
      </Container>
    </>
  );
};

export default UserManagementPage;
