/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import ReactQuill from 'react-quill';
import Label from '../Label/Label';
import 'react-quill/dist/quill.snow.css';
import './textArea.scss';

export type Props = {
  id: string;
  label: string;
  value: string;
  className?: string;
  maxLength?: number;
  required?: boolean;
  placeholder?: string;
  onChange: ([arg]?: any) => any;
  [key: string]: any;
};

const TextArea: React.FC<Props> = ({
  className = '',
  id,
  label,
  maxLength,
  required,
  placeholder,
  value,
  onChange,
  ...otherProps
}) => {
  const ReactQuillRef = React.useRef<any>(null);
  const getCharacterCount = () => {
    const unprivilegedEditor = ReactQuillRef.current?.unprivilegedEditor;
    return unprivilegedEditor?.getLength();
  };
  const [isTouched, setIsTouched] = React.useState(false);
  const [isInvalid, setIsInvalid] = React.useState<boolean | undefined>(false);
  const [characterCount, setCharacterCount] = React.useState(getCharacterCount() || 0);
  const [firstRender, setFirstRender] = React.useState(true); // this is needed for a workaround for a bug in the ReactQuill library

  const changeHandler = (e: any) => {
    setCharacterCount(getCharacterCount());
    onChange(e);
  };

  React.useEffect(() => setFirstRender(false), []);

  React.useEffect(() => {
    setCharacterCount(getCharacterCount() || 0);
    setIsInvalid(
      (isTouched && maxLength && characterCount > maxLength) || (required && isTouched && !value),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTouched, value, maxLength, required]);

  const labelProps = { id, label, required };

  return firstRender ? null : (
    <div className={`${className} textarea-root`}>
      <Label {...labelProps} />
      <ReactQuill
        ref={ReactQuillRef}
        className="rich-text-editor"
        modules={{
          toolbar: [['bold', 'italic'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }]],
        }}
        theme="snow"
        onBlur={() => setIsTouched(true)}
        onChange={changeHandler}
        id={id}
        value={value}
        placeholder={placeholder}
        {...otherProps}
      />
      {maxLength ? (
        <p className={`textarea-count ${isInvalid ? 'invalid' : ''}`}>
          {maxLength - characterCount} characters left
        </p>
      ) : null}
    </div>
  );
};

export default TextArea;
