import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Radio, Typography, RadioChangeEvent } from 'antd';
import IconCustom from '../IconCustom/IconCustom';
import InputCustom from '../UI/InputCustom/InputCustom';
import { CalendarModes } from '../../Helpers/generalTypes';
import { formatWeekString } from '../../Helpers/helperFunctions';
import './timeRangePicker.scss';
import { useAppDispatch } from '../../redux/hooks';
import { changeDatePeriod } from '../../redux/reducers/global/globalSlice';

export type Props = {
  date: Dayjs;
  mode: CalendarModes;
  className?: string;
  onModeChange: (mode: CalendarModes) => void;
};

const TimeRangePicker: React.FC<Props> = ({ className = '', date, mode, onModeChange }) => {
  const { Text } = Typography;
  const dispatch = useAppDispatch();
  const [dateDropdownOpen, setDateDropdownOpen] = React.useState(false);

  const getDateRangeString = () => {
    if (mode === 'week') {
      const mondayUnformatted = date.startOf('week');
      const sundayUnformatted = date.endOf('week');
      return formatWeekString(mondayUnformatted, sundayUnformatted);
    }
    if (mode === 'month') {
      const month = date.format('MMMM');
      const year = date.year();
      return `${month}, ${year}`;
    }
  };

  const changeDateWithArrows = (direction: 'forward' | 'back') => {
    const isWeekly = mode === 'week';
    const newDate = date[direction === 'forward' ? 'add' : 'subtract'](
      isWeekly ? 7 : 1,
      isWeekly ? 'day' : 'month',
    );
    dispatch(changeDatePeriod(dayjs(newDate)));
  };

  const pickDate = (newDate: Date) => dispatch(changeDatePeriod(dayjs(newDate)));

  const changeRadioHandler = (e: RadioChangeEvent) => onModeChange(e.target.value);

  const toggleDropdownOpen = () => setDateDropdownOpen((prevState) => !prevState);

  return (
    <div className={`timeRangePicker-root ${className}`}>
      <IconCustom
        icon="MdChevronLeft"
        className="timeRangePicker-icon"
        onClick={() => changeDateWithArrows('back')}
      />
      <IconCustom
        icon="MdChevronRight"
        className="timeRangePicker-icon"
        onClick={() => changeDateWithArrows('forward')}
      />
      <Text className="timeRangePicker-range" onClick={toggleDropdownOpen}>
        {getDateRangeString()}
        <IconCustom icon="MdArrowDropDown" className="timeRangePicker-icon" />
      </Text>
      <InputCustom
        className="timeRangePicker-input"
        type={mode}
        dropdownOpen={dateDropdownOpen}
        value={date.toDate()}
        onChange={pickDate}
        onOpenChange={toggleDropdownOpen}
      />
      <Radio.Group
        optionType="button"
        className="timeRangePicker-radio-group"
        value={mode}
        onChange={changeRadioHandler}
      >
        <Radio value="week" className="timeRangePicker-radio-button">
          Weekly
        </Radio>
        <Radio value="month" className="timeRangePicker-radio-button">
          Monthly
        </Radio>
      </Radio.Group>
    </div>
  );
};

export default TimeRangePicker;
