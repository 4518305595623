/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Typography } from 'antd';
import IconCustom from '../../../Components/IconCustom/IconCustom';
import ButtonCustom from '../../../Components/UI/ButtonCustom/ButtonCustom';
import CardCustom from '../../../Components/UI/CardCustom/CardCustom';
import ModalCustom from '../../../Components/UI/ModalCustom/ModalCustom';
import InputCustom from '../../../Components/UI/InputCustom/InputCustom';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import useInputField from '../../../hooks/useInputField';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { isPending, loggedUser } from '../../../redux/reducers/user/userSelectors';
import { updateUserTeamworkApiKey } from '../../../redux/reducers/user/userApi';
import './IntegrationCard.scss';

// To be refactored when we finalize what happens with multi integration checks
type Props = {
  icon?: any;
  title?: string;
  description?: string;
};

const { Text } = Typography;

const IntegrationCard: React.FC<Props> = ({ icon, title, description }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(loggedUser);
  const appIsLoading = useAppSelector(isPending);

  const [modalOpen, setModalOpen] = React.useState(false);

  const apiKey = useInputField('');
  const userHasApiKey = currentUser?.hasTeamworkApiKey;

  const handleSubmit = () => {
    if (currentUser) {
      const updateApiKeyArgs = {
        currentUserId: currentUser.id,
        apiKey: apiKey.value,
      };
      dispatch(updateUserTeamworkApiKey(updateApiKeyArgs));
      setModalOpen(false);
    }
  };

  // TODO: handle canceling integration

  return (
    <CardCustom className="integration-card-root">
      <div className="icon-and-title">
        <IconCustom component={icon} className="app-icon mr-3" />
        <Text className="integration-title">{title}</Text>
      </div>
      <Text className="description">{description}</Text>
      <DividerCustom className="my-4" />
      <div className="integration-card-controls">
        <ButtonCustom
          className="integration-card-controls-button update-integration"
          type="text"
          onClick={() => setModalOpen(true)}
        >
          {userHasApiKey ? 'Edit key' : 'Integrate Now'}
        </ButtonCustom>
      </div>
      {modalOpen && (
        <ModalCustom
          form
          withHeader
          title="Teamwork Integration"
          okText="Save Changes"
          open={modalOpen}
          okButtonDisabled={!apiKey.value}
          okButtonLoading={appIsLoading}
          onCancel={() => setModalOpen(false)}
          onOk={handleSubmit}
        >
          <InputCustom
            required
            id="apiKey"
            label="API Key"
            value={apiKey.value}
            onChange={apiKey.onChange}
            errorMessage="Please enter a valid API key."
            placeholder="Enter API Key"
          />
        </ModalCustom>
      )}
    </CardCustom>
  );
};

export default IntegrationCard;
