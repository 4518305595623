import * as React from 'react';
import DividedSection from '../../../Components/DividedSection/DividedSection';
import CardCustom from '../../../Components/UI/CardCustom/CardCustom';
import FormCustom from '../../../Components/FormCustom/FormCustom';
import InputCustom from '../../../Components/UI/InputCustom/InputCustom';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import ButtonCustom from '../../../Components/UI/ButtonCustom/ButtonCustom';
import SelectCustom from '../../../Components/UI/SelectCustom/SelectCustom';
import TextArea from '../../../Components/UI/TextArea/TextArea';
import CheckboxCustom from '../../../Components/CheckboxCustom/CheckboxCustom';
import TagsSelect from '../../../Components/UI/TagsSelect/TagsSelect';
import { timezones } from '../../../Helpers/constants';
import { formatDate, formatPhone } from '../../../Helpers/helperFunctions';
import useInputField from '../../../hooks/useInputField';
import { User } from '../../../redux/reducers/user/userInterface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateUserProfile } from '../../../redux/reducers/user/userApi';
import { isPending } from '../../../redux/reducers/user/userSelectors';

export type Props = {
  user: User;
};

const AdditionalInformationSection: React.FC<Props> = ({ user }) => {
  const dispatch = useAppDispatch();
  const appIsLoading = useAppSelector(isPending);

  const defaultDoNotShowBirthday = user?.doNotShowBirthday ?? true;

  const jobRole = useInputField(user.jobRole || '');
  const joinedOn = useInputField(user.joinedOn ? new Date(user.joinedOn) : new Date(), true);
  const country = useInputField(user.country || '', true);
  const city = useInputField(user.city || '');
  const phone = useInputField(user.phone || '', true);
  const timezone = useInputField(user.timezone || 'UTC+02:00', true);
  const biography = useInputField(user.biography || '', true);
  const birthday = useInputField(user.birthday ? new Date(user.birthday) : new Date(), true);
  const hobbies = useInputField(user?.hobbies.filter((hobby) => hobby !== '') || [], true);

  const [doNotShowBirthday, setDoNotShowBirthday] = React.useState(defaultDoNotShowBirthday);

  const submitHandler = () => {
    if (user) {
      const updateInfoPayload = {
        ...user,
        currentUserId: user.id,
        timezone: timezone.value.value,
        birthday: formatDate(birthday.value),
        phone: formatPhone(phone.value),
        country: country.value,
        city: city.value,
        biography: biography.value,
        doNotShowBirthday: doNotShowBirthday,
        hobbies: hobbies.value,
      };
      //TODO: When we get userRoles defined Admin | User
      // delegate the dispatch with a different object
      // the object should contain fields that are only
      // editable by the ADMIN
      dispatch(updateUserProfile(updateInfoPayload));
    }
  };

  const cancelHandler = () => {
    jobRole.reset();
    joinedOn.reset();
    country.reset();
    city.reset();
    phone.reset();
    timezone.reset();
    biography.reset();
    birthday.reset();
    hobbies.reset();
    setDoNotShowBirthday(defaultDoNotShowBirthday);
  };

  const canSubmitForm = React.useMemo(
    () =>
      jobRole.value &&
      joinedOn.value &&
      country.value &&
      city.value &&
      phone.value &&
      birthday.value,
    [jobRole.value, joinedOn.value, country.value, city.value, phone.value, birthday.value],
  );

  return (
    <DividedSection
      className="editProfile-root"
      title="Additional information"
      subtitle="Tell us more about yourself!"
    >
      <CardCustom>
        <FormCustom onSubmit={submitHandler}>
          <div className="editProfile-form-grid">
            <InputCustom
              type="text"
              label="Role"
              required
              disabled
              id="role"
              value={jobRole.value}
              onChange={jobRole.onChange}
            />
            <InputCustom
              type="date"
              label="Joined On"
              required
              disabled
              id="joinedOn"
              value={joinedOn.value}
              onChange={joinedOn.onChange}
            />
            <SelectCustom
              countries
              required
              label="Country"
              id="country"
              value={country.value}
              onChange={country.onChange}
            />
            <InputCustom
              label="City"
              id="city"
              errorMessage="Please enter a city."
              required
              value={city.value}
              onChange={city.onChange}
            />
            <InputCustom
              type="tel"
              country={country.value}
              label="Phone"
              required
              id="phone"
              value={phone.value}
              onChange={phone.onChange}
            />
            <SelectCustom
              label="Timezone"
              id="timezone"
              options={timezones.map((tz) => {
                return {
                  label: tz,
                  value: tz,
                };
              })}
              value={timezone.value}
              onChange={timezone.onChange}
            />
            <TextArea
              className="col-span-full"
              id="biography"
              label="Bio"
              maxLength={500}
              placeholder="Introduce yourself to the team"
              value={biography.value}
              onChange={biography.onChange}
            />
            <div className="col-span-full">
              <InputCustom
                className="mb-4"
                type="date"
                label="Birthday"
                required
                disabled
                id="birthday"
                value={birthday.value}
                onChange={birthday.onChange}
              />
              <CheckboxCustom
                label="Don't show my birthday"
                checked={doNotShowBirthday}
                onChange={(e) => setDoNotShowBirthday(e.target.checked)}
              />
            </div>
            <div className="col-span-full">
              <TagsSelect
                className="col-span-full input-with-button"
                id="hobbies"
                label="Hobbies"
                placeholder="Enter hobbies..."
                values={hobbies.value}
                onChange={hobbies.onChange}
              />
            </div>
          </div>
          <DividerCustom />
          <div className="form-actions">
            <ButtonCustom type="default" onClick={cancelHandler}>
              Cancel
            </ButtonCustom>
            <ButtonCustom
              type="primary"
              htmlType="submit"
              disabled={!canSubmitForm}
              loading={appIsLoading}
            >
              Save changes
            </ButtonCustom>
          </div>
        </FormCustom>
      </CardCustom>
    </DividedSection>
  );
};

export default AdditionalInformationSection;
