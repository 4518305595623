import * as React from 'react';
import ButtonCustom from '../UI/ButtonCustom/ButtonCustom';
import './dividedSection.scss';

export type Props = {
  title?: string;
  children: React.ReactNode;
  buttonAction?: () => void;
  leftSectionComponent?: React.ReactNode;
  className?: string;
  subtitle?: string;
};

const DividedSection: React.FC<Props> = ({
  buttonAction,
  children,
  className = '',
  subtitle,
  leftSectionComponent,
  title,
}) => {
  return (
    <div className={`dividedSection-root ${className}`}>
      <div className="dividedSection-heading">
        <h3>{title}</h3>
        <h4 className="dividedSection-subtitle">{subtitle}</h4>
        {buttonAction && (
          <ButtonCustom
            type="text"
            iconLeft="MdAddCircleOutline"
            className="dividedSection-button"
            onClick={buttonAction}
          >
            Add New
          </ButtonCustom>
        )}
        {leftSectionComponent && leftSectionComponent}
      </div>
      <div className="dividedSection-content">{children}</div>
    </div>
  );
};

export default DividedSection;
