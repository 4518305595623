import * as React from 'react';
import dayjs from 'dayjs';
import TransactionItem from './TransactionItem';
import StatusTag from '../../Components/StatusTag/StatusTag';
import TableCustom from '../../Components/TableCustom/TableCustom';
import EmptyState from '../../Components/EmptyState/EmptyState';
import { LoggedUser } from '../../redux/reducers/user/userInterface';
import { StatusTagProps } from '../../Helpers/generalTypes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectedUserTransactionHistory } from '../../redux/reducers/user/userSelectors';
import { getUserTokensTransactionHistory } from '../../redux/reducers/user/userApi';
import ButtonCustom from '../../Components/UI/ButtonCustom/ButtonCustom';

export type Props = {
  user: LoggedUser | null;
};

const TransactionsHistorySection: React.FC<Props> = ({ user }) => {
  const transactionsShownByDefault = 6;
  const transactionHistory = useAppSelector(selectedUserTransactionHistory);
  const dispatch = useAppDispatch();
  const today = dayjs();

  const [isShowingAll, setIsShowingAll] = React.useState(false);
  const [transactionsCount, setTransactionsCount] = React.useState<number>(
    transactionsShownByDefault,
  );

  const handleShowMore = () => {
    if (transactionHistory?.data.length) {
      if (transactionsCount < transactionHistory?.data.length) {
        setTransactionsCount(transactionsCount + 6);
        setIsShowingAll(false);
      } else {
        setTransactionsCount(transactionHistory?.data.length);
        setIsShowingAll(true);
      }
    }
  };
  const handleShowLess = () => {
    setIsShowingAll(false);
    setTransactionsCount(transactionsShownByDefault);
  };

  React.useEffect(() => {
    if (user?.id) {
      dispatch(
        getUserTokensTransactionHistory({
          currentUserId: user.id,
          limited: false,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const statusTagConfig: { [x: string]: StatusTagProps } = {
    Received: {
      icon: 'MdCheck',
      color: 'green',
      text: 'Received',
    },
    Pending: {
      icon: 'MdOutlineAccessTimeFilled',
      color: 'blue',
      text: 'Pending',
    },
    Declined: {
      icon: 'MdClose',
      color: 'red',
      text: 'Declined',
    },
  };

  const data = transactionHistory?.data?.map((transaction) => {
    const date = dayjs(transaction.date);
    const dateDifferenceInHours = today.diff(date, 'hours');
    const dateDifferenceInMinutes = today.diff(date, 'minutes');

    const displayDate =
      dateDifferenceInHours > 24
        ? dateDifferenceInHours > 48
          ? date.format('MMM DD, YYYY')
          : '1 day ago'
        : dateDifferenceInHours < 1
        ? dateDifferenceInMinutes < 1
          ? 'Just now'
          : `${dateDifferenceInMinutes} minute${dateDifferenceInMinutes === 1 ? '' : 's'} ago`
        : `${dateDifferenceInHours} hour${dateDifferenceInHours === 1 ? '' : 's'} ago`;

    return {
      users: <TransactionItem transaction={transaction} />,
      transactionDate: <div className="transaction-date">{displayDate}</div>,
      status: <StatusTag {...statusTagConfig[transaction.status]} />,
    };
  });

  return data && data.length ? (
    <>
      <TableCustom
        className="transactionsHistorySection-table"
        columns={['Users', 'Transaction Date', 'Status', '']}
        dataSource={isShowingAll ? data : data.slice(0, transactionsCount)}
      />

      {data && !isShowingAll && data.length > transactionsCount ? (
        <ButtonCustom
          type="default"
          className="w-full block text-center"
          onClick={() => handleShowMore()}
        >
          Show More
        </ButtonCustom>
      ) : (
        <ButtonCustom
          type="default"
          className="w-full block text-center"
          onClick={() => handleShowLess()}
        >
          Show Less
        </ButtonCustom>
      )}
    </>
  ) : (
    <EmptyState description="You currently have no transactions." />
  );
};

export default TransactionsHistorySection;
