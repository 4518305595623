import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import IconCustom from '../IconCustom/IconCustom';
import './headingWithBackButton.scss';

export type Props = {
  children?: React.ReactNode;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
};

const { Title } = Typography;

const HeadingWithBackButton: React.FC<Props> = ({
  children,
  showBackButton,
  onBackButtonClick,
}) => {
  const navigate = useNavigate();

  return (
    <div className="headingWithBackButton-root">
      {showBackButton && (
        <IconCustom
          icon="MdKeyboardBackspace"
          onClick={() => (onBackButtonClick ? onBackButtonClick() : navigate(-1))}
        />
      )}{' '}
      <Title level={2}>{children}</Title>
    </div>
  );
};

export default HeadingWithBackButton;
