import * as React from 'react';
import DividedSection from '../../../Components/DividedSection/DividedSection';
import CardCustom from '../../../Components/UI/CardCustom/CardCustom';
import TagsSelectWithLevel from '../../../Components/UI/TagsSelect/TagsSelectWithLevel';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import FormCustom from '../../../Components/FormCustom/FormCustom';
import ButtonCustom from '../../../Components/UI/ButtonCustom/ButtonCustom';
import { Skills } from '../../../redux/reducers/user/userInterface';
import { useAppDispatch } from '../../../redux/hooks';
import { updateUserSkills } from '../../../redux/reducers/user/userApi';

export type Props = {
  skills?: Skills[];
  user?: string;
};
const ProfessionalSkillsSection: React.FC<Props> = ({ user, skills }) => {
  const dispatch = useAppDispatch();

  const [professionalSkills, setProfessionalSkills] = React.useState(skills);

  React.useEffect(() => setProfessionalSkills(skills), [skills]);

  const submitHandler = () => {
    user &&
      professionalSkills &&
      dispatch(updateUserSkills({ currentUserId: user, skills: professionalSkills }));
  };

  return (
    <DividedSection
      title="Professional Skills"
      subtitle="You're great at many things. Let us know which!"
    >
      <CardCustom>
        <FormCustom onSubmit={submitHandler}>
          <TagsSelectWithLevel
            id="professionalSkills"
            label="Skills"
            values={professionalSkills || []}
            onChange={setProfessionalSkills}
          />
          <DividerCustom />
          <div className="form-actions">
            <ButtonCustom type="primary" htmlType="submit">
              Send for approval
            </ButtonCustom>
          </div>
        </FormCustom>
      </CardCustom>
    </DividedSection>
  );
};

export default ProfessionalSkillsSection;
