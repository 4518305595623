import * as React from 'react';
import { Tabs } from 'antd';
import './tabulation.scss';
import type { TabsProps } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

export type Props = {
  className?: string;
  items?: TabsProps['items'];
  children?: React.ReactNode;
};

const Tabulation: React.FC<Props> = (props: Props) => {
  const { className, items } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [activeKey, setActiveKey] = React.useState<string | undefined>(() => {
    const tab = location.hash.replace('#', '');
    return tab || undefined;
  });

  const handleTabChange = (key: string) => {
    setActiveKey(key);
    navigate(`#${key}`);
  };
  return (
    <div className="tabulation-root">
      <Tabs
        defaultActiveKey="1"
        activeKey={activeKey}
        onChange={handleTabChange}
        items={items}
        className={className}
      />
    </div>
  );
};

export default Tabulation;
