import * as React from 'react';
import Welcome from './Welcome/Welcome';
import WelcomeForm from './WelcomeForm/WelcomeForm';
import WelcomeSocialMedia from './WelcomeSocialMedia/WelcomeSocialMedia';
import WelcomeSuccess from './WelcomeSuccess/WelcomeSuccess';
import { selectedUserSocialMedia } from '../../redux/reducers/user/userSelectors';
import { useAppSelector } from '../../redux/hooks';

export type Props = {
  started: boolean;
};

const WelcomeWizard: React.FC<Props> = ({ started }) => {
  const userSocialMedia = useAppSelector(selectedUserSocialMedia);

  const [welcomeOpen, setWelcomeOpen] = React.useState(started);
  const [welcomeFormOpen, setWelcomeFormOpen] = React.useState(false);
  const [welcomeSocialFormOpen, setWelcomeSocialFormOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);

  const closeWizard = (setCurrentModalOpen: (modalOpen: false) => void) => {
    setCurrentModalOpen(false);
  };

  React.useEffect(() => {
    setWelcomeOpen(started);
  }, [started]);

  const handleWelcomeStep = () => {
    setWelcomeFormOpen(false);
    if (userSocialMedia?.length) {
      setSuccessOpen(true);
    } else {
      setWelcomeSocialFormOpen(true);
    }
  };

  return (
    <>
      <Welcome
        open={welcomeOpen}
        onCancel={() => closeWizard(setWelcomeOpen)}
        onOk={() => {
          setWelcomeOpen(false);
          setWelcomeFormOpen(true);
        }}
      />
      <WelcomeForm
        open={welcomeFormOpen}
        onCancel={() => closeWizard(setWelcomeFormOpen)}
        onOk={() => {
          handleWelcomeStep();
        }}
      />
      <WelcomeSocialMedia
        open={welcomeSocialFormOpen}
        onCancel={() => closeWizard(setWelcomeSocialFormOpen)}
        onCancelButton={() => {
          setWelcomeSocialFormOpen(false);
          setWelcomeFormOpen(true);
        }}
        onOk={() => {
          setWelcomeSocialFormOpen(false);
          setSuccessOpen(true);
        }}
      />
      <WelcomeSuccess
        open={successOpen}
        onCancel={() => closeWizard(setSuccessOpen)}
        onOk={() => closeWizard(setSuccessOpen)}
      />
    </>
  );
};

export default WelcomeWizard;
