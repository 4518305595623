import * as React from 'react';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import { VictoryPie } from 'victory';
import CardCustom from '../../../../Components/UI/CardCustom/CardCustom';
import SelectCustom from '../../../../Components/UI/SelectCustom/SelectCustom';
import {
  formatPercentageString,
  formatTimeString,
  formatDate,
} from '../../../../Helpers/helperFunctions';
import type { DoughnutChartTimeRanges } from '../../../../Helpers/generalTypes';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { selectedUserTime } from '../../../../redux/reducers/user/userSelectors';
import { getUserBillableTime, getUserProjectTime } from '../../../../redux/reducers/user/userApi';
import { ProjectTimeEntry } from '../../../../redux/reducers/user/userInterface';

export type Props = {
  userId: string;
  type: 'byProject' | 'byBillable';
};

const DoughnutChart: React.FC<Props> = ({ userId, type }) => {
  const { Title, Paragraph } = Typography;

  const dispatch = useAppDispatch();
  const currentSelectedUserTime = useAppSelector(selectedUserTime);

  const [timeRange, setTimeRange] = React.useState<DoughnutChartTimeRanges>('last-3');
  const [data, setData] = React.useState<Array<ProjectTimeEntry>>([]);

  const today = dayjs();

  React.useEffect(() => {
    const fromDate = formatDate(
      timeRange === 'current'
        ? today.startOf('month')
        : today.subtract(timeRange === 'last-3' ? 3 : 1, 'month').startOf('month'),
    );
    const toDate = formatDate(
      timeRange === 'last' ? today.subtract(1, 'month').endOf('month') : today.endOf('month'),
    );
    if (userId) {
      const dispatchObject = { userId, fromDate, toDate };
      dispatch(
        type === 'byBillable'
          ? getUserBillableTime(dispatchObject)
          : getUserProjectTime(dispatchObject),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, timeRange]);

  React.useEffect(() => {
    setData(currentSelectedUserTime[type === 'byBillable' ? 'timeByBillable' : 'timeByProject']);
  }, [
    currentSelectedUserTime,
    currentSelectedUserTime.timeByBillable,
    currentSelectedUserTime.timeByProject,
    type,
  ]);

  const totalTime = data.reduce(
    (accumulator, time) => accumulator + (time.totalBillableHours + time.totalNonBillableHours),
    0,
  );

  const colorScaleBillable = ['#5b7587', '#f48e87'];
  const colorScaleProject = ['#1f435b', '#9a8dea', '#75dddd', '#388cc4'];

  const getDataByProject = () => {
    const dataByProject: Array<{
      projectData: { name: string; company: string };
      hoursDecimal: number;
      color?: string;
    }> = [];
    data.forEach((item) => {
      dataByProject.push({
        projectData: { name: item.name, company: item.company },
        hoursDecimal: item.totalNonBillableHours + item.totalBillableHours,
      });
    });
    return dataByProject
      .sort((a, b) => b.hoursDecimal - a.hoursDecimal)
      .slice(0, 4)
      .map((item, i) => {
        return { ...item, color: colorScaleProject[i] };
      });
  };

  const dataByBillable = [
    {
      billableStatus: 'Billable',
      hoursDecimal:
        data.reduce((accumulator, item) => accumulator + item.totalBillableHours, 0) || 0,
      color: colorScaleBillable[0],
    },
    {
      billableStatus: 'Non-Billable',
      hoursDecimal:
        data.reduce((accumulator, item) => accumulator + item.totalNonBillableHours, 0) || 0,

      color: colorScaleBillable[1],
    },
  ];

  type ChartableData = Array<{
    color: string;
    hoursDecimal: number;
    billableStatus?: string;
    projectData?: { name: string; company: string };
  }>;

  const getChartData = (dataToChart: ChartableData) =>
    dataToChart.map((datum) => {
      return {
        x: type === 'byBillable' ? datum.billableStatus : datum.projectData,
        y: datum.hoursDecimal,
      };
    });

  const getLegend = (dataToMap: ChartableData) =>
    dataToMap.map((time, i) => (
      <div className={`doughnutChart-item ${i < data.length - 1 ? 'mb-3' : ''}`}>
        <div className="doughnutChart-item-percentage" style={{ backgroundColor: time.color }}>
          {formatPercentageString(time.hoursDecimal, totalTime)}
        </div>
        <div className="doughnutChart-item-data">
          <Paragraph className="doughnutChart-item-main">
            {type === 'byBillable' ? time.billableStatus : time.projectData?.name}
          </Paragraph>
          <Paragraph className="doughnutChart-item-sub">
            {type === 'byBillable' ? formatTimeString(time.hoursDecimal) : time.projectData?.name}
          </Paragraph>
        </div>
      </div>
    ));

  const getPastMonth = (numberOfMonths: number) =>
    today.subtract(numberOfMonths, 'month').format('MMM');

  const valuesToLabels = {
    current: `Current month (${today.format('MMM')})`,
    last: `Last month (${getPastMonth(1)})`,
    'last-3': `Last 3 months (${getPastMonth(3)} - ${getPastMonth(0)})`,
  };

  const chartData = type === 'byBillable' ? dataByBillable : getDataByProject();

  return (
    <CardCustom className="doughnutChart-root">
      <div className="doughnutChart-header">
        <Title level={3} className="doughnutChart-title">
          {type === 'byBillable' ? 'Billable Vs. Non-Billable' : 'Projects Breakdown'}
        </Title>
        <SelectCustom
          noBorder
          className="doughnutChart-select"
          id={`doughnut-chart-${type}`}
          label=""
          value={{ label: valuesToLabels[timeRange], value: timeRange }}
          onChange={setTimeRange}
          options={[
            { label: valuesToLabels.current, value: 'current' },
            { label: valuesToLabels.last, value: 'last' },
            { label: valuesToLabels['last-3'], value: 'last-3' },
          ]}
        />
      </div>
      <div className="doughnutChart-data">
        <div className="doughnutChart-chart">
          <VictoryPie
            data={getChartData(chartData)}
            colorScale={type === 'byBillable' ? colorScaleBillable : colorScaleProject}
            height={400}
            width={400}
            innerRadius={150}
            padding={0}
            domainPadding={0}
            labels={() => ''}
          ></VictoryPie>
        </div>
        <div className="doughnutChart-legend">{getLegend(chartData)}</div>
      </div>
    </CardCustom>
  );
};

export default DoughnutChart;
