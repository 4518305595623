import * as React from 'react';

import RequestsTable from './RequestsTable';
import { ApprovalRequest, Team } from '../../../Helpers/generalTypes';
import DividerCustom from '../../../Components/UI/DividerCustom/DividerCustom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getApprovalRequests } from '../../../redux/reducers/user/userApi';
import { approvalRequests } from '../../../redux/reducers/user/userSelectors';
import DividedSection from '../../../Components/DividedSection/DividedSection';

export type Props = {
  teams: Array<Team> | null;
};

const AllApprovalRequests: React.FC<Props> = ({ teams }) => {
  const dispatch = useAppDispatch();
  //get requests
  const requests = useAppSelector(approvalRequests);

  React.useEffect(() => {
    dispatch(getApprovalRequests({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create a new array 'teamsWithRequests' by mapping through the 'teams' array.
  // For each team, filter the 'requests' array to get all requests that have a 'userId' present in the team's 'users' array.
  // Create an object for each team containing its 'name', 'id', and the filtered 'teamRequests'.
  // After mapping through all teams, filter the 'teamsWithRequests' array to exclude teams that have no requests.
  // This ensures that only teams with at least one request are included in the final 'teamsWithRequests' array.
  // The result is a new array with teams that have requests and each team object containing its name, id, and relevant requests.
  const teamsWithRequests = teams
    ?.map((team) => {
      const teamRequests = requests.filter((request: ApprovalRequest) =>
        team.users.includes(request.userId),
      );
      return { name: team.name, requests: teamRequests, id: team.id };
    })
    .filter((team) => team.requests.length);

  return (
    <>
      {teamsWithRequests?.length
        ? teamsWithRequests?.map((teamItem, i) => {
            return (
              <DividedSection
                className="allTeamsView-root"
                title={teamItem.name}
                subtitle="Manage your team and change roles/permissions"
                key={teamItem.id}
              >
                <div className="allTeamsView-root_approval-wrapper">
                  <div className="allTeamsView-item-data allTeamsView-item-data-approval">
                    {requests && requests.length && <RequestsTable requests={requests} />}
                  </div>

                  {i < teamsWithRequests.length ? <DividerCustom className="my-4" /> : null}
                </div>
              </DividedSection>
            );
          })
        : 'No requests'}
    </>
  );
};

export default AllApprovalRequests;
