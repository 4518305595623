import * as React from 'react';
import { Typography } from 'antd';
import Icon from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { appTitle } from '../../Helpers/constants';
import { useAppSelector } from '../../redux/hooks';
import { initialUrl } from '../../redux/reducers/user/userSelectors';
import Logo from '../../assets/svg/industria-logo.svg';
import { ReactComponent as GoogleIcon } from '../../assets/svg/google.svg';
import './login.scss';

const Login: React.FC = () => {
  const pageUrl = useAppSelector(initialUrl);
  const redirectToPage = `${window.location.origin}${pageUrl}`;

  const loginUrl = `${process.env.REACT_APP_API_URL}/login/google?next=${redirectToPage}`;
  const { Text, Link } = Typography;

  return (
    <>
      <Helmet>
        <title>Login | {appTitle}</title>
      </Helmet>
      <div className="login-page flex h-full">
        <img className="login-page-logo" width={147} src={Logo} alt="INDUSTRIA logo" />
        <div className="login-page-left-side">
          <div className="login-page-form">
            <h1 className="text-2xl">
              Welcome to <br /> INDUSTRIA Dashboard
            </h1>
            <Text className="text-gray-500 mt-2 mb-5">
              Sign in with your INDUSTRIA Google Account
            </Text>
            <Link
              aria-disabled="true"
              className="button-default login-page-form-button"
              href={loginUrl}
              role="button"
              target="_self"
            >
              <Icon component={GoogleIcon}></Icon>
              <strong>Sign in with Google</strong>
            </Link>
          </div>
        </div>
        <div className="login-page-right-side"></div>
      </div>
    </>
  );
};

export default Login;
