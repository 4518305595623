import * as React from 'react';
import { Link } from 'react-router-dom';
import { uid } from 'uid';
import AvatarCustom from '../../../Components/AvatarCustom/AvatarCustom';
import StatusTag from '../../../Components/StatusTag/StatusTag';
import { StatusTagProps } from '../../../Helpers/generalTypes';

export type Props = {
  benchmarkHours: number;
  id: string;
  jobRole: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
  totalTime: number;
};
const TeamTimeTrackerPersonBox: React.FC<Props> = ({
  benchmarkHours,
  id,
  jobRole,
  firstName,
  lastName,
  profilePicture,
  totalTime,
}) => {
  const name = `${firstName} ${lastName}`;
  const statusTagConfig: StatusTagProps = {
    color: totalTime < benchmarkHours ? 'red' : totalTime > benchmarkHours ? 'yellow' : 'green',
    text: totalTime
      ? totalTime < benchmarkHours
        ? 'Partially logged'
        : totalTime > benchmarkHours
        ? 'Overbooked'
        : 'Fully logged'
      : 'No time logged',
    icon:
      totalTime < benchmarkHours
        ? 'MdOutlineAccessTimeFilled'
        : totalTime > benchmarkHours
        ? 'MdFlag'
        : 'MdCheck',
  };

  return (
    <div className="teamTimeTracker-calendar-team-member-info" key={uid()}>
      <div className="teamTimeTracker-calendar-team-member-info-header">
        <AvatarCustom
          size="md"
          imageSrc={profilePicture}
          fallbackText={`${firstName[0]}${lastName[0]}`}
        />
        <div className="teamTimeTracker-calendar-team-member-details">
          <span className="teamTimeTracker-calendar-team-member-details-name">{name}</span>
          <span>{jobRole}</span>
        </div>
      </div>
      {totalTime.toFixed(2)}/{benchmarkHours.toFixed(2)}
      <StatusTag className="teamTimeTracker-calendar-status-tag" {...statusTagConfig} />
      <Link className="teamTimeTracker-calendar-link" to={`details/${id}`}>
        Details
      </Link>
    </div>
  );
};

export default TeamTimeTrackerPersonBox;
