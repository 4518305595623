import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Typography } from 'antd';
import Container from '../../Components/UI/Container/Container';
import './pageNotFound.scss';
import pageNotFound from '../../assets/images/page-not-found.gif';
import ButtonCustom from '../../Components/UI/ButtonCustom/ButtonCustom';
import { appTitle } from '../../Helpers/constants';
const { Text, Title } = Typography;

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Page Not Found | {appTitle} </title>
      </Helmet>
      <Container>
        <div className="pageNotFound-root">
          <Text className="pageNotFound-text pageNotFound-text-reaction">Whoops!</Text>
          <Title className="pageNotFound-title">Well, this is awkward</Title>
          <Text className="pageNotFound-text">
            This page has gone missing. We have no idea where it went, but we’re working on finding
            it. In the meantime, why not check if you entered the correct link?
          </Text>
          <img
            className="pageNotFound-image"
            src={pageNotFound}
            alt="People looking for a page that cannot be found"
          />
          <div className="pageNotFound-actions">
            <ButtonCustom
              type="link"
              iconLeft="MdOutlineAccountCircle"
              className="pageNotFound-button-back"
              onClick={() => navigate(-1)}
            >
              Go Back
            </ButtonCustom>
            <Link className="ant-btn button-root pageNotFound-button" to="/">
              Go to Home page
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PageNotFound;
