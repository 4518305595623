/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import TechnologyBox from './TechnologyBox';
import TechnologyForm from './TechnologyForm/TechnologyForm';
import DividedSection from '../../../Components/DividedSection/DividedSection';
import ButtonCustom from '../../../Components/UI/ButtonCustom/ButtonCustom';
import FormCustom from '../../../Components/FormCustom/FormCustom';
import { UITechnology } from '../../../Helpers/generalTypes';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { selectedUser } from '../../../redux/reducers/user/userSelectors';
import { updateUserTechnologies } from '../../../redux/reducers/user/userApi';
import { technologiesReducer } from '../../../redux/reducers/technologies/technologyReducer';
import { TechnologyActions } from '../../../redux/reducers/technologies/technologyActions';
import { findItemInArrayOfObjectsByName } from '../../../Helpers/helperFunctions';

const EditTechnologiesTab: React.FC = () => {
  /// SETUP
  const dispatch = useAppDispatch();
  const currentSelectedUser = useAppSelector(selectedUser);

  const [technologies, dispatchTechnologies] = React.useReducer(
    technologiesReducer,
    currentSelectedUser?.technologies,
  );

  const [customTechnologies, dispatchCustomTechnologies] = React.useReducer(
    technologiesReducer,
    [],
  );

  const setDefaultTechnologies = () => {
    dispatchTechnologies({
      type: TechnologyActions.GET_TECHNOLOGIES,
      technologies: currentSelectedUser?.technologies,
      custom: false,
    });
    dispatchCustomTechnologies({
      type: TechnologyActions.GET_TECHNOLOGIES,
      technologies: currentSelectedUser?.technologies,
      custom: true,
    });
  };

  React.useEffect(setDefaultTechnologies, [currentSelectedUser]);

  /// MODAL
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalEditItem, setModalEditItem] = React.useState<UITechnology | null>();

  React.useEffect(() => {
    if (!modalOpen) {
      setModalEditItem(undefined);
    }
  }, [modalOpen]);

  React.useEffect(() => {
    if (modalEditItem) {
      setModalOpen(true);
    }
  }, [modalEditItem]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const editHandler = (technology: UITechnology) => {
    setModalEditItem(technology);
  };

  const deleteHandler = (technologyItemId: string) => {
    dispatchCustomTechnologies({
      type: TechnologyActions.DELETE_TECHNOLOGY,
      id: technologyItemId,
    });
  };

  const submitModalFormHandler = (e: UITechnology) => {
    const { name, url, experience, id } = e;
    if (modalEditItem) {
      dispatchCustomTechnologies({
        type: TechnologyActions.EDIT_CUSTOM_TECHNOLOGY,
        name,
        url,
        experience,
        id,
      });
    } else if (findItemInArrayOfObjectsByName(customTechnologies, name)) {
      // TODO: Actual message instead of alert when design is available
      alert('This technology has already been added. Did you mean to edit it instead?');
    } else if (findItemInArrayOfObjectsByName(technologies, name)) {
      // TODO: Actual message instead of alert when design is available
      alert('This technology is available by default. Did you mean to edit your level instead?');
    } else {
      dispatchCustomTechnologies({
        type: TechnologyActions.ADD_CUSTOM_TECHNOLOGY,
        name,
        experience,
        url,
        id,
      });
    }
    closeModal();
  };

  ///GENERAL
  const submitHandler = () => {
    const allTechs = [...technologies, ...customTechnologies]
      .map((tech: UITechnology) => {
        return { name: tech.name, url: tech.url, experience: tech.experience };
      })
      .filter((tech) => tech.experience);
    currentSelectedUser &&
      dispatch(
        updateUserTechnologies({
          currentUserId: currentSelectedUser.id,
          technologies: allTechs,
        }),
      );
  };

  const cancelHandler = () => setDefaultTechnologies();

  const generateElements = (technologiesArray: Array<UITechnology>, custom = false) => {
    const data = technologiesArray.map((technology: UITechnology) => {
      return {
        custom,
        id: technology.id,
        name: technology.name,
        experience: technology.experience,
        imageUrl: custom ? null : technology.imageSrc,
        url: technology.url,
        status: technology?.status,
        onChange: (e: string) => {
          const dispatchObject = {
            type: TechnologyActions.UPDATE_TECHNOLOGY_EXPERIENCE,
            name: technology.name,
            experience: e,
          };
          if (custom) {
            dispatchCustomTechnologies(dispatchObject);
          } else dispatchTechnologies(dispatchObject);
        },
        onEdit: custom
          ? () =>
              editHandler({
                name: technology.name,
                url: technology.url,
                experience: technology.experience,
                id: technology.id,
              })
          : null,
        onDelete: custom ? () => deleteHandler(technology.id) : null,
      };
    });
    return data.map((technology: any) => <TechnologyBox key={technology.id} {...technology} />);
  };

  const customTechnologyElements = customTechnologies?.length
    ? generateElements(customTechnologies, true)
    : null;

  return (
    <>
      <DividedSection title="Technologies" subtitle="Share all the things you know!">
        <FormCustom onSubmit={submitHandler}>
          {customTechnologyElements?.length ? <>{customTechnologyElements}</> : ''}
          <ButtonCustom type="text" onClick={() => setModalOpen(true)}>
            Add Additional Technology
          </ButtonCustom>
          <div className="form-actions my-10">
            <ButtonCustom type="default" onClick={cancelHandler}>
              Cancel
            </ButtonCustom>
            <ButtonCustom type="primary" htmlType="submit">
              Send for approval
            </ButtonCustom>
          </div>
        </FormCustom>
      </DividedSection>
      {modalOpen && (
        <TechnologyForm
          onCancel={closeModal}
          onOk={submitModalFormHandler}
          open={modalOpen}
          technology={modalEditItem}
        />
      )}
    </>
  );
};

export default EditTechnologiesTab;
