import * as React from 'react';
import ExperienceCard from './ExperienceCard';
import ExperienceForm from './ExperienceForm/ExperienceForm';
import DividedSection from '../../../Components/DividedSection/DividedSection';
import ButtonCustom from '../../../Components/UI/ButtonCustom/ButtonCustom';
import { WorkExperience } from '../../../redux/reducers/user/userInterface';

export type Props = {
  experience: Array<WorkExperience>;
  userId?: string;
};

const cardsShownByDefault = 2;

const ExperienceSection: React.FC<Props> = ({ experience, userId }) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const closeModal = () => setModalOpen(false);

  const experienceCards = experience.length
    ? experience?.map((item: WorkExperience) => (
        <ExperienceCard experienceItem={item} key={item.id} userId={userId} />
      ))
    : null;

  const [isShowingAll, setIsShowingAll] = React.useState(false);
  const [experienceCount, setExperienceCount] = React.useState<number>(cardsShownByDefault);

  const handleShowMore = () => {
    if (experience?.length) {
      if (experienceCount < experience?.length) {
        setExperienceCount(experienceCount + 2);
        setIsShowingAll(false);
      } else {
        setExperienceCount(experience?.length);
        setIsShowingAll(true);
      }
    }
  };

  const handleShowLess = () => {
    setIsShowingAll(false);
    setExperienceCount(cardsShownByDefault);
  };

  return (
    <>
      <DividedSection
        title="Experience"
        subtitle="We're glad you're here! What did you do before?"
        buttonAction={() => {
          setModalOpen(true);
        }}
      >
        <div className="experienceSection-cards-grid">
          {!experienceCards || !experience.length ? (
            <p>No experience added</p>
          ) : isShowingAll ? (
            experienceCards
          ) : (
            experienceCards.slice(0, experienceCount)
          )}
        </div>
        {!isShowingAll && experience.length > experienceCount ? (
          <ButtonCustom
            type="default"
            className="w-full block text-center"
            onClick={() => handleShowMore()}
          >
            Show More
          </ButtonCustom>
        ) : (
          <ButtonCustom
            type="default"
            className="w-full block text-center"
            onClick={() => handleShowLess()}
          >
            Show Less
          </ButtonCustom>
        )}
      </DividedSection>
      {modalOpen && (
        <ExperienceForm onCancel={closeModal} onOk={closeModal} open={modalOpen} userId={userId} />
      )}
    </>
  );
};

export default ExperienceSection;
