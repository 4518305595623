import * as React from 'react';
import { Pagination } from 'antd';
import CardCustom from '../UI/CardCustom/CardCustom';
import './paginationCustom.scss';

export type Props = {
  current: number;
  pageSize: number;
  total: number;
  onChange: (page: number) => void;
};

const PaginationCustom: React.FC<Props> = ({ current, pageSize, total, onChange }) => {
  return (
    <CardCustom className="paginationCustom-container">
      <Pagination
        className="paginationCustom-root"
        current={current}
        pageSize={pageSize}
        total={total}
        onChange={onChange}
        showTotal={(totalItems, range) => `Results: ${range[0]}-${range[1]} of ${totalItems}`}
        hideOnSinglePage
      />
    </CardCustom>
  );
};

export default PaginationCustom;
