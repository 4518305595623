import * as React from 'react';
import './layoutSidebar.scss';
import { Menu, Typography } from 'antd';
import type { MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';
// import Icon from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import LogoInvertedCollapsed from '../../assets/svg/industria-logo-inverted-collapsed.svg';
import LogoInverted from '../../assets/svg/industria-logo-inverted.svg';

//imported svg icons
import { ReactComponent as PeopleIcon } from '../../assets/svg/sidebar-icons/icon-people.svg';
import { ReactComponent as TimerIcon } from '../../assets/svg/sidebar-icons/icon-timer.svg';
import { ReactComponent as CrownIcon } from '../../assets/svg/sidebar-icons/icon-crown.svg';
import IconCustom from '../IconCustom/IconCustom';

type MenuItem = Required<MenuProps>['items'][number];
const { Text } = Typography;

//create menus in sidebar
const getItem = (
  label: React.ReactNode, // string OR react element
  key: React.Key, // string OR null
  icon?: React.ReactNode, // icon element OR null
  children?: MenuItem[], //  array of getItem()
  type?: 'group', // reset active class and group submenus when click be
): MenuItem => {
  return {
    label,
    key,
    icon,
    children,
    type,
  } as MenuItem;
};
//Navigation menu
const navItems: MenuItem[] = [
  getItem(
    <Text className="layout-root-sider-group-title">Navigation</Text>,
    'Navigation',
    null,
    [
      getItem(
        <NavLink to="/people" className="layout-root-sider-link">
          <IconCustom component={PeopleIcon} className="sidebar-icon" />
          <span>People</span>
        </NavLink>,
        'People',
      ),
      getItem(
        <NavLink to="/time-tracker" className="layout-root-sider-link">
          <IconCustom component={TimerIcon} className="sidebar-icon" />
          <span>Time Tracker</span>
        </NavLink>,
        'Time Tracker',
      ),
      getItem(
        <NavLink to="/tokens" className="layout-root-sider-link">
          <IconCustom component={CrownIcon} className="sidebar-icon" />
          <span>Tokens</span>
        </NavLink>,
        'Tokens',
      ),
    ],
    'group',
  ),
];

export type Props = {
  children?: React.ReactNode;
  collapsed?: boolean;
  collapsible?: boolean;
  className?: string;
};

const LayoutSidebar: React.FC<Props> = (props: Props) => {
  const { children, collapsed } = props;

  return (
    <Sider
      trigger={null}
      collapsed={collapsed}
      collapsible
      className={`layout-root-sider ${collapsed ? 'layout-root-sider-collapsed' : ''}`}
    >
      <div className="layout-root-sider-logo">
        <img
          className={collapsed ? 'logo-collapsed' : 'logo-expanded'}
          src={collapsed ? LogoInvertedCollapsed : LogoInverted}
          alt="INDUSTRIA LOGO"
        />
      </div>

      <Menu mode="inline" items={navItems} theme="dark" />

      {children}
    </Sider>
  );
};

export default LayoutSidebar;
